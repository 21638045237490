import {
  Avatar,
  ButtonGroup,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdInfo } from 'react-icons/md';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { useDebounce } from '../../app/hooks/useDebounce';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { useGetUserListQuery } from '../../app/services/provider/api/user';
import { UserDto } from '../../app/services/provider/types';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import CustomPopover from '../../components/CustomPopover';
import GridView from '../../components/CustomTable/GridView';
import DeleteUserButton from './DeleteUserButton';

type Props = {
  onClickUser: (user: UserDto | null) => void;
  selectedUser: UserDto | null;
  onUserListChange?: () => void;
};

const UserList: FC<Props> = ({
  selectedUser,
  onClickUser,
  onUserListChange,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');

  const debouncedSearch = useDebounce(search, 400);
  const hasUserWrite = useIsUserHasRequiredAppAccess(AppAccessAuth.userWrite);
  const { data, isLoading, isFetching } = useGetUserListQuery(
    {
      page_number: pageNumber,
      page_size: pageSize,
      search: searchDebounce,
    },
    { refetchOnMountOrArgChange: true }
  );

  const TableHeader = [
    {
      Header: 'Action',
      isAction: true,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: ({ row: { original } }: { row: { original: UserDto } }) => {
        const fullName = `${original.first_name} ${original.last_name}`;
        const avatarUrl = `${process.env.REACT_APP_CONTAINER_URL}/${process.env.REACT_APP_CONTAINER_NAME}/${original.email}.jpeg`;

        const auditInfo = [
          { key: 'ID', value: original.ref_user_id || '-' },
          {
            key: 'SSO ID',
            value: original.sso_object_id || '-',
          },
          {
            key: 'CREATED BY',
            value: original.row_created_by_user_name || '-',
          },
          {
            key: 'DATE CREATED',
            value: convertUtcToLocal(original.row_created_datetime_utc) || '-',
          },
          {
            key: 'MODIFIED BY',
            value: original.row_modified_by_user_name || '-',
          },
          {
            key: 'DATE MODIFIED',
            value: convertUtcToLocal(original.row_modified_datetime_utc) || '-',
          },
        ];
        return (
          <VStack alignItems="end">
            <ButtonGroup spacing={1}>
              <CustomPopover
                items={auditInfo}
                triggerElement={
                  <IconButton
                    minWidth={1}
                    variant="link"
                    aria-label="View Details"
                    icon={<MdInfo />}
                    title="View Details"
                  />
                }
              />

              <Tooltip label="View Roles">
                <IconButton
                  variant="link"
                  aria-label="View Roles"
                  icon={<BsFillEyeFill />}
                  onClick={() => onSelectUser(original)}
                  minWidth={1}
                />
              </Tooltip>
              {hasUserWrite && (
                <>
                  <DeleteUserButton refUser={original} />
                </>
              )}
            </ButtonGroup>
            <Avatar
              name={`${fullName.trim() || original.email}`}
              src={avatarUrl}
              size="sm"
              bg="gray.300"
              color="gray.800"
            />
          </VStack>
        );
      },
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Disable Date',
      style: { display: 'flex', justifyContent: 'center' },
      Cell: ({ row: { original } }: { row: { original: UserDto } }) => {
        return <>{convertUtcToLocal(original.disabled_datetime_utc) || '-'}</>;
      },
    },
  ];

  const onSelectUser = (data: UserDto) => {
    onClickUser(data);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  // useEffect(() => {
  //   if (!isLoading && data && data.data) {
  //     let newSelectedUser = data.data.find((f) => f.ref_user_id === selectedUser?.ref_user_id);
  //     if (newSelectedUser) {
  //       onClickUser(newSelectedUser);
  //     }
  //   }
  //   onUserListChange && onUserListChange();
  // }, [data, isLoading, selectedUser?.ref_user_id, onClickUser, onUserListChange]);

  return (
    <>
      <GridView
        isLoading={isLoading}
        isFetching={isFetching}
        data={data?.data.filter(f => f.ref_user_id !== 1) ?? []}
        pageCount={data?.total_pages || 0}
        pageSize={data?.page_size || pageSize}
        totalRecords={data?.total_records || 0}
        headers={TableHeader}
        search={search}
        onPageChange={index => {
          setPageNumber(index);
          onUserListChange && onUserListChange();
        }}
        onPageSizeChange={size => {
          setPageSize(size);
        }}
        onPageSearch={search => {
          setPageNumber(1);
          setSearch(search);
          onUserListChange && onUserListChange();
        }}
        onSort={() => ({})}
        rowDisabledOnPropertyTrue="disabled_flag"
        activeRow={{
          property: 'ref_user_id',
          value: selectedUser?.ref_user_id,
        }}
      />
    </>
  );
};

export default UserList;
