import { convertUtcToLocal } from '@/app/helpers/dateHelper';
import { RefRoleUserSummaryDto } from '@/app/services/provider/types';

const LastModified = ({
  row: { original },
}: {
  row: { original: RefRoleUserSummaryDto };
}) => {
  return (
    convertUtcToLocal(
      original.last_modified_datetime_utc,
      'MMMM D, YYYY h:mm A'
    ) || '-'
  );
};

export default LastModified;
