import { providerApi } from './index';
import { PostPimGraphDto } from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    postPimGraph: builder.mutation<void, PostPimGraphDto>({
      query: params => ({
        url: '/pim/graph/user',
        method: 'POST',
        body: params,
      }),
      // invalidatesTags: ["UserOrganization"],
    }),
    postPimGraphFirstSignin: builder.mutation<void, string>({
      query: ssoObjectId => ({
        url: `/pim/graph/firstsignin`,
        method: 'POST',
        body: { sso_object_id: ssoObjectId },
      }),
      // invalidatesTags: ["UserOrganization"],
    }),
  }),
  overrideExisting: false,
});

export const { usePostPimGraphMutation, usePostPimGraphFirstSigninMutation } =
  extendedApi;
