import { providerApi } from '.';
import {
  PostRolesAppAccessRoleDto,
  RolesAppAccessRefAppAccessModel,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getRolesAppAccessRefAppAccessList: builder.query<
      RolesAppAccessRefAppAccessModel[],
      void
    >({
      query: () => '/rolesappaccess/ref_app_access/list',
      transformResponse: (list: RolesAppAccessRefAppAccessModel[]) => {
        return list.filter(f => !f.disabled_flag);
      },
    }),
    postRolesAppAccessRole: builder.mutation<
      boolean,
      PostRolesAppAccessRoleDto
    >({
      query: body => ({
        url: `/rolesappaccess`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RolesAppAccessList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRolesAppAccessRefAppAccessListQuery,
  useLazyGetRolesAppAccessRefAppAccessListQuery,
  usePostRolesAppAccessRoleMutation,
} = extendedApi;
