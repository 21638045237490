import {
  AuthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
} from '@azure/msal-react';
import React, { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserRoleContext } from '../../app/context/UserRoleContext';
import { getIdTokenClaims } from '../../app/services/auth/helper';
import { setLogonUser } from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import PageLoading from '../../components/PageLoading/PageLoading';

type IProps = {
  children: ReactNode;
};

const AuthenticatedUserWrapper: FC<IProps> = ({ children }) => {
  const { userAppAccess } = useUserRoleContext();

  return (
    <>
      {!userAppAccess ? (
        <PageLoading />
      ) : (
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      )}
    </>
  );
};

export default AuthenticatedUserWrapper;
