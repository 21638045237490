import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { debounce } from '../../app/helpers/utilities';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { useLazyGetUserListQuery } from '../../app/services/provider/api/user';
import { useLazyGetUserOrganizationListQuery } from '../../app/services/provider/api/userOrganization';
import {
  FilterParamsDto,
  GetUserOrganizationDto,
} from '../../app/services/provider/types';
import { appColors } from '../../app/theme';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import AssignButton from './AssignButton';

const AddUserButton = ({ orgId, onSuccess }: any) => {
  const [users, setUsers] = useState<GetUserOrganizationDto[] | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertDisclosure = useDisclosure();
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [existingUsers, setExistingUsers] = useState<GetUserOrganizationDto[]>(
    []
  );
  const cancelRef = React.useRef(null);

  const [usersOrgTrigger, usersOrgDetails] =
    useLazyGetUserOrganizationListQuery();
  const [usersTrigger, usersDetails] = useLazyGetUserListQuery();

  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.userWrite
  );

  const isLoading =
    usersDetails.isLoading ||
    usersOrgDetails.isLoading ||
    usersDetails.isFetching ||
    usersOrgDetails.isFetching;

  const handleError = (error: any) => {
    setErrorMessage(error);
  };

  const setInitState = () => {
    setErrorMessage(null);
    setUsers(null);
    setSearch('');
    alertDisclosure.onClose();
  };

  const handleSuccess = (
    newAssignedUser: GetUserOrganizationDto | undefined
  ) => {
    setErrorMessage(null);
    if (newAssignedUser) {
      setExistingUsers(l => [...l, newAssignedUser]);
      onSuccess(newAssignedUser);
    }
  };

  const handleClick = () => {
    setInitState();
    onOpen();
    usersOrgTrigger().then(resp => {
      const _d = resp.data;
      const existingUsers = _d?.filter(u => {
        return u.organization_id === orgId;
      });
      setExistingUsers(existingUsers ?? []);
    });
  };

  function removeDuplicates(array: any) {
    const temp: GetUserOrganizationDto[] = [];
    array?.forEach((item: any, index: number) => {
      if (
        temp.length > 0 &&
        !temp.find(s => s.ref_user_id === item.ref_user_id)
      ) {
        temp.push(item);
      }

      if (temp.length === 0) {
        temp.push(item);
      }
    });

    return temp;
  }

  const onModalClose = () => {
    setInitState();
    onClose();
  };

  const searchUsers = (params?: Partial<FilterParamsDto>) => {
    usersTrigger({
      page_number: 1,
      page_size: 99999,
      search,
      ...params,
    } as FilterParamsDto);
  };

  const debouncedSearchUsers = useCallback(
    debounce((params: Partial<FilterParamsDto>) => {
      searchUsers(params);
    }, 800),
    []
  );

  const keyDownHandler = (event: any) => {
    if (event.key === 'Enter') searchUsers();
  };

  useEffect(() => debouncedSearchUsers({ search }), [search]);

  useEffect(() => {
    // const filtered = usersDetails.data?.data?.filter((u) => {
    //   if (
    //     existingUsers?.some((s) => s.ref_user_id === u.ref_user_id) ||
    //     u.ref_user_id === 1 ||
    //     u.disabled_flag
    //   )
    //     return false;
    //   return true;
    // });

    const noDup = removeDuplicates(usersDetails.data?.data);
    setUsers(noDup || null);
  }, [usersDetails.data, existingUsers]);

  return (
    <>
      {userHasRequiredRole ? (
        <div>
          <Button
            leftIcon={<AddIcon />}
            boxShadow="sm"
            size="sm"
            onClick={handleClick}
          >
            Assign Existing User
          </Button>

          <Modal isOpen={isOpen} onClose={onModalClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <HStack alignItems="start">
                  <HStack mr="auto">
                    <Text>Please select a user</Text>
                    {isLoading && <Spinner />}
                  </HStack>
                  <Box>
                    <InputGroup w="56">
                      <Input
                        id="search"
                        placeholder="search"
                        name="search"
                        w="100%"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        onKeyDown={keyDownHandler}
                      />
                      <InputRightElement
                        children={
                          <SearchIcon
                            className="SearchIcon"
                            color={appColors.brand.main.default}
                          />
                        }
                      />
                    </InputGroup>
                  </Box>
                  <IconButton
                    aria-label="close"
                    icon={<HiOutlineX />}
                    onClick={onModalClose}
                    backgroundColor="white"
                  />
                </HStack>
              </ModalHeader>
              <ModalBody>
                {users && (
                  <VStack
                    align="stretch"
                    spacing={5}
                    alignItems="center"
                    maxH={300}
                    overflowY="auto"
                  >
                    <Box p={2} w="100%" shadow="md" borderWidth="1px">
                      {users && users.length < 1 && <Text>No data found</Text>}
                      <List>
                        {users &&
                          users.map(u => {
                            return (
                              <ListItem
                                key={u.ref_user_id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderBottomWidth={1}
                                pb={1}
                              >
                                {u.first_name && u.last_name && u.email ? (
                                  <Box>
                                    <Text
                                      fontSize="md"
                                      textTransform="capitalize"
                                    >
                                      {u.first_name.toLowerCase()}{' '}
                                      {u.last_name.toLowerCase()}
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                      {u.email}
                                    </Text>
                                  </Box>
                                ) : (
                                  <Box
                                    minH="45px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Text fontSize="md">{u.email}</Text>
                                  </Box>
                                )}
                                {existingUsers.some(
                                  e => e.ref_user_id === u.ref_user_id
                                ) ? (
                                  <Text
                                    color={appColors.brand.main.default}
                                    fontSize="xs"
                                    pr="1"
                                  >
                                    Assigned
                                  </Text>
                                ) : (
                                  <AssignButton
                                    refUserId={u.ref_user_id}
                                    orgId={orgId}
                                    setError={handleError}
                                    setIsSuccess={handleSuccess}
                                  />
                                )}
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </VStack>
                )}
              </ModalBody>

              <ModalFooter>
                {errorMessage && (
                  <Alert status="error" mt={3}>
                    <>
                      <AlertIcon />
                      {errorMessage &&
                        'data' in errorMessage &&
                        errorMessage?.data}
                      {errorMessage &&
                        !errorMessage.data &&
                        'Something went wrong, please try again later or contact admin'}
                    </>
                  </Alert>
                )}

                <Button
                  ref={cancelRef}
                  variant="solid"
                  colorScheme="brand.main"
                  onClick={onModalClose}
                  ml={3}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default AddUserButton;
