import { Box, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import b64toBlob from 'b64-to-blob';
import { FunctionComponent } from 'react';
import { AiFillCamera } from 'react-icons/ai';
import { usePostAvatarMutation } from '../../app/services/provider/api/avatar';
import { LogonUserDto } from '../../app/services/provider/types';
import UploadAvatarFormModal from './UploadAvatarFormModal';

type UploadAvatarButtonProps = {
  title: string;
  logonUser: LogonUserDto;
};

const UploadAvatarButton: FunctionComponent<
  UploadAvatarButtonProps
> = props => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [postAsync, { isLoading, isSuccess, isError, error }] =
    usePostAvatarMutation();

  const onSubmit = (src: string) => {
    const contentType = 'image/jpeg';
    const b64Data = src.split(',')[1];

    const blob = b64toBlob(b64Data, contentType);

    const formData = new FormData();
    formData.append('File', blob);
    formData.append('email', props.logonUser.user_email);

    postAsync(formData);
  };

  return (
    <Box p={3}>
      <Tooltip
        hasArrow
        label="Change Picture"
        bg="gray.100"
        color="gray.600"
        placement="top"
      >
        <IconButton
          size="lg"
          variant="link"
          aria-label="Change Picture"
          icon={<AiFillCamera />}
          colorScheme="brand.main"
          onClick={onOpen}
        />
      </Tooltip>
      <UploadAvatarFormModal
        mode="Add"
        modalTitle="Upload Photo"
        isOpen={isOpen}
        onClose={onClose}
        submitButtonLabel="Submit"
        successMessage="Successfully save photo."
        onSubmit={onSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        errorMessage={error}
      />
    </Box>
  );
};

export default UploadAvatarButton;
