import { providerApi } from '.';
import { GetListRefPaymentTrackModel } from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getListRefPaymentTrack: builder.query<GetListRefPaymentTrackModel[], void>({
      query: () => '/refpaymenttrack/list',
    }),
  }),
});

export const { useGetListRefPaymentTrackQuery } = extendedApi;
