import { AddIcon } from '@chakra-ui/icons';
import { Button, useDisclosure } from '@chakra-ui/react';

import React from 'react';
import { StepperProvider } from '../../app/context/StepperContext';
import CmsModal from './CmsModal';
type Props = {
  userOrgId: number;
  organizationTin: string;
};

const SearchCmsButton = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button leftIcon={<AddIcon />} boxShadow="sm" size="sm" onClick={onOpen}>
        Add Provider
      </Button>
      <StepperProvider>
        <CmsModal
          isOpen={isOpen}
          onClose={onClose}
          userOrgId={props.userOrgId}
          organizationTin={props.organizationTin}
        />
      </StepperProvider>
    </>
  );
};

export default SearchCmsButton;
