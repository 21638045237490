import { ValueOf } from '../helpers/utilities';

export type WindowSize = {
  width: number;
  height: number;
};

export const AppAccessRaw = {
  UserRead: 'user.read',
  UserWrite: 'user.write',
  OrganizationRead: 'organization.read',
  OrganizationWrite: 'organization.write',
  ReportHubRead: 'reporthub.read',
  ReportHubWrite: 'reporthub.write',
  ProviderExportRead: 'providerexport.read',
  ProviderExportWrite: 'providerexport.write',
  OrdersToSignRead: 'orderstosign.read',
  OrdersToSignWrite: 'orderstosign.write',
  OrdersToSignCgmRead: 'orderstosigncgm.read',
  OrdersToSignCgmWrite: 'orderstosigncgm.write',
  fulfillmentRead: 'fulfillment.read',
  fulfillmentWrite: 'fulfillment.write',
  fulfillmentCgmRead: 'fulfillmentcgm.read',
  fulfillmentCgmWrite: 'fulfillmentcgm.write',
  fileShareRead: 'fileshare.read',
  fileShareWrite: 'fileshare.write',
  fileShareFinancialRead: 'filesharefinancial.read',
  fileShareFinancialWrite: 'filesharefinancial.write',
  fileSharePhiRead: 'filesharephi.read',
  fileSharePhiWrite: 'filesharephi.write',
  filesharedeidentifiedRead: 'filesharedeidentified.read',
  filesharedeidentifiedWrite: 'filesharedeidentified.write',
  ProviderPatientDetailsRead: 'providerpatientdetails.read',
  ProviderPatientDetailsWrite: 'providerpatientdetails.write',
  ExploreRolesRead: 'exploreroles.read',
  ExploreRolesWrite: 'exploreroles.write',
  AddRolesRead: 'addroles.read',
  AddRolesWrite: 'addroles.write',
  AccessApprovalRead: 'accessapproval.read',
  AccessApprovalWrite: 'accessapproval.write',
} as const;

export const AppAccessAuth = {
  providerExportReadWriteAll: [
    AppAccessRaw.ProviderExportRead,
    AppAccessRaw.ProviderExportWrite,
  ],
  reportHubReadWriteAll: [
    AppAccessRaw.ReportHubRead,
    AppAccessRaw.ReportHubWrite,
  ],
  userReadWriteAll: [AppAccessRaw.UserRead, AppAccessRaw.UserWrite],
  userRead: [AppAccessRaw.UserRead],
  userWrite: [AppAccessRaw.UserWrite],
  ordersToSignAll: [
    AppAccessRaw.OrdersToSignRead,
    AppAccessRaw.OrdersToSignWrite,
  ],
  ordersToSignCgmAll: [
    AppAccessRaw.OrdersToSignCgmRead,
    AppAccessRaw.OrdersToSignCgmWrite,
  ],
  fulfillmentAll: [AppAccessRaw.fulfillmentRead, AppAccessRaw.fulfillmentWrite],
  fulfillmentCgmAll: [
    AppAccessRaw.fulfillmentCgmRead,
    AppAccessRaw.fulfillmentCgmWrite,
  ],
  organizationReadAndWriteAll: [
    AppAccessRaw.OrganizationRead,
    AppAccessRaw.OrganizationWrite,
  ],
  organizationRead: [AppAccessRaw.OrganizationRead],
  organizationWrite: [AppAccessRaw.OrganizationWrite],
  fileShareAll: [
    AppAccessRaw.fileShareRead,
    AppAccessRaw.fileShareWrite,
    AppAccessRaw.fileShareFinancialRead,
    AppAccessRaw.fileShareFinancialWrite,
    AppAccessRaw.fileSharePhiRead,
    AppAccessRaw.fileSharePhiWrite,
    AppAccessRaw.filesharedeidentifiedRead,
    AppAccessRaw.filesharedeidentifiedWrite,
  ],
  loreAdministrator: [],
  participantAdministrator: [],
  ProviderPatientDetailsAll: [
    AppAccessRaw.ProviderPatientDetailsRead,
    AppAccessRaw.ProviderPatientDetailsWrite,
  ],
  exploreRolesWrite: [AppAccessRaw.ExploreRolesWrite],
  exploreRolesRead: [AppAccessRaw.ExploreRolesRead],
  addRolesAll: [AppAccessRaw.AddRolesRead, AppAccessRaw.AddRolesWrite],
  AccessApprovalAll: [
    AppAccessRaw.AccessApprovalRead,
    AppAccessRaw.AccessApprovalWrite,
  ],
  all: [
    AppAccessRaw.UserRead,
    AppAccessRaw.UserWrite,
    AppAccessRaw.OrganizationRead,
    AppAccessRaw.OrganizationWrite,
  ],
};

export type AppAuthRequiredAppAccess =
  | ValueOf<typeof AppAccessRaw>
  | Array<ValueOf<typeof AppAccessRaw>>
  | '*';
