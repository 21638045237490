import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Flex, VStack, Text } from '@chakra-ui/react';

const UploadAvatar = ({ onChange, onError }: any) => {
  const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
    onChange();
  };

  const onCrop = (preview: any) => {
    setPreview(preview);
  };

  const MAX_SIZE = Math.ceil(
    Number(process.env.REACT_APP_AVATAR_MAX_SIZE) / 1000
  );

  const onBeforeFileLoad = (elem: any) => {
    const fileSize = Number(elem.target.files[0].size);
    const fileType = elem.target.files[0].type;
    const maxSize = Number(process.env.REACT_APP_AVATAR_MAX_SIZE);
    const allowedFiles = process.env.REACT_APP_AVATAR_FILE_TYPE?.split(',');
    try {
      if (!allowedFiles?.find(s => 'image/' + s.trim() === fileType)) {
        onError(
          `${String(fileType).substring(6)}
            File extension is not allowed. You can only upload
            ${allowedFiles?.map(s => s.trim())}`
        );
        elem.target.value = '';
      }

      if (fileSize > maxSize) {
        onError(
          `File size 
            ${MAX_SIZE} KB
             is too large. Please enter a file with a valid file size no larger than 
            ${maxSize / 1000} 
            KB`
        );
        elem.target.value = '';
      }
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    if (preview) onChange(preview);
  }, [preview]);

  return (
    <Flex justifyContent="center">
      <VStack>
        <Flex
          flexDirection="column"
          alignItems="flexStart"
          fontSize="0.8em"
          color="gray.600"
          fontStyle="italic"
        >
          <Text>
            Maximum file size: <b>{MAX_SIZE}KB</b>.
          </Text>
          <Text>
            Allowed file types: <b>{process.env.REACT_APP_AVATAR_FILE_TYPE}</b>.
          </Text>
        </Flex>
        <Avatar
          width={400}
          height={300}
          onCrop={onCrop}
          onClose={onClose}
          exportSize={110}
          onBeforeFileLoad={onBeforeFileLoad}
        />
        {preview && <img src={preview} />}
      </VStack>
    </Flex>
  );
};

export default UploadAvatar;
