import { Button, Divider, Heading, HStack, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { RefRoleUsersSummaryDto } from '@/app/services/provider/types';
import CustomTable from '@/components/CustomTable';
import { FaUnlockAlt } from 'react-icons/fa';
import { InitialSortBy, SortType } from './ExploreRoles.container';
import RequestAccessDialog from './RequestAccess';

type column = {
  Header: string;
  accessor: string;
};
type Props = {
  data: RefRoleUsersSummaryDto | undefined;
  columns: Array<column>;
  pageIndex: number;
  pageSize: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSortBy: React.Dispatch<React.SetStateAction<SortType>>;
  isLoadingUsersSummary: boolean;
  isFetchingUsersSummary: boolean;
  userHasRequiredRole: boolean;
};

const ExploreRolesView: FC<Props> = ({
  data,
  columns,
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  setSearch,
  setSortBy,
  isLoadingUsersSummary,
  isFetchingUsersSummary,
  userHasRequiredRole,
}) => {
  return (
    <VStack alignItems="start">
      <HStack>
        <Heading size="md">Explore Roles</Heading>
        <RequestAccessDialog
          triggerElement={
            <Button
              variant="outline"
              colorScheme="brand.main"
              leftIcon={<FaUnlockAlt />}
            >
              Request Access
            </Button>
          }
        />
      </HStack>
      <Divider />
      <CustomTable
        hasFilter
        hasLoadingIndicator
        manual
        manualSortBy
        disableSortRemove
        isPageNumberEditable
        isLoading={isLoadingUsersSummary}
        isFetching={isFetchingUsersSummary}
        data={data?.data || []}
        pageCount={data?.total_pages ?? 0}
        pageSize={data?.page_size ?? pageSize}
        totalRecords={data?.total_records ?? 0}
        pageIndex={pageIndex}
        headers={columns}
        initialState={{
          // @ts-expect-error: please fix if encountered
          sortBy: [InitialSortBy],
          hiddenColumns: [userHasRequiredRole ? '' : 'user_count'],
        }}
        onPageChange={index => {
          if ((data?.total_pages || 1) > index) setPageIndex(index);
        }}
        onPageSizeChange={size => {
          setPageIndex(0);
          setPageSize(size);
        }}
        onPageSearch={search => {
          setPageIndex(0);
          setSearch(search);
        }}
        onSort={sort => {
          if (sort[0]) setSortBy(sort[0]);
        }}
      />
    </VStack>
  );
};

export default ExploreRolesView;
