import { providerApi } from '.';
import { GetPatientDetailListDto, GetPatientDetailListModel } from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getPatientDetailList: builder.query<
      GetPatientDetailListModel,
      GetPatientDetailListDto
    >({
      query: ({ organization_id_list, ...params }) => {
        let orgParam: string;
        if (organization_id_list.length < 1) {
          orgParam = 'organization_id_list=null';
        } else if (organization_id_list.length === 1) {
          orgParam = 'organization_id_list=' + organization_id_list[0];
        } else {
          let text = '';
          organization_id_list.forEach((f, i) => {
            if (i === 0) {
              text = 'organization_id_list=' + f;
            } else {
              text = `${text}&organization_id_list=${f}`;
            }
          });
          orgParam = text;
        }

        const otherParams = new URLSearchParams({
          sort_order: params.sort_order,
          sort_column: params.sort_column,
          PageNumber: params.PageNumber.toString(),
          PageSize: params.PageSize.toString(),
          q: params.q,
        }).toString();

        return {
          url: `/patientdetail?${orgParam}&${otherParams}`,
        };
      },
    }),
  }),
});

export const {
  useGetPatientDetailListQuery,
  useLazyGetPatientDetailListQuery,
} = extendedApi;
