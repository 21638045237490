import { providerApi } from './index';
import {
  OrganizationPagedDto,
  PostOrganizationDto,
  PutOrganizationDto,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getOrganizationList: builder.query<OrganizationPagedDto, any>({
      query: params =>
        `/organization/?pagenumber=${params.filter.page_number}&pagesize=${params.filter.page_size}&q=${params.filter.search}`,
      providesTags: ['Organization'],
    }),
    postOrganization: builder.mutation<void, PostOrganizationDto>({
      query: params => ({
        url: '/organization',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Organization'],
    }),
    putOrganization: builder.mutation<void, PutOrganizationDto>({
      query: params => ({
        url: '/organization',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Organization'],
    }),
    deleteOrganization: builder.mutation<void, number>({
      query: id => ({
        url: `/organization/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Organization'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetOrganizationListQuery,
  usePostOrganizationMutation,
  usePutOrganizationMutation,
  useDeleteOrganizationMutation,
} = extendedApi;
