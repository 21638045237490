import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useStepperContext } from '../../../app/context/StepperContext';
import {
  getClinicProviderNpiExternal,
  usePostClinicProviderMutation,
} from '../../../app/services/provider/api/clinicProvider';
import { ClinicProviderDto } from '../../../app/services/provider/types';
import { useAppSelector } from '../../../app/state/hooks';
import { appColors } from '../../../app/theme';
import CustomStepper from '../../../components/CustomStepper/CustomStepper';
import StepperActions from '../../../components/CustomStepper/StepperActions';
import CmsList, { cmsFields } from './CmsList';
import IncorrectTagList from './IncorrectTagList';
import NpiList, { npiFields } from './NpiList';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  userOrgId: number;
  organizationTin: string;
};

const InstructionText = () => {
  return (
    <Box px={2}>
      <Alert
        bg={appColors.hightLightColor}
        borderRadius={5}
        my={2}
        borderTopWidth={4}
        borderTopColor={appColors.brand.main.default}
      >
        <VStack align="stretch">
          <Text>
            {
              'The information displayed here is associated with this NPI by CMS. If elements of this data are incorrect, visit '
            }
            <Link
              color={appColors.links}
              href="https://nppes.cms.hhs.gov/IAWeb/login.do"
            >
              https://nppes.cms.hhs.gov/IAWeb/login.do
            </Link>
            {' to update the CMS entry.'}
          </Text>
        </VStack>
      </Alert>
    </Box>
  );
};

const CmsModal: FC<IProps> = ({
  isOpen,
  onClose,
  userOrgId,
  organizationTin,
}: IProps) => {
  const alertDisclosure = useDisclosure();
  const { logonUser } = useAppSelector(s => s.user);
  const { nextStep, steps, activeStep, reset, setCompleted } =
    useStepperContext();
  const [status, setStatus] = useState<'info' | 'error' | 'warning'>();
  const [statusMessage, setStatusMessage] = useState<any | null>();
  const [postStatusMessage, setPostStatusMessage] = useState<string | null>();
  const [cmsData, setCmsData] = useState<ClinicProviderDto | null>(null);
  const [selectedIncorrectData, setSelectedIncorrectData] = useState<
    { key: string; label: string; checked: boolean }[]
  >(cmsFields.concat(npiFields));

  const defaultErrorMessage =
    'Something went wrong, please try again later or contact admin';
  const [trigger, { isLoading, isError, data, error, isFetching, isSuccess }] =
    getClinicProviderNpiExternal.useLazyQuery();

  const [postAsync, postStatus] = usePostClinicProviderMutation();

  const FormSchema = Yup.object().shape({
    npi: Yup.string()
      .length(
        10,
        'Please enter a valid NPI, must numeric and 10 characters long'
      )
      .required('Please enter a valid NPI'),
  });

  const { handleSubmit, errors, touched, handleChange, values, resetForm } =
    useFormik({
      validationSchema: FormSchema,
      initialValues: {
        npi: '',
      },
      onSubmit: ({ npi }, { resetForm }) => {
        reset();
        resetSelectedIncorrectFields();
        alertDisclosure.onClose();
        setCompleted(false);
        trigger(npi);
      },
    });

  const handleNpiChange = (e: any) => {
    setStatusMessage(null);
    setPostStatusMessage(null);
    clearTable();
    handleChange(e);
    resetSelectedIncorrectFields();
  };

  useEffect(() => {
    setStatusMessage(null);
    setPostStatusMessage(null);

    if (isError) {
      alertDisclosure.onOpen();
      if (error && 'data' in error && error.data) {
        const _d = error.data as any;
        setStatus('warning');
        setStatusMessage(_d.errors);
      }
    }

    if (isLoading || isFetching) {
      clearTable();
      setStatusMessage(null);
    }
  }, [isLoading, isError, data, error, isFetching]);

  useEffect(() => {
    if (!isSuccess || isFetching) return;

    clearTable();
    const _d = data || null;

    setStatus('info');

    if (isSuccess && data) {
      setCmsData(_d);
    }
  }, [isSuccess, data, isFetching]);

  const onModalClose = () => {
    reset();
    onClose();
    resetForm();
    setStatusMessage(null);
    clearTable();
    resetSelectedIncorrectFields();
    alertDisclosure.onClose();
    setCompleted(false);
  };

  const clearTable = () => setCmsData({} as ClinicProviderDto);

  const resetSelectedIncorrectFields = () => {
    setSelectedIncorrectData([
      ...selectedIncorrectData.map(item => {
        return { ...item, checked: false };
      }),
    ]);
  };

  const resetModalBody = () => {
    reset();
    resetForm();
    clearTable();
    resetSelectedIncorrectFields();
  };

  const onCheckData = (key: string, checkFlag: boolean) => {
    const newList: any = [...selectedIncorrectData];
    const indexToUpdate = newList.findIndex(
      (f: any) => f.key.toLowerCase() === key.toLowerCase()
    );
    newList[indexToUpdate].checked = checkFlag;
    setSelectedIncorrectData(newList);
  };

  const nextStepClick = () => {
    if (activeStep === steps.length - 1) {
      const checkedIncorrectData = selectedIncorrectData
        .filter(f => f.checked)
        .map(m => m.key);
      console.log('error', checkedIncorrectData);
      setCompleted(true);
      postAsync({
        ref_user_id: logonUser?.ref_user_id || 0,
        data: {
          ...cmsData,
          provider_tin: organizationTin.toString(),
          incorrect_data_flag: checkedIncorrectData.length > 0,
          manual_entry_flag: false,
          user_organization_id: userOrgId,
          list_incorrect_fields: checkedIncorrectData,
        } as ClinicProviderDto,
      });

      return;
    }

    nextStep();
  };

  useEffect(() => {
    if (postStatus.isLoading) {
      alertDisclosure.onClose();
    }

    if (postStatus.isError) {
      alertDisclosure.onOpen();
      setCompleted(false);

      const errorObj = postStatus.error as any;
      const errorMessage = (errorObj?.data?.error_message || '').replace(
        'provider_npi',
        'NPI'
      );
      if (errorMessage) setPostStatusMessage(errorMessage);
      else {
        setPostStatusMessage(defaultErrorMessage);
      }
    }

    if (postStatus.isSuccess) {
      setPostStatusMessage('Provider added');
      alertDisclosure.onOpen();
      setCompleted(false);
      setTimeout(() => {
        resetModalBody();
        resetForm();
        alertDisclosure.onClose();
        setPostStatusMessage(null);
      }, 1700);
    }
  }, [
    postStatus.isLoading,
    postStatus.isSuccess,
    postStatus.isError,
    postStatus.error,
  ]);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onModalClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Box>Add Provider</Box>
              <Box>
                <form onSubmit={handleSubmit}>
                  <FormControl
                    isInvalid={!!errors.npi && touched.npi}
                    style={{ width: '100%', marginRight: '50px' }}
                  >
                    <HStack>
                      <FormLabel htmlFor="npi" mb={0}>
                        NPI
                      </FormLabel>
                      <Input
                        id="npi"
                        placeholder=" "
                        name="npi"
                        type="number"
                        w="180px"
                        onChange={handleNpiChange}
                        value={values.npi}
                        disabled={isFetching}
                        colorScheme="brand.main"
                      />
                      <Button type="submit" isLoading={isFetching}>
                        Search
                      </Button>
                    </HStack>
                  </FormControl>
                </form>
              </Box>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" spacing={3}>
              {/* search cms button errors */}
              {alertDisclosure.isOpen && statusMessage && (
                <Alert status={status}>
                  <AlertIcon />
                  {statusMessage.length ? (
                    <span>
                      {statusMessage.map((e: string, i: number) => (
                        <p key={'Key-' + i}>{e}</p>
                      ))}
                    </span>
                  ) : (
                    <span>
                      <p>{statusMessage}</p>
                    </span>
                  )}
                </Alert>
              )}
              {errors && values.npi.length !== 0 && errors.npi && (
                <Alert status="error">
                  <AlertIcon />
                  {errors.npi}
                </Alert>
              )}

              {cmsData?.provider_npi && !(isLoading || isFetching) && (
                <>
                  <CustomStepper>
                    <>
                      <CustomStepper.Steps>
                        <CustomStepper.Step label="" description="" id="Step 1">
                          <Flex flexDirection="column" alignItems="center">
                            <Box mt={4}>Check for information - CMS</Box>

                            <InstructionText />
                            <Box w="100%">
                              <CmsList
                                cmsData={cmsData}
                                onCheckData={onCheckData}
                                checkboxData={selectedIncorrectData}
                              />
                            </Box>
                          </Flex>
                        </CustomStepper.Step>
                        <CustomStepper.Step label="" description="" id="Step 2">
                          <Flex flexDirection="column" alignItems="center">
                            <Box mt={4}>Check for information - NPI</Box>
                            <InstructionText />
                            <Box w="100%">
                              <NpiList
                                npiData={cmsData}
                                onCheckData={onCheckData}
                                checkboxData={selectedIncorrectData}
                              />
                            </Box>
                          </Flex>
                        </CustomStepper.Step>
                      </CustomStepper.Steps>
                      {/* <Box py={3}>
                        {selectedIncorrectData.some((s) => s.checked) && (
                          <IncorrectTagList
                            list={selectedIncorrectData}
                            onClear={resetSelectedIncorrectFields}
                          />
                        )}
                      </Box> */}
                      {cmsData?.warning_message && (
                        <Alert status="warning" mb={3}>
                          <AlertIcon />
                          {cmsData.warning_message}
                        </Alert>
                      )}
                      <HStack justifyContent="space-between">
                        <Text as="div">TIN : {organizationTin}</Text>
                        <StepperActions
                          prevLabel="Previous"
                          nextLabel="Next"
                          finishLabel="Add Provider"
                          onNextStep={nextStepClick}
                        />
                      </HStack>
                    </>
                  </CustomStepper>
                </>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            {alertDisclosure.isOpen && postStatusMessage && (
              <Alert
                status={
                  postStatus.isSuccess
                    ? 'success'
                    : postStatus.isError
                    ? 'error'
                    : 'info'
                }
                mb={3}
              >
                <AlertIcon />
                {postStatusMessage}
              </Alert>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CmsModal;
