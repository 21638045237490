import type { StyleFunctionProps } from '@chakra-ui/react';
import brandColors from '../brandColors';

const Error = (props: StyleFunctionProps) => ({
  '.chakra-form-control': {
    '& > input[aria-invalid="true"]': {
      borderColor: brandColors.alert.red[300],
      boxShadow: `0 0 0 1px ${brandColors.alert.red[300]}`,
    },
    '.chakra-form__error-message': {
      color: brandColors.alert.red[300],
    },
  },
});
export default Error;
