import React, { useState } from "react";
import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import FocusLock from "react-focus-lock";
import { Month_Names_Short, Weekday_Names_Short } from "./utils/calanderUtils";
import { CalendarPanel } from "./utils/calendarPanel";
import {
  CalendarConfigs,
  DatepickerConfigs,
  DatepickerProps,
  OnDateSelected,
} from "./utils/commonTypes";
import { FaCalendarAlt } from "react-icons/fa";

export interface SingleDatepickerProps extends DatepickerProps {
  date?: Date;
  onDateChange: (date: Date) => void;
  configs?: DatepickerConfigs;
  disabled?: boolean;
  defaultIsOpen?: boolean;
  id?: string;
  name?: string;
  usePortal?: boolean;
}

const DefaultConfigs: CalendarConfigs = {
  dateFormat: "yyyy-MM-dd",
  monthNames: Month_Names_Short,
  dayNames: Weekday_Names_Short,
  firstDayOfWeek: 0,
};

const CustomDatePicker: React.FC<SingleDatepickerProps> = ({
  configs,
  propsConfigs,
  usePortal,
  defaultIsOpen = false,
  ...props
}) => {
  const {
    date: selectedDate,
    name,
    disabled,
    onDateChange,
    id,
    minDate,
    maxDate,
  } = props;

  const [dateInView, setDateInView] = useState(selectedDate);
  const [offset, setOffset] = useState(0);

  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen });

  const calendarConfigs: CalendarConfigs = {
    ...DefaultConfigs,
    ...configs,
  };

  const setCalendarToSelectedDate = () => {
    setDateInView(selectedDate);
    setOffset(0);
  };

  const onPopoverClose = () => {
    onClose();
    setCalendarToSelectedDate();
  };

  const onPopoverOpen = () => {
    onOpen();
    setCalendarToSelectedDate();
  };

  // dayzed utils
  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) return;
    if (date instanceof Date && !isNaN(date.getTime())) {
      onDateChange(date);
      onClose();
      return;
    }
  };

  const PopoverContentWrapper = usePortal ? Portal : React.Fragment;

  return (
    <Popover
      placement="bottom-end"
      variant="responsive"
      isOpen={isOpen}
      onOpen={onPopoverOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <InputGroup size="md">
        <Input
          onKeyPress={(e) => {
            if (e.key === " " && !isOpen) {
              e.preventDefault();
              onOpen();
            }
          }}
          id={id}
          autoComplete="off"
          isDisabled={disabled}
          name={name}
          value={
            selectedDate ? format(selectedDate, calendarConfigs.dateFormat) : ""
          }
          onChange={(e) => e.target.value}
          {...propsConfigs?.inputProps}
        />
        <PopoverTrigger>
          <InputRightElement h="100%">
            <IconButton
              icon={<FaCalendarAlt />}
              variant="link"
              aria-label="Show Calendar"
            />
          </InputRightElement>
        </PopoverTrigger>
      </InputGroup>
      <PopoverContentWrapper>
        <PopoverContent width="100%">
          <PopoverBody>
            <FocusLock>
              <CalendarPanel
                dayzedHookProps={{
                  showOutsideDays: true,
                  onDateSelected: handleOnDateSelected,
                  selected: selectedDate,
                  date: dateInView,
                  minDate: minDate,
                  maxDate: maxDate,
                  offset: offset,
                  onOffsetChanged: setOffset,
                  firstDayOfWeek: calendarConfigs.firstDayOfWeek,
                }}
                configs={calendarConfigs}
                propsConfigs={propsConfigs}
              />
            </FocusLock>
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  );
};

export default CustomDatePicker;