import { useIsAuthenticated } from '@azure/msal-react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  UserRoleAppAccessByEmailModel,
  UserRoleDto,
} from '../../app/services/provider/types';
import { useAppSelector } from '../../app/state/hooks';
import {
  useLazyGetUserAppAccessListByUserEmailQuery,
  useLazyGetUserRoleListByRefUserIdQuery,
} from '../services/provider/api/userRole';
import {
  setCurrentUserAppAccess,
  setCurrentUserRole,
} from '../slices/userRoleSlice';

type ContextType = {
  userAppAccess: UserRoleAppAccessByEmailModel[];
  userRole: UserRoleDto[];
  isUserLoreAdmin: boolean;
  isUserLoreTeam: boolean;
};
export const UserRoleContext = createContext<ContextType>({
  userAppAccess: [],
  userRole: [],
  isUserLoreAdmin: false,
  isUserLoreTeam: false,
});

export const useUserRoleContext = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error('Context error');
  }

  return context;
};

export const UserRoleProvider = ({ children }: any) => {
  const { logonUser } = useAppSelector(s => s.user);
  const { currentUserUserAppAccess, currentUserRole } = useAppSelector(
    s => s.userRole
  );
  const [userRole, setUserRole] = useState<UserRoleDto[]>(
    currentUserRole || []
  );
  const [userAppAccess, setUserAppAccess] = useState<
    UserRoleAppAccessByEmailModel[]
  >(currentUserUserAppAccess || []);
  const isAuthenticated = useIsAuthenticated();
  const [trigger] = useLazyGetUserAppAccessListByUserEmailQuery();
  const [triggerUserRole] = useLazyGetUserRoleListByRefUserIdQuery();
  const dispatch = useDispatch();

  const isUserLoreAdmin = useMemo(() => {
    return userRole.some(s => s.role_name === 'lore.health.administrator');
  }, [userRole]);
  const isUserLoreTeam = useMemo(() => {
    return userRole.some(
      s =>
        s.role_name === 'lore.health.administrator' ||
        s.role_name === 'lore.health.team'
    );
  }, [userRole]);

  useEffect(() => {
    if (isAuthenticated) {
      if (currentUserRole && currentUserRole.length > 0) {
        setUserRole(currentUserRole);
      } else {
        triggerUserRole(logonUser?.ref_user_id || -1).then(resp => {
          if (resp.data) {
            setUserRole(resp.data);
            dispatch(setCurrentUserRole(resp.data));
          } else {
            setUserRole([]);
            dispatch(setCurrentUserRole([]));
          }
        });
      }

      if (currentUserUserAppAccess && currentUserUserAppAccess.length > 0) {
        setUserAppAccess(currentUserUserAppAccess);
      } else {
        trigger(logonUser?.user_email || '').then(resp => {
          if (resp.data) {
            const _d = resp.data.result.filter(f => f.access_flag);
            setUserAppAccess(_d);
            dispatch(setCurrentUserAppAccess(_d));
          } else {
            setUserAppAccess([]);
            dispatch(setCurrentUserAppAccess([]));
          }
        });
      }
    }
  }, [logonUser?.user_email, isAuthenticated]);

  return (
    <UserRoleContext.Provider
      value={{ userAppAccess, userRole, isUserLoreAdmin, isUserLoreTeam }}
    >
      {children}
    </UserRoleContext.Provider>
  );
};
