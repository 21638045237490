import {
  UpdateUserRoleDto,
  UserRoleAppAccessResultByEmailModel,
  UserRoleDto,
  UsersByRefRoleIdDto,
} from '../types';
import { providerApi } from './index';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getUserRoleListByRefUserId: builder.query<UserRoleDto[], number>({
      query: ref_user_id => `/userrole/${ref_user_id}/user`,
      providesTags: ['UserRole'],
    }),
    getUserRoleListByEmail: builder.query<UserRoleDto[], string>({
      query: email => `/userrole/${email}/email`,
      providesTags: ['UserRole'],
    }),
    getUserAppAccessListByUserEmail: builder.query<
      UserRoleAppAccessResultByEmailModel,
      string
    >({
      query: email => `/userrole/appaccess/email/${email}`,
      providesTags: ['UserRole'],
    }),
    getUserRoleListByRefRoleId: builder.query<UsersByRefRoleIdDto[], number>({
      query: ref_role_id => `/userrole/users/${ref_role_id}`,
      providesTags: ['UserRole'],
    }),

    putUserRole: builder.mutation<
      void,
      { ref_user_id: number; model: UpdateUserRoleDto[] }
    >({
      query: params => ({
        url: `/UserRole/user/${params.ref_user_id}`,
        method: 'PUT',
        body: params.model,
      }),
      invalidatesTags: ['UserRole'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserRoleListByEmailQuery,
  useGetUserRoleListByRefUserIdQuery,
  useGetUserAppAccessListByUserEmailQuery,
  useGetUserRoleListByRefRoleIdQuery,
  useLazyGetUserAppAccessListByUserEmailQuery,
  usePutUserRoleMutation,
  useLazyGetUserRoleListByRefUserIdQuery,
  useLazyGetUserRoleListByRefRoleIdQuery,
} = extendedApi;
