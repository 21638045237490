import { Flex, useColorModeValue, Heading, Link } from '@chakra-ui/react';
import { appColors } from '../../app/theme';
import Footer from '../../components/Footer';

const Offline = () => {
  const isTest = Boolean(process.env.REACT_APP_BORDERED_HEADER);
  return (
    <>
      <Flex
        bg="brand.main"
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        w="100%"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottomWidth={isTest ? 5 : 1}
        borderBottomColor={isTest ? appColors.testEnvironmentIndicator : ''}
        borderStyle={'solid'}
        borderTopColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        justifyContent="center"
        position="fixed"
        zIndex={3}
      >
        <Heading color="white" size={{ base: 'sm', sm: 'md' }}>
          Provider Portal
        </Heading>
      </Flex>

      <div
        style={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <article>
          <h1>We&rsquo;ll be back soon!</h1>
          <div>
            <p>
              Sorry for the inconvenience but we&rsquo;re performing some
              maintenance at the moment. If you need to you can always{' '}
              <Link href="mailto:app_pcm@sequel.ae" isExternal color="blue.500">
                contact us
              </Link>{' '}
              , otherwise we&rsquo;ll be back online shortly!
            </p>
            <p>&mdash; The Team</p>
          </div>
        </article>
      </div>

      <Footer />
    </>
  );
};

export default Offline;
