import {
  OrganizationContext,
  useChooseOrgContext,
} from '@/app/context/ChooseOrgContext';
import { useUserRoleContext } from '@/app/context/UserRoleContext';
import { useGetOrganizationListQuery } from '@/app/services/provider/api/organization';
import {
  useGetDeviceOrderListQuery,
  usePutDeviceOrderListMutation,
} from '@api/deviceOrder';
import { ChatIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Radio,
  Table,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import CustomTable from '@components/CustomTable';
import { DeviceOrderStatus } from '@constants';
import { convertUtcToLocal } from '@helpers/dateHelper';
import { useDebounce } from '@hooks/useDebounce';
import { GetDeviceOrderListData, PutDeviceOrderListDto } from '@provider/types';
import { appColors } from '@theme';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Row, SortingRule } from 'react-table';

const InitialSortBy: SortingRule<GetDeviceOrderListData> = {
  id: 'device_order_id',
  desc: false,
};

export type AdditionalDeviceFieldsType = {
  newDeviceOrderStatusId?: number;
  tracking_notes?: string;
};

export const InflammationMedicalGroupOrgTin = '871973582';

export const initialDeviceOrderState: GetDeviceOrderListData &
  AdditionalDeviceFieldsType = {
  device_order_id: 0,
  pim_ref_user_id: 0,
  community_ref_user_id: 0,
  user_workflow_id: 0,
  ref_device_id: 0,
  device_type: '',
  first_name: '',
  last_name: '',
  date_of_birth: '',
  street_address: '',
  city: '',
  state: '',
  zip: '',
  attributed_provider: '',
  attested_to_lifestyle_use: false,
  row_created_datetime_utc: '',
  row_created_ref_user_id: 0,
  ref_device_order_status_id: 0,
  status_datetime_utc: '',
  status_name: '',
  status_display_name: '',
  row_created_by_user_name: '',
  row_modified_datetime_utc: '',
  row_modified_ref_user_id: 0,
  row_modified_by_user_name: '',
  consented_to_study: false,
  pregnancy_status: false,
  insulin_use: false,
  sku: '',
  sku_description: '',
  mbi: '',
  phone: '',
  email: '',
  potential_action: '',
  organization_name: '',
  status_created_by_user_name: '',
  status_created_datetime_utc: '',
  tracking_notes: '',
  partner_name: '',
  // gender: '',
  // is_pregnant_or_plan_to_be_pregnant: null,
  // medication: '',
  // notes_to_doctor: '',
  // preexisting_medical_conditions_surgeries_hospitalizations: '',
  request_datetime_utc: '',
  approved_datetime_utc: '',
  gpid: '',
};

const OrdersToSignCgmInsulin = () => {
  const cancelRef = useRef(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<SortingRule<object>>(InitialSortBy);
  const [list, setList] = useState<PutDeviceOrderListDto>([]);
  const [deviceOrderActive, setDeviceOrderActive] = useState<
    GetDeviceOrderListData & AdditionalDeviceFieldsType
  >(initialDeviceOrderState);
  const [orgIdList, setOrgIdList] = useState<number[]>([]);

  const [searchDebounce, setSearchDebounce] = useState('');
  const debouncedSearch = useDebounce(search, 400);

  const { userOrganizations } = useChooseOrgContext() as OrganizationContext;
  const { isUserLoreTeam } = useUserRoleContext();
  const [putAsync, putDetail] = usePutDeviceOrderListMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRejectDialog,
    onOpen: onOpenRejectDialog,
    onClose: onCloseRejectDialog,
  } = useDisclosure();
  const { data, isLoading, isFetching } = useGetDeviceOrderListQuery({
    ref_device_order_status_id: DeviceOrderStatus.created,
    PageNumber: pageIndex + 1,
    PageSize: pageSize,
    q: searchDebounce,
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    insulin_use: true,
    organization_id_list:
      !isUserLoreTeam && orgIdList.length < 1
        ? userOrganizations?.map(({ organization_id }) => organization_id) ?? [
            -1,
          ] // none lore team should not fetch all patients
        : orgIdList,
  });
  const orgListDetail = useGetOrganizationListQuery(
    { filter: { page_number: 1, page_size: 99999, search: '' } },
    { skip: !isUserLoreTeam }
  );

  const handleOnClickStatus =
    (
      ref_device_order_status_id: number,
      entry: GetDeviceOrderListData & AdditionalDeviceFieldsType,
      forceSetCheck = false
    ) =>
    () => {
      const itemInList = list.find(
        item => item.device_order_id === entry.device_order_id
      );
      const isRemovedCheck =
        itemInList?.ref_device_order_status_id === ref_device_order_status_id &&
        !forceSetCheck;

      if (itemInList) {
        setList(l => {
          if (isRemovedCheck) {
            return l.filter(m => m.device_order_id !== entry.device_order_id);
          }

          return l.map(m => {
            if (m.device_order_id === entry.device_order_id) {
              return {
                device_order_id: entry.device_order_id || 0,
                tracking_notes: entry.tracking_notes || '',
                ref_device_order_status_id,
              };
            }
            return m;
          });
        });
      } else {
        setList(l => [
          ...l,
          {
            device_order_id: entry.device_order_id || 0,
            ref_device_order_status_id,
            tracking_notes: entry.tracking_notes || '',
          },
        ]);
      }
      return isRemovedCheck;
    };

  const handleSubmit = () => {
    onClose();
    putAsync(list).then(() => setList([]));
  };

  const openReasonRejectDialog = (
    refDeviceOrderStatusId: number,
    entry: GetDeviceOrderListData & AdditionalDeviceFieldsType
  ) => {
    const isRemovedCheckAction = handleOnClickStatus(
      refDeviceOrderStatusId,
      entry
    )();
    if (isRemovedCheckAction) {
      return null;
    }

    setDeviceOrderActive({
      ...entry,
      newDeviceOrderStatusId: refDeviceOrderStatusId || 0,
      ref_device_order_status_id: entry.ref_device_order_status_id || 0,
      device_order_id: entry.device_order_id || 0,
      tracking_notes: entry.tracking_notes || '',
    });
    onOpenRejectDialog();
  };

  const saveMessage = () => {
    handleOnClickStatus(
      deviceOrderActive?.newDeviceOrderStatusId || 0,
      {
        ...deviceOrderActive,
        tracking_notes: messageInputRef.current?.value || '',
      },
      true
    )();
    setDeviceOrderActive({
      ...deviceOrderActive,
      newDeviceOrderStatusId: 0,
      ref_device_order_status_id: 0,
      device_order_id: 0,
      tracking_notes: '',
    });
    onCloseRejectDialog();
  };

  const handleSelectOrg = (orgId: number) => () => {
    const index = orgIdList.findIndex(f => f === orgId);
    if (index < 0) {
      setOrgIdList(s => [...s, orgId]);
    } else {
      setOrgIdList(s => {
        const list = s.slice();
        list.splice(index, 1);
        return list;
      });
    }
  };

  const datalist = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const isAllForApproval = useMemo(() => {
    return (
      datalist.length > 0 &&
      datalist.every(e =>
        list.some(
          s =>
            s.device_order_id === e.device_order_id &&
            s.ref_device_order_status_id === DeviceOrderStatus.approved
        )
      )
    );
  }, [datalist, list]);

  const isSomeForApproval = useMemo(() => {
    return datalist.some(e =>
      list.some(
        s =>
          s.device_order_id === e.device_order_id &&
          s.ref_device_order_status_id === DeviceOrderStatus.approved
      )
    );
  }, [datalist, list]);

  const hasNoForApproval = useMemo(() => {
    return !isAllForApproval && !isSomeForApproval;
  }, [isAllForApproval, isSomeForApproval]);

  // determine to show additional fields
  const isUserInflammationMedicalGroup = useMemo(() => {
    return !!userOrganizations?.some(
      s => s.organization_tin === InflammationMedicalGroupOrgTin
    );
  }, [userOrganizations]);
  // const showAdditionalFields = useMemo(() => {
  //   return isUserInflammationMedicalGroup || isUserLoreTeam;
  // }, [isUserInflammationMedicalGroup, isUserLoreTeam]);

  const cgmHeaders = [
    // ...(showAdditionalFields
    //   ? [
    //       {
    //         Header: 'Action',
    //         isSticky: true,
    //         style: { minWidth: '120px' },
    //         Cell: ({
    //           row: { original },
    //         }: {
    //           row: Row<GetDeviceOrderListData>;
    //         }) => {
    //           return <OrdersToSignAdditionalInfoButton entry={original} />;
    //         },
    //       },
    //     ]
    //   : []),
    {
      Header: 'First Name',
      accessor: 'first_name',
      isSticky: true,
    },
    // {
    //   Header: "Middle Name",
    //   accessor: "middle_name",
    //   style: { textAlign: "center" },
    //   Cell: () => <>-</>,
    // },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      isSticky: true,
    },
    {
      Header: 'DOB',
      Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
        return (
          <>{convertUtcToLocal(original.date_of_birth, 'YYYY-MM-DD') || '-'}</>
        );
      },
    },
    // ...(showAdditionalFields
    //   ? [
    //       {
    //         Header: 'Sex',
    //         accessor: 'gender',
    //         isSortable: false,
    //       },
    //       {
    //         Header: 'Phone',
    //         accessor: 'phone',
    //         isSortable: false,
    //       },
    //     ]
    //   : []),
    {
      Header: 'Lore ID',
      accessor: 'community_ref_user_id',
      style: { textAlign: 'center' },
    },
    {
      Header: 'Assigned Provider',
      accessor: 'attributed_provider',
    },
    ...(isUserInflammationMedicalGroup
      ? [
          {
            Header: 'Partner Name',
            accessor: 'partner_name',
          },
        ]
      : []),
    {
      Header: 'Requested Date',
      style: { textAlign: 'center' },
      Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
        return (
          <>
            {convertUtcToLocal(
              original.row_created_datetime_utc,
              'YYYY-MM-DD'
            ) || '-'}
          </>
        );
      },
    },
    // {
    //   Header: "Pregnant",
    //   accessor: "pregnancy_status",
    //   style: { textAlign: "center" },
    //   Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
    //     return <>{original.pregnancy_status ? "YES" : "NO"}</>;
    //   },
    // },
    // {
    //   Header: 'Consented to Study',
    //   accessor: 'consented_to_study',
    //   style: { textAlign: 'center' },
    //   Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
    //     return <>{original.consented_to_study ? 'YES' : 'NO'}</>;
    //   },
    // },
    {
      Header: 'Attested to Lifestyle Use',
      accessor: 'attested_to_lifestyle_use',
      style: { textAlign: 'center' },
      Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
        return <>{original.attested_to_lifestyle_use ? 'YES' : 'NO'}</>;
      },
    },
    // {
    //   Header: "Insulin Regimen",
    //   accessor: "insulin_regimen",
    //   style: { textAlign: "center" },
    //   Cell: () => <>-</>,
    // },
    // {
    //   Header: 'Insulin Use',
    //   accessor: 'insulin_use',
    //   style: { textAlign: 'center' },
    //   Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
    //     return <>{original.insulin_use ? 'YES' : 'NO'}</>;
    //   },
    // },
    // {
    //   Header: 'Potential Action',
    //   accessor: 'potential_action',
    // },
    {
      Header: 'Order CGM',
      HeaderCell: (
        <HStack gap="1">
          <Text>Order CGM</Text>
          <Checkbox
            isChecked={isAllForApproval}
            isIndeterminate={!isAllForApproval && isSomeForApproval}
            onChange={() => {
              if (hasNoForApproval || isSomeForApproval) {
                const l = [...list];
                datalist.forEach(fe => {
                  const index = l.findIndex(
                    fi => fe.device_order_id === fi.device_order_id
                  );
                  if (index < 0) {
                    l.push({
                      device_order_id: fe.device_order_id,
                      ref_device_order_status_id: DeviceOrderStatus.approved,
                      tracking_notes: '',
                    });
                  } else {
                    l[index].ref_device_order_status_id =
                      DeviceOrderStatus.approved;
                  }
                });
                setList(l);
              }
            }}
          />
        </HStack>
      ),
      style: { textAlign: 'center' },
      Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
        return (
          <Radio
            colorScheme="brand.main"
            isDisabled={putDetail.isLoading}
            isChecked={list.some(
              s =>
                s.device_order_id === original.device_order_id &&
                s.ref_device_order_status_id === DeviceOrderStatus.approved
            )}
            onClick={handleOnClickStatus(DeviceOrderStatus.approved, original)}
          />
        );
      },
    },
    {
      Header: 'CGM Ordered Via EMR',
      style: { textAlign: 'center' },
      Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
        return (
          <Radio
            colorScheme="brand.main"
            isChecked={list.some(
              s =>
                s.device_order_id === original.device_order_id &&
                s.ref_device_order_status_id === DeviceOrderStatus.emr
            )}
            onClick={handleOnClickStatus(DeviceOrderStatus.emr, original)}
          />
        );
      },
    },
    {
      Header: 'Reject Patient CGM Request',
      style: { textAlign: 'center' },
      Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
        const foundItemInList = list.find(
          item =>
            item.device_order_id === original.device_order_id &&
            item.ref_device_order_status_id === DeviceOrderStatus.declined
        );
        return (
          <Box position="relative">
            <Radio
              colorScheme="brand.main"
              isChecked={Boolean(foundItemInList)}
              onClick={() => {
                openReasonRejectDialog(DeviceOrderStatus.declined, original);
              }}
            />
            {foundItemInList?.tracking_notes ? (
              <Tooltip
                closeOnScroll
                label={foundItemInList.tracking_notes}
                aria-label="rejection notes"
              >
                <ChatIcon
                  position="absolute"
                  color="gray.300"
                  ml={1}
                  mb={1}
                  fontSize="15px"
                />
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <>
      {(isUserLoreTeam || (userOrganizations ?? []).length > 1) && (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Choose Organization{' '}
            {orgIdList.length > 0 ? `(${orgIdList.length})` : ''}
          </MenuButton>
          <MenuList h="70vh" overflowY="auto" zIndex={3}>
            {(isUserLoreTeam && orgListDetail.data
              ? orgListDetail.data.data
              : userOrganizations
            )?.map(m => (
              <Box key={m.organization_id} px="4" py="2">
                <Checkbox
                  isChecked={orgIdList.some(s => s === m.organization_id)}
                  onChange={handleSelectOrg(m.organization_id)}
                >
                  <Text title={m.organization_name} noOfLines={1} maxW="96">
                    {m.organization_name}
                  </Text>
                </Checkbox>
              </Box>
            ))}
            <HStack px="4">
              <Button
                variant="ghost"
                ml="auto"
                onClick={() => setOrgIdList([])}
              >
                Clear
              </Button>
            </HStack>
          </MenuList>
        </Menu>
      )}

      {putDetail.isSuccess ? (
        <Box mt="4">
          <Alert status="success" size="sm">
            <AlertIcon />
            <Text fontSize={16} fontWeight="normal">
              Successfully submitted entries.
            </Text>
          </Alert>
        </Box>
      ) : (
        !isLoading &&
        !isFetching &&
        datalist.length < 1 && (
          <Box mt="4">
            <Alert size="sm" bg={appColors.hightLightColor}>
              <AlertIcon />
              <Text fontSize={16} fontWeight="normal">
                No data found.
              </Text>
            </Alert>
          </Box>
        )
      )}

      <CustomTable
        isLoading={isLoading}
        isFetching={isFetching}
        data={datalist}
        pageCount={(data && data.total_pages) || 0}
        pageSize={(data && data.page_size) || pageSize}
        totalRecords={(data && data.total_records) || 0}
        pageIndex={pageIndex}
        headers={cgmHeaders}
        // @ts-expect-error: please fix if encountered
        initialState={{ sortBy: [InitialSortBy] }}
        hasFilter
        hasLoadingIndicator
        manual
        manualSortBy
        disableSortRemove
        isPageNumberEditable
        onPageChange={index => {
          if ((data?.total_pages || 1) > index) setPageIndex(index);
        }}
        onPageSizeChange={size => {
          setPageIndex(0);
          setPageSize(size);
        }}
        onPageSearch={search => {
          setPageIndex(0);
          setSearch(search);
        }}
        onSort={sort => {
          if (sort[0]) setSortBy(sort[0]);
        }}
      />
      <VStack pt="8" mx="auto" gap={5} textAlign="left">
        {/* <Box w="100%" bgColor={brandColors.primary.green[300]} textAlign="center" py="2">
            <Text color="white" as="b">
              ATTESTATION
            </Text>
          </Box> */}
        {/* <Box w="100%" bgColor={brandColors.primary.green[100]} textAlign="left" py="2" px="4"> */}
        <Button
          w="52"
          colorScheme="brand.main"
          onClick={onOpen}
          isDisabled={list.length < 1 || putDetail.isLoading}
          isLoading={putDetail.isLoading}
        >
          Attest and Submit
        </Button>
        <Text w="full" textDecor="underline">
          Signing Orders for Continuous Glucose Monitors
        </Text>
        <Text w="full">
          Please review the above patient details and choose the appropriate
          action for your patient:
        </Text>
        <OrderedList px="8">
          <ListItem>
            Order CGM if you would like Lore to ship a continuous glucose
            monitor to the participant.
            <Box m="4">
              <Text>Prescription: Dexcom G6 sensor </Text>
              <Text>NDC: 08627-0053-03 </Text>
              <Text>Dispense: 1 (30-day supply) </Text>
              <Text>Refill: 3 per year </Text>

              <Text py="2">AND</Text>

              <Text>Prescription: Dexcom G6 transmitter </Text>
              <Text>NDC: 08627-0016-01 </Text>
              <Text>Dispense: 1 (90-day supply) </Text>
              <Text>Refill: 4 per year</Text>
            </Box>
          </ListItem>
          <ListItem>
            CGM is ordered via EMR if the participant should have a continuous
            glucose monitor covered under Medicare and you have sent a
            continuous glucose monitor prescription to the participant's
            pharmacy or other supplier.
          </ListItem>
          <ListItem>
            Reject patient CGM request if you determine the participant should
            not receive a continuous glucose monitor.
          </ListItem>
        </OrderedList>
        <Text w="full">
          The above information is intended as a guide only; all review
          determinations and actions are up to your judgment.
        </Text>
        <Text w="full">
          Perform a chart review to determine if your patients need a phone,
          telehealth, or in-person consultation to discuss how to use the
          continuous glucose monitor and dose insulin. If the patient is on
          short-acting insulin, the continuous glucose monitor may be a Medicare
          covered benefit. In this case, their continuous glucose monitor order
          must be made in your electronic medical record and fulfilled by the
          patient's pharmacy or durable medical equipment provider. Once this is
          done, select “CGM is ordered via EMR”.
        </Text>
        <Text>
          If a patient is not eligible for a continuous glucose monitor as a
          Medicare benefit, but you want them to receive a continuous glucose
          monitor, you must order the continuous glucose monitor through Lore.
          All patients will be notified when their continuous glucose monitor
          request is confirmed or rejected.
        </Text>
        {/* </Box> */}
        {/* <Box w="100%" bgColor={brandColors.primary.green[50]} textAlign="left" py="2" px="4">
            <Table size="sm">
              <Tbody
                sx={{
                  "& tr > th, tr > td": {
                    border: "none",
                  },
                }}
              >
                <Tr>
                  <Th>Designated Provider:</Th>
                  <Td>Jared Jones</Td>
                </Tr>
                <Tr>
                  <Th>Organization:</Th>
                  <Td>USC Care Medical Group Inc.</Td>
                </Tr>
                <Tr>
                  <Th>Date Ordered:</Th>
                  <Td>{convertUtcToLocal(new Date().toISOString(), "YYYY-MM-DD") || "-"}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <HStack>
            <Button variant="outline" colorScheme="brand.main">
              Cancel
            </Button>
            <Button colorScheme="brand.main">Order</Button>
          </HStack> */}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attest and Submit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="striped" size="sm">
              <Tr>
                <Td>
                  <Text fontWeight="bold">Orders to Approve:</Text>
                </Td>
                <Td>
                  <Text>
                    {
                      list.filter(
                        f =>
                          f.ref_device_order_status_id ===
                          DeviceOrderStatus.approved
                      ).length
                    }
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Orders to confirm (EMR):</Text>
                </Td>
                <Td>
                  <Text>
                    {
                      list.filter(
                        f =>
                          f.ref_device_order_status_id === DeviceOrderStatus.emr
                      ).length
                    }
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Orders to Reject:</Text>
                </Td>
                <Td>
                  <Text>
                    {
                      list.filter(
                        f =>
                          f.ref_device_order_status_id ===
                          DeviceOrderStatus.declined
                      ).length
                    }
                  </Text>
                </Td>
              </Tr>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.main"
              mr={3}
              onClick={handleSubmit}
              isDisabled={putDetail.isLoading}
            >
              Submit
            </Button>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpenRejectDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCloseRejectDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reason for rejection
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input ref={messageInputRef} defaultValue="" />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseRejectDialog}>
                Cancel
              </Button>
              <Button colorScheme="teal" onClick={saveMessage} ml={3}>
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

// export const OrdersToSignAdditionalInfoButton = ({
//   entry,
// }: {
//   entry: GetDeviceOrderListData;
// }) => {
//   const { isOpen, onClose, onOpen } = useDisclosure();

//   return (
//     <Box>
//       <Button
//         aria-label="Additional Patient Info"
//         onClick={onOpen}
//         variant="link"
//         colorScheme="brand.main"
// size="sm"
// h="4"
// w="4"
//       >
//         <Text fontSize="sm">View Details</Text>
//       </Button>

//       <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Additional Patient Info</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <VStack
//               gap="8"
//               maxH={'400px'}
//               overflowY="auto"
//               textAlign="left"
//               justifyContent="start"
//               alignItems="start"
//               sx={{
//                 '.chakra-stack': {
//                   alignItems: 'start',
//                 },
//               }}
//             >
//               <VStack justifyContent="start">
//                 <Heading as="h5" size="sm">
//                   Patient Name
//                 </Heading>
//                 <Text>{entry.first_name + ' ' + entry.last_name}</Text>
//               </VStack>
//               <VStack gap="2">
//                 <Heading as="h5" size="sm">
//                   Please list any pre-exsiting medical conditions, prior
//                   sugeries, or hospitalizations.
//                 </Heading>
//                 <Text>
//                   {entry.preexisting_medical_conditions_surgeries_hospitalizations ||
//                     '-'}
//                 </Text>
//               </VStack>
//               <VStack gap="2">
//                 <Heading as="h5" size="sm">
//                   Please list any current medications, supplements, or over the
//                   counter treatments vou are currently taking. Please list
//                   frequency and dosage.
//                 </Heading>
//                 <Text>{entry.medication || '-'}</Text>
//               </VStack>
//               <VStack gap="2" textAlign="left">
//                 <Heading as="h5" size="sm">
//                   Are you pregnant or plan to be pregnant in the near future?
//                 </Heading>
//                 <Text>
//                   {entry.is_pregnant_or_plan_to_be_pregnant === null ||
//                   entry.is_pregnant_or_plan_to_be_pregnant === undefined
//                     ? '-'
//                     : entry.is_pregnant_or_plan_to_be_pregnant
//                     ? 'Yes'
//                     : 'No'}
//                 </Text>
//               </VStack>
//               <VStack gap="2">
//                 <Heading as="h5" size="sm">
//                   Is there anything else you would like the doctor to know about
//                   your health condition?
//                 </Heading>
//                 <Text>{entry.notes_to_doctor || '-'}</Text>
//               </VStack>
//             </VStack>
//           </ModalBody>

//           <ModalFooter>
//             <Button colorScheme="brand.main" mr={3} onClick={onClose}>
//               Close
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </Box>
//   );
// };

export default OrdersToSignCgmInsulin;
