import { providerApi } from './index';
import {
  GetUserByUpnDto,
  PostUserDto,
  UserPagedDto,
  UserInfoDto,
  SearchParamsDto,
  DeleteUserDto,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getUserList: builder.query<UserPagedDto, SearchParamsDto>({
      query: params =>
        `/user?pagenumber=${params.page_number}&pagesize=${params.page_size}&q=${params.search}`,
      providesTags: ['User'],
    }),
    getUserByUpn: builder.query<UserInfoDto, GetUserByUpnDto>({
      query: ({ upn }) => `/user/email/${upn}`,
      providesTags: ['User'],
    }),
    getUserById: builder.query<UserInfoDto, number>({
      query: refUserId => `/user/${refUserId}`,
      providesTags: ['User'],
    }),

    postUser: builder.mutation<void, PostUserDto>({
      query: params => ({
        url: '/user',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<void, DeleteUserDto>({
      query: params => ({
        url: `/user/${params.ref_user_id}`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: () => {
        return ['User'];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserListQuery,
  useGetUserByUpnQuery,
  usePostUserMutation,
  useLazyGetUserByUpnQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUserListQuery,
  useDeleteUserMutation,
} = extendedApi;
