import { Grid, GridItem, Heading, HStack } from '@chakra-ui/layout';
import React, { useState } from 'react';
import { OrganizationDto } from '../../app/services/provider/types';
import { appColors } from '../../app/theme';
import { PanelCard } from '../../components/PanelCard';
import { AddOrgButton } from './AddOrgButton';
import OrganizationList from './OrganizationList';
import UserOrganizationList from './UserOrganizationList';
type Props = object;

const Organization = (props: Props) => {
  const [selectedOrg, setSelectedOrg] = useState<OrganizationDto | null>(null);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const onClickOrg = (org: OrganizationDto | null) => {
    setSelectedOrg(org);
    if (selectedOrg) {
      if (selectedOrg.organization_id !== org?.organization_id) {
        setIsUsersLoading(true);
      }
    }
  };

  const clearSelected = () => {
    setSelectedOrg(null);
  };
  return (
    <Grid templateColumns="repeat(2, minmax(50%, 1fr))" gap={3} w="full">
      <GridItem>
        <PanelCard
          header={
            <HStack
              align="stretch"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading as="h5" size="md">
                Manage Organizations
              </Heading>
              <AddOrgButton />
            </HStack>
          }
          fullHeight
        >
          <OrganizationList selectedOrg={selectedOrg} onClickOrg={onClickOrg} />
        </PanelCard>
      </GridItem>
      {selectedOrg && (
        <GridItem>
          <PanelCard
            header={
              <Heading
                as="h5"
                size="md"
                textTransform="capitalize"
                color={appColors.body}
              >
                {selectedOrg?.organization_name.toLowerCase()}
              </Heading>
            }
            fullHeight
            onClose={() => {
              clearSelected();
            }}
          >
            <UserOrganizationList
              selectedOrg={selectedOrg}
              isUsersLoading={isUsersLoading}
              handleIsUsersLoading={setIsUsersLoading}
            />
          </PanelCard>
        </GridItem>
      )}
    </Grid>
  );
};

export default Organization;
