import { AddIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { validateEmail } from '../../app/helpers/stringHelper';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { usePostPimGraphMutation } from '../../app/services/provider/api/pim';
import { useLazyGetUserByUpnQuery } from '../../app/services/provider/api/user';
import {
  GetUserOrganizationDto,
  PostPimGraphDto,
} from '../../app/services/provider/types';
import { useAppSelector } from '../../app/state/hooks';
import { appColors } from '../../app/theme';
import { AppAccessAuth } from '../../app/types/appAccessAuth';

type Props = {
  orgId: number;
  onSuccess: (user: GetUserOrganizationDto) => void;
};

const AddRefUserButton: FC<Props> = ({ orgId, onSuccess }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertDisclosure = useDisclosure();
  const { logonUser } = useAppSelector(s => s.user);

  const [postAsync, { isLoading, isSuccess, isError, error }] =
    usePostPimGraphMutation();

  const [getUserByUpnTrigger, { isFetching }] = useLazyGetUserByUpnQuery();
  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.userWrite
  );

  const initialValues: PostPimGraphDto = {
    email: '',
    first_name: '',
    last_name: '',
    sso_name: process.env.REACT_APP_SSO_NAME || '',
    source: process.env.REACT_APP_PIM_GRAPH_SOURCE || '',
    items: [
      {
        key: process.env.REACT_APP_PIM_GRAPH_ITEM_KEY_ORG_ID || '',
        value: String(orgId),
      },
      {
        key: process.env.REACT_APP_PIM_GRAPH_ITEM_KEY_REF_USER_ID || '',
        value: String(logonUser?.ref_user_id),
      },
    ],
  };

  const postSubmit = async (values: PostPimGraphDto) => {
    const params = {
      ...values,
    };
    try {
      await postAsync(params);
      const newUser: any = {
        email: params.email,
        consent_date_time_utc: '',
        first_name: params.first_name,
        last_name: params.last_name,
        middle_name: '',
        user_organization_id: 0,
        ref_user_id: 0,
        organization_id: orgId,
        can_be_deleted: false,
      };
      onSuccess(newUser);
    } catch (e) {
      console.log('error', e);
    }
  };

  const FormSchema = Yup.object().shape({
    email: Yup.string().required(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
  });

  const { handleSubmit, errors, touched, handleChange, values, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema: FormSchema,
      initialValues: initialValues,
      onSubmit: async values => {
        alertDisclosure.onClose();
        const userExist = await getUserByUpnTrigger({ upn: values.email });
        if (!validateEmail(values.email) || values.email.includes('+')) {
          errors.email = 'Email is invalid';
        } else if (userExist.data) {
          errors.email = 'User with this email already exists';
        } else {
          const val = {
            ...values,
          };
          await postSubmit(val);
        }
      },
    });

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          onClose();
          resetForm();
          alertDisclosure.onClose();
        }, 2000);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  useEffect(() => {
    return () => {
      resetForm();
      alertDisclosure.onClose();
    };
  }, [isOpen]);
  return (
    <>
      {userHasRequiredRole ? (
        <>
          <Stack align="start">
            <Button
              leftIcon={<AddIcon />}
              boxShadow="sm"
              size="sm"
              onClick={onOpen}
            >
              Invite New User
            </Button>
          </Stack>
          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <form onSubmit={handleSubmit}>
              {
                <ModalContent>
                  <ModalHeader>Invite New User</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Alert
                      bg={appColors.hightLightColor}
                      borderRadius={5}
                      mb={5}
                      borderLeftWidth={4}
                      borderLeftColor={appColors.brand.main.default}
                    >
                      <Stack>
                        <Text>
                          Complete the form to invite someone to the Provider
                          Portal for your organization. Once you submit this
                          form, we will email the user with instructions for
                          completing account setup and logging into the portal.
                        </Text>
                        <Spacer />
                        <Text as="strong">
                          New users must complete account setup within 7 days or
                          the invitation will expire and need to be re-sent.
                        </Text>
                      </Stack>
                    </Alert>

                    <FormControl
                      mb={3}
                      isInvalid={!!errors.email && touched.email}
                    >
                      <FormLabel>Email</FormLabel>
                      <Input
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {errors.email?.includes('required')
                          ? 'Email is required'
                          : errors.email}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mb={3}
                      isInvalid={!!errors.first_name && touched.first_name}
                    >
                      <FormLabel>First Name</FormLabel>
                      <Input
                        id="first_name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        First Name is required
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mb={3}
                      isInvalid={!!errors.last_name && touched.last_name}
                    >
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        id="last_name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>Last Name is required</FormErrorMessage>
                    </FormControl>

                    {alertDisclosure.isOpen && (
                      <Alert
                        status={
                          isSuccess ? 'success' : isError ? 'error' : 'info'
                        }
                      >
                        <AlertIcon />
                        {isSuccess && 'Invitation Sent!'}
                        {isError &&
                          (error
                            ? (error as any)?.data?.error_message
                            : 'Something went wrong, please try again later or contact admin')}
                      </Alert>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      mr={3}
                      onClick={onClose}
                      disabled={isLoading || isFetching}
                      colorScheme="brand.main"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="solid"
                      colorScheme="brand.main"
                      type="submit"
                      isLoading={isLoading || isFetching}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </ModalContent>
              }
            </form>
          </Modal>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default AddRefUserButton;
