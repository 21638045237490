import {
  HStack,
  Box,
} from "@chakra-ui/react";
import { GoCheck } from "react-icons/go";
type Props = {
  children: JSX.Element;
  index: JSX.Element | number;
  label: JSX.Element | string;
  hasNoLine?: boolean|null;
  isComplete?: boolean|null;
};
const RequestQuestionItem = ({ children, index, label, hasNoLine = false, isComplete = false }: Props) => {
  return (
    <>
      <HStack alignItems="center">
        <Box
          justifyContent="center"
          w="40px"
          h="40px"
          borderRadius="50%"
          bg={isComplete? "green.100": "gray.100"}
          borderWidth={1}
          borderColor={isComplete? "green.500": "gray.500"}
          display="flex"
          alignItems="center"
        >
          {isComplete ? <GoCheck/> : index}
        </Box>
        <Box fontWeight="600" display="flex" alignItems="center" h="auto">
          {label}
        </Box>
      </HStack>
      <Box
        marginInlineStart="calc(40px / 2)"
        borderInlineStartWidth={2}
        borderColor={hasNoLine ? "transparent" : "inherit"}
        pl={4}
        my={2}
        h="auto"
      >
        {children}
      </Box>
    </>
  );
};

export default RequestQuestionItem;
