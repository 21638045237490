import {
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Text,
  Box,
} from '@chakra-ui/react';
import { FC } from 'react';
import { ClinicProviderDto } from '../../../app/services/provider/types';

export const cmsFields = [
  {
    key: 'provider_first_name',
    label: 'First Name',
    checked: false,
  },
  {
    key: 'provider_middle_name',
    label: 'Middle Name',
    checked: false,
  },
  {
    key: 'provider_last_name',
    label: 'Last Name',
    checked: false,
  },
  {
    key: 'provider_gender',
    label: 'Gender',
    checked: false,
  },
  {
    key: 'provider_state_cd',
    label: 'State',
    checked: false,
  },
  {
    key: 'provider_pecos_id',
    label: 'Pecos ID',
    checked: false,
  },
  {
    key: 'provider_enrollment_id',
    label: 'Enrollment ID',
    checked: false,
  },
  {
    key: 'provider_org_name',
    label: 'Organization',
    checked: false,
  },
  {
    key: 'provider_provider_type_cd',
    label: 'Provider Type',
    checked: false,
  },
  {
    key: 'provider_provider_type_desc',
    label: 'Provider Description',
    checked: false,
  },
];

type IProps = {
  cmsData: ClinicProviderDto;
  onCheckData: (str: string, checkFlag: boolean) => void;
  checkboxData: Array<{ key: string; label: string; checked: boolean }>;
};

const CmsList: FC<IProps> = ({ cmsData, checkboxData, onCheckData }) => {
  const formatText = (text: string | undefined) => {
    if (text) {
      return text;
    }

    return '-';
  };
  // const onCheckUncheck = (e: ChangeEvent<HTMLInputElement>) => {
  //   const label = (e.target.parentNode as any)?.innerText;
  //   onCheckData(label, e.target.checked);
  // };
  return (
    <>
      <TableContainer>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_first_name", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_first_name")?.checked}
                > */}
                <Text fontSize="xs">First Name</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_first_name)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_pecos_id", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_pecos_id")?.checked}
                > */}
                <Text fontSize="xs">PECOS ID</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_pecos_id)}</Td>
            </Tr>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_middle_name", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_middle_name")?.checked}
                > */}
                <Text fontSize="xs">Middle Name</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_middle_name)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_enrollment_id", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_enrollment_id")?.checked}
                > */}
                <Text fontSize="xs">Enrollment ID</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_enrollment_id)}</Td>
            </Tr>

            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_last_name", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_last_name")?.checked}
                > */}
                <Text fontSize="xs">Last Name</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_last_name)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_org_name", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_org_name")?.checked}
                > */}
                <Text fontSize="xs">Organization</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_org_name)}</Td>
            </Tr>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_gender", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_gender")?.checked}
                > */}
                <Text fontSize="xs">Gender</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_gender)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_provider_type_cd", e.target.checked)}
                  isChecked={
                    checkboxData.find((f) => f.key === "provider_provider_type_cd")?.checked
                  }
                > */}
                <Text fontSize="xs">Provider Type</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_provider_type_cd)}</Td>
            </Tr>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_state_cd", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "provider_state_cd")?.checked}
                > */}
                <Text fontSize="xs">State</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(cmsData.provider_state_cd)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("provider_provider_type_desc", e.target.checked)}
                  isChecked={
                    checkboxData.find((f) => f.key === "provider_provider_type_desc")?.checked
                  }
                > */}
                <Text fontSize="xs">Provider Description</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>
                <Box>{formatText(cmsData.provider_provider_type_desc)}</Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CmsList;
