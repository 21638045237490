import { useUserRoleContext } from '@/app/context/UserRoleContext';
import { useGetOrganizationListQuery } from '@/app/services/provider/api/organization';
import {
  useDeleteOrganizationFolderMutation,
  useLazyGetContentsByOrganizationQuery,
  useLazyGetRootFoldersByOrganizationQuery,
} from '@/app/services/provider/api/organizationFolder';
import brandColors from '@/app/theme/brandColors';
import { AddIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertProps,
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import b64toBlob from 'b64-to-blob';
import { Select } from 'chakra-react-select';
import mime from 'mime';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { BsFolderFill } from 'react-icons/bs';
import { FaFileDownload, FaTrashAlt } from 'react-icons/fa';
import { Row } from 'react-table';
import { useFilePicker } from 'use-file-picker';
import {
  OrganizationContext,
  useChooseOrgContext,
} from '../../app/context/ChooseOrgContext';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { fileTypeValidator } from '../../app/helpers/fileHelper';
import { useDebounce } from '../../app/hooks/useDebounce';
import {
  useDeleteOrganizationDocumentMutation,
  useLazyGetOrganizationDocumentQuery,
  usePostOrganizationDocumentUploadMutation,
} from '../../app/services/provider/api/organizationDocument';
import {
  GetUserOrganizationDto,
  OrganizationDocument,
  OrganizationDocumentModel,
  OrganizationDto,
  OrganizationFolderModel,
} from '../../app/services/provider/types';
import CustomTable from '../../components/CustomTable';
import FileShareAddFolderModal from './FileShareAddFolderModal';
import FileShareBreadCrumb from './FileShareBreadCrumb';
import FileShareConfirmModal from './FileShareConfirmModal';
import FileShareDeleteFileModal from './FileShareDeleteFileModal';
import FileShareDeleteFolderModal from './FileShareDeleteFolderModal';

type Props = object;
type OrgRow = Row<
  | OrganizationFolderModel
  | OrganizationDocumentModel
  | OrganizationDocument
  | any
>;

export const acceptedFiles = (
  process.env.REACT_APP_FILE_SHARE_FILETYPES || ''
).split(',');
export const maxFileCount = parseInt(
  process.env.REACT_APP_FILE_SHARE_MAX_FILE_COUNT || '0'
);
export const maxFileSize = parseInt(
  process.env.REACT_APP_FILE_SHARE_MAX_FILE_SIZE || '0'
);

const FileShare: React.FC<Props> = () => {
  const { userOrganizations, selectedOrg } =
    useChooseOrgContext() as OrganizationContext;
  const { userRole, isUserLoreAdmin } = useUserRoleContext();
  const toast = useToast();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [alert, setAlert] = useState<{
    message: string;
    status: AlertProps['status'];
  } | null>(null);
  const [org, setOrg] = useState<{
    value: GetUserOrganizationDto | OrganizationDto;
    label: string;
  } | null>(null);
  const [search, setSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');
  const [deleteFile, setDeleteFile] = useState<OrganizationDocument | null>(
    null
  );
  const [deleteFolder, setDeleteFolder] =
    useState<OrganizationFolderModel | null>(null);
  const [confirmMessage, setConfirmMessage] = useState<string | null>(null);
  const [folderId, setFolderId] = useState<number | null>(null);
  const [folders, setFolders] = useState<OrganizationFolderModel[]>([]);

  const debouncedSearch = useDebounce(search, 400);

  const [getAsync, getDetail] = useLazyGetOrganizationDocumentQuery();
  const [postAsync, postDetail] = usePostOrganizationDocumentUploadMutation();
  const [deleteFileAsync, deleteFileDetail] =
    useDeleteOrganizationDocumentMutation();
  const [deleteFolderAsync, deleteFolderDetail] =
    useDeleteOrganizationFolderMutation();
  const [rootAsync, rootDetail] = useLazyGetRootFoldersByOrganizationQuery();
  const [contentAsync, contentDetail] = useLazyGetContentsByOrganizationQuery();
  const orgListDetail = useGetOrganizationListQuery(
    { filter: { page_number: 1, page_size: 99999, search: '' } },
    { skip: !isUserLoreAdmin }
  );

  const [openFileSelector, filePicker] = useFilePicker({
    accept: acceptedFiles.map(m => '.' + m),
    limitFilesConfig: { max: maxFileCount },
    maxFileSize: maxFileSize,
    readFilesContent: false,
    validators: [fileTypeValidator({ acceptedFiles, maxFileSize })],
  });

  const fetch = ({
    folderId,
    orgId,
  }: {
    folderId?: number | null;
    orgId?: number;
  }) => {
    if (!folderId && !orgId) return;
    if (folderId) {
      contentAsync(folderId)
        .unwrap()
        .then(contents => {
          if (
            !(
              folders.length > 1 &&
              folders[folders.length - 1].organization_folder_id === folderId
            )
          ) {
            setFolders(f => [...f, contents.main_folder]);
          }
        })
        .catch(e => {
          setFolderId(null);
          setFolders([]);
          if (e.status === 403) {
            toast({
              description: 'You do not have permission to view this folder.',
              status: 'warning',
            });
          }
        });
      return;
    }

    if (orgId) {
      rootAsync(orgId);
    }
  };

  const createFile = async () => {
    if (org?.value.organization_id) {
      setAlert(null);
      const file = filePicker.plainFiles[0];
      filePicker.clear();
      await postAsync({
        organization_id: org.value.organization_id,
        document: file,
        organization_folder_id: folderId,
      });
      fetch({ folderId });
    }
  };

  const handleDownload = (filename: string) => async () => {
    if (org?.value.organization_id) {
      const response = await getAsync({
        organization_id: org.value.organization_id,
        filename,
      });
      const contentType = mime.getType(filename);
      if (response.data && contentType) {
        const blob = b64toBlob(response.data, contentType);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = filename;
        link.download = fileName;
        link.click();
      }
    }
  };

  const handleDeleteFile = async () => {
    if (deleteFile) {
      setDeleteFile(null);
      await deleteFileAsync(deleteFile.organization_document_id);
      toast({
        description: `Successfully deleted file ${deleteFile.document_filename}`,
      });
      fetch({ folderId });
    }
  };

  const handleDeleteFolder = async () => {
    if (deleteFolder) {
      setDeleteFolder(null);
      const result = await deleteFolderAsync(
        deleteFolder.organization_folder_id
      ).unwrap();

      if (result.message.includes('Folder cannot be deleted')) {
        toast({
          description: 'Folder cannot be deleted because it has contents.',
          status: 'warning',
        });
      } else {
        toast({
          description: `Successfully deleted folder ${deleteFolder.organization_folder_name}`,
        });
      }

      fetch({ folderId });
    }
  };

  const isFolder = (
    folderOrFile: OrganizationFolderModel | OrganizationDocumentModel | any // TODO remove
  ) => {
    return !!folderOrFile.organization_id;
  };

  const isAllowedToViewFolder = (allowedRoleIds: number[]) => {
    return userRole.some(r => allowedRoleIds.some(id => id === r.ref_role_id));
  };

  const isLoading =
    postDetail.isLoading ||
    deleteFileDetail.isLoading ||
    deleteFolderDetail.isLoading ||
    rootDetail.isLoading ||
    rootDetail.isFetching ||
    contentDetail.isLoading ||
    contentDetail.isFetching;

  const TableHeaderUsers = [
    {
      Header: 'Action',
      style: {
        display: 'flex',
        justifyContent: 'center',
      },
      Cell: ({ row: { original } }: { row: OrgRow }) => {
        return isFolder(original) ? (
          <ButtonGroup spacing={2} minH="4">
            {isUserLoreAdmin && !!folderId && (
              <Tooltip
                hasArrow
                label="Delete Folder"
                bg="gray.100"
                color="gray.600"
                placement="top"
              >
                <IconButton
                  minWidth={1}
                  variant="link"
                  aria-label="Delete Folder"
                  icon={<FaTrashAlt />}
                  isDisabled={isLoading}
                  isLoading={
                    isLoading &&
                    original.organization_folder_id ===
                      deleteFolderDetail.originalArgs
                  }
                  onClick={e => {
                    e.stopPropagation();
                    setDeleteFolder(original);
                  }}
                />
              </Tooltip>
            )}
          </ButtonGroup>
        ) : (
          <ButtonGroup spacing={2}>
            <Tooltip
              hasArrow
              label="Download"
              bg="gray.100"
              color="gray.600"
              placement="top"
            >
              <IconButton
                minWidth={1}
                variant="link"
                aria-label="Download"
                icon={<FaFileDownload />}
                isDisabled={
                  ((getDetail.isLoading || getDetail.isFetching) &&
                    original.document_filename ===
                      getDetail.originalArgs?.filename) ||
                  (isLoading &&
                    original.organization_document_id ===
                      deleteFileDetail.originalArgs)
                }
                isLoading={
                  (getDetail.isLoading || getDetail.isFetching) &&
                  original.document_filename ===
                    getDetail.originalArgs?.filename
                }
                onClick={handleDownload(original.document_filename)}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label="Delete"
              bg="gray.100"
              color="gray.600"
              placement="top"
            >
              <IconButton
                minWidth={1}
                variant="link"
                aria-label="Delete"
                icon={<FaTrashAlt />}
                isDisabled={isLoading}
                isLoading={
                  isLoading &&
                  original.organization_document_id ===
                    deleteFileDetail.originalArgs
                }
                onClick={() => setDeleteFile(original)}
              />
            </Tooltip>
          </ButtonGroup>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'document_filename',
      Cell: ({ row: { original } }: { row: OrgRow }) => {
        return isFolder(original) ? (
          <HStack>
            <Icon
              as={BsFolderFill}
              aria-label={original.organization_folder_name}
              color={
                isAllowedToViewFolder(original.allowed_roles)
                  ? brandColors.primary.teal['300']
                  : 'gray.400'
              }
            />
            <Text
              maxW="96"
              title={original.organization_folder_name}
              isTruncated
            >
              {original.organization_folder_name}
            </Text>
          </HStack>
        ) : (
          <HStack>
            <Icon as={AiOutlineFile} aria-label={original.document_filename} />
            <Text maxW="96" title={original.document_filename} isTruncated>
              {original.document_filename}
            </Text>
          </HStack>
        );
      },
    },
    {
      Header: 'Created By',
      accessor: 'row_created_by_user_name',
    },
    {
      Header: 'Created Date',
      Cell: ({ row: { original } }: { row: OrgRow }) => {
        return (
          <>{convertUtcToLocal(original.row_created_datetime_utc) || '-'}</>
        );
      },
    },
    {
      Header: 'Last Modified Date',
      Cell: ({ row: { original } }: { row: OrgRow }) => {
        return (
          <>{convertUtcToLocal(original.row_modified_datetime_utc) || '-'}</>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedOrg) {
      setOrg({ value: selectedOrg, label: selectedOrg.organization_name });
      fetch({ orgId: selectedOrg.organization_id });
    }
  }, [selectedOrg]);

  useEffect(() => {
    setFolderId(null);
    setFolders([]);
  }, [org]);

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  const data = useMemo(() => {
    if (
      rootDetail.isLoading ||
      rootDetail.isFetching ||
      contentDetail.isLoading ||
      contentDetail.isFetching
    )
      return undefined;
    return !folderId
      ? rootDetail.data
      : contentDetail.data
      ? [...contentDetail.data.folders, ...contentDetail.data.documents]
      : undefined;
  }, [contentDetail, rootDetail]);

  useEffect(() => {
    const run = () => {
      if (
        org &&
        filePicker.errors.length < 1 &&
        filePicker.plainFiles.length > 0
      ) {
        let duplicateFile: string | null = null;
        filePicker.plainFiles.forEach(f => {
          if (
            contentDetail.data?.documents.some(
              s => s.document_filename === f.name
            )
          ) {
            duplicateFile = f.name;
          }
        });

        if (duplicateFile)
          setConfirmMessage(
            `You are about to upload a duplicate file: ${duplicateFile}`
          );
        else createFile();
      }
      if (filePicker.errors.length > 0) {
        setAlert({
          message:
            (filePicker.errors[0] as any).fileTypeError ??
            'Something went wrong.',
          status: 'warning',
        });
        filePicker.clear();
      }
    };
    run();
  }, [filePicker]);

  if (!userOrganizations || (isUserLoreAdmin && !orgListDetail.data)) {
    return <>Loading...</>;
  }

  return (
    <VStack alignItems="start" w="full">
      <HStack mt=".8rem" w="full">
        <Heading as="h5" size="md">
          Organization
        </Heading>
        <Box w="400px">
          <Select
            isDisabled={isLoading}
            onChange={e => {
              setOrg(e ?? null);
              e && fetch({ orgId: e.value.organization_id });
            }}
            value={org}
            placeholder="Select Organization"
            options={(isUserLoreAdmin && orgListDetail.data?.data
              ? orgListDetail.data.data
              : userOrganizations
            ).map(m => ({
              value: m,
              label: m.organization_name,
              isDisabled: m.organization_id === org?.value.organization_id,
            }))}
          />
        </Box>
        {!!folderId && (
          <HStack>
            <FileShareAddFolderModal
              organizationId={org?.value.organization_id}
              parentFolderId={folderId}
              onCreated={() => {
                fetch({ folderId, orgId: org?.value.organization_id });
              }}
              content={contentDetail.data}
            />
            <Button
              colorScheme="brand.main"
              leftIcon={<AddIcon />}
              isDisabled={postDetail.isLoading}
              isLoading={postDetail.isLoading}
              onClick={openFileSelector}
            >
              Add Document
            </Button>
          </HStack>
        )}
      </HStack>

      <Text as="small" w="full">{`Allowed File Types: ${acceptedFiles.join(
        ', '
      )}`}</Text>
      <Text
        as="small"
        w="full"
      >{`Maximum upload file size: ${maxFileSize}mb`}</Text>

      {alert && (
        <Alert status={alert.status} h="8">
          <AlertIcon />
          {alert.message}
        </Alert>
      )}

      <Box pt="8" w="100%">
        <FileShareBreadCrumb
          folderId={folderId}
          folders={folders}
          orgId={org?.value.organization_id}
          isLoading={
            rootDetail.isLoading ||
            contentDetail.isLoading ||
            rootDetail.isFetching ||
            contentDetail.isFetching
          }
          setFolderId={setFolderId}
          setFolders={setFolders}
          fetch={fetch}
        />

        <CustomTable
          hideRowsPerPage
          hideDisplayRecords
          hidePagination
          isLoading={
            rootDetail.isLoading ||
            contentDetail.isLoading ||
            rootDetail.isFetching ||
            contentDetail.isFetching
          }
          isFetching={
            rootDetail.isFetching ||
            contentDetail.isFetching ||
            rootDetail.isLoading ||
            contentDetail.isLoading
          }
          data={data || []}
          pageCount={10}
          pageSize={pageSize}
          pageIndex={pageIndex}
          totalRecords={100}
          headers={TableHeaderUsers}
          rowDisabledOnPropertyTrue="disabled_flag"
          onPageChange={index => {
            // if ((listDetail.data?.total_pages || 1) > index) setPageIndex(index);
            // fetch({ folderId, orgId: org?.value.organization_id });
          }}
          onPageSizeChange={size => {
            // setPageIndex(0);
            // setPageSize(size);
            // fetch({ folderId, orgId: org?.value.organization_id });
          }}
          onPageSearch={search => {
            // setPageIndex(0);
            // setSearch(search);
            // fetch({ folderId, orgId: org?.value.organization_id });
          }}
          onSort={() => ({})}
          manual={true}
          onRowClick={e => {
            if (isFolder(e)) {
              if (isAllowedToViewFolder(e.allowed_roles)) {
                setFolderId(e.organization_folder_id);
                fetch({
                  folderId: e.organization_folder_id,
                  orgId: org?.value.organization_id,
                });
              } else {
                toast({
                  description:
                    'You do not have permission to view this folder.',
                  status: 'warning',
                });
              }
            }
          }}
        />
      </Box>

      <FileShareDeleteFileModal
        file={deleteFile}
        handleClose={() => setDeleteFile(null)}
        handleClick={handleDeleteFile}
      />

      <FileShareDeleteFolderModal
        folder={deleteFolder}
        handleClose={() => setDeleteFolder(null)}
        handleClick={handleDeleteFolder}
      />
      <FileShareConfirmModal
        message={confirmMessage}
        handleClose={() => {
          setConfirmMessage(null);
          filePicker.clear();
        }}
        handleClick={() => {
          createFile();
          setConfirmMessage(null);
        }}
      />
    </VStack>
  );
};

export default FileShare;
