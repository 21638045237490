import {
  Tooltip,
  IconButton,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Heading,
  Textarea,
  Alert,
  AlertIcon,
  Badge,
  Icon,
  Wrap,
  WrapItem,
  Text,
  HStack,
} from '@chakra-ui/react';
import { FaQuestionCircle } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFilePicker } from 'use-file-picker';
import { useEffect, useState } from 'react';
import { usePostFeedbackMutation } from '../../app/services/provider/api/feedback';
import { CgAttachment } from 'react-icons/cg';
import {
  createFormData,
  fileTypeValidator,
} from '../../app/helpers/fileHelper';

export const acceptedFiles = (
  process.env.REACT_APP_FEEDBACK_FILETYPES || ''
).split(',');
export const maxFileCount = parseInt(
  process.env.REACT_APP_FEEDBACK_MAX_FILE_COUNT || '0'
);
export const maxFileSize = parseInt(
  process.env.REACT_APP_FEEDBACK_MAX_FILE_SIZE || '0'
);

const FormSchema = Yup.object().shape({
  feedback_subject: Yup.string()
    .label('Subject')
    .required()
    .max(1000, 'Text exceed the character limit of 1000'),
  feedback_message: Yup.string()
    .label('Message')
    .required()
    .max(3000, 'Text exceed the character limit of 3000'),
  attachments: Yup.array<File>().optional(),
});

const Feedback = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postAsync, postDetail] = usePostFeedbackMutation();

  const [alertMessage, setAlertMessage] = useState('');

  const [openFileSelector, filePicker] = useFilePicker({
    multiple: true,
    accept: acceptedFiles.map(m => '.' + m),
    limitFilesConfig: { max: maxFileCount },
    maxFileSize: maxFileSize,
    readFilesContent: false,
    validators: [fileTypeValidator({ acceptedFiles, maxFileSize })],
  });

  const { handleSubmit, errors, touched, handleChange, values, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema: FormSchema,
      initialValues: {
        feedback_subject: '',
        feedback_message: '',
        attachments: [] as File[],
      },
      onSubmit: values => {
        if (filePicker.errors.length === 0) {
          values.attachments = filePicker.plainFiles;
          const data = createFormData(values);
          postAsync(data);
        }
      },
    });

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = postDetail;

    if (isSuccess) {
      setAlertMessage('Feedback successfully submitted.');
      setTimeout(() => {
        clearModal();
      }, 3000);
    } else if (isError) {
      setAlertMessage(
        'There was an error processing your request, please try again later.'
      );
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [postDetail]);

  const clearModal = () => {
    filePicker.clear();
    resetForm();
    setAlertMessage('');
  };

  return (
    <>
      <Tooltip label="Send Feedback">
        <IconButton
          variant="link"
          aria-label="Help"
          icon={<FaQuestionCircle />}
          color="white"
          onClick={onOpen}
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Feedback</ModalHeader>
          <ModalCloseButton disabled={postDetail.isLoading} />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={5}>
                <Heading size="sm" w="full">
                  Have feedback? We'd love to hear it, but please don't share
                  protected data.
                </Heading>
                <FormControl
                  isInvalid={
                    !!errors.feedback_subject && touched.feedback_subject
                  }
                >
                  <FormLabel htmlFor="feedback_subject">Subject</FormLabel>
                  <Input
                    id="feedback_subject"
                    placeholder="Enter Subject"
                    name="feedback_subject"
                    onChange={handleChange}
                    value={values.feedback_subject}
                    maxLength={1000}
                  />
                  <FormErrorMessage>{errors.feedback_subject}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={
                    !!errors.feedback_message && touched.feedback_message
                  }
                >
                  <HStack justifyContent="space-between">
                    <FormLabel htmlFor="feedback_message">Message</FormLabel>
                    {values.feedback_message.length > 0 && (
                      <Text as="small">{`${values.feedback_message.length}/3000`}</Text>
                    )}
                  </HStack>

                  <Textarea
                    id="feedback_message"
                    placeholder="Enter Message"
                    name="feedback_message"
                    onChange={handleChange}
                    value={values.feedback_message}
                    maxLength={3000}
                  />
                  <FormErrorMessage>{errors.feedback_message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={filePicker.errors.length > 0}>
                  {filePicker.plainFiles.length ? (
                    <Wrap
                      align="center"
                      p={2}
                      border="1px"
                      borderColor="chakra-border-color"
                      borderRadius={5}
                    >
                      <WrapItem>
                        <Icon as={CgAttachment} />
                      </WrapItem>
                      {filePicker.plainFiles.map((m, i) => (
                        <WrapItem key={i}>
                          <Badge variant="outline" textTransform="none">
                            {m.name}
                          </Badge>
                        </WrapItem>
                      ))}
                      <WrapItem>
                        <Button onClick={filePicker.clear} size="xs">
                          Clear files
                        </Button>
                      </WrapItem>
                    </Wrap>
                  ) : (
                    <>
                      <VStack
                        p={2}
                        border="1px"
                        borderColor="chakra-border-color"
                        borderRadius={5}
                      >
                        <Button h="40px" w="full" onClick={openFileSelector}>
                          <VStack>
                            <Heading size="sm">Upload Attachments</Heading>
                          </VStack>
                        </Button>
                        <Text
                          as="small"
                          w="full"
                        >{`Allowed File Types: ${acceptedFiles.join(
                          ', '
                        )}`}</Text>
                        <Text
                          as="small"
                          w="full"
                        >{`Maximum upload file size: ${maxFileSize}mb`}</Text>
                      </VStack>
                    </>
                  )}
                  <FormErrorMessage>
                    {((filePicker.errors[0] || {}) as any).fileTypeError}
                  </FormErrorMessage>
                </FormControl>
                {alertMessage && (
                  <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                    <AlertIcon />
                    {alertMessage}
                  </Alert>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onClose}
                mr={3}
                ml="auto"
                disabled={postDetail.isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={postDetail.isLoading}
                disabled={alertMessage !== '' && postDetail.isSuccess}
              >
                Send
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Feedback;
