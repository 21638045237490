import { CircularProgress } from '@chakra-ui/progress';
import React from 'react';
import { appColors } from '../../app/theme';

type Props = object;

const PageLoading = (props: Props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress isIndeterminate color={appColors.brand.main.default} />
    </div>
  );
};

export default PageLoading;
