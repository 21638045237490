import { useAccount } from '@azure/msal-react';
import React, { FC, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChooseOrgProvider } from '../app/context/ChooseOrgContext';
import { useAppSelector } from '../app/state/hooks';
import { AppAccessAuth } from '../app/types/appAccessAuth';
import AdAuthenticatedView from '../features/AdAuthenticatedView';
import ChooseOrganizationView from '../features/ChooseOrganizationView/ChooseOrganizationView';
import { ConsentedViewNewUser } from '../features/ConsentedView';
import { RouteAuth } from '../features/RouteAuth';
import Claims from './Claims';
import ClinicProvider from './ClinicProvider';
import PatientDetails from './ClinicProvider/PatientDetails';
import FAQ from './FAQ';
import FileShare from './FileShare';
import Fulfillment from './Fulfillment';
import FulfillmentCgm from './Fulfillment/CGM';
import OrdersToSign from './OrdersToSign';
import { OrdersToSignCgmNoninsulin } from './OrdersToSign/CGM';
import OrdersToSignCgmDeclined from './OrdersToSign/CGM/Declined';
import Organization from './Organization';
import { Profile } from './Profile';
import ChangePassword from './Profile/ChangePassword';
import ReportHub from './ReportHub';
import AcoPaymentReport from './ReportHub/AcoPaymentReport';
import { GdsReports } from './Reports';
import Roles from './Roles';
import AccessApproval from './Roles/AccessApproval';
import AddRole from './Roles/AddRole';
import ExploreRoles from './Roles/ExploreRoles';
import { User } from './User';

type Props = object;

const Routing = (props: Props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/clinic-provider" />}></Route>
        <Route path="/clinic-provider">
          <Route
            index
            element={<ProtectedRoute Component={ClinicProvider} />}
          />
          <Route
            path="/clinic-provider/patient-details"
            element={
              <RouteAuth
                requiredAppAccess={AppAccessAuth.ProviderPatientDetailsAll}
              >
                <ProtectedRoute Component={PatientDetails} />
              </RouteAuth>
            }
          />
        </Route>
        <Route
          path="/organization"
          element={
            <RouteAuth
              requiredAppAccess={AppAccessAuth.organizationReadAndWriteAll}
            >
              <Organization />
            </RouteAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RouteAuth requiredAppAccess={AppAccessAuth.userReadWriteAll}>
              <User />
            </RouteAuth>
          }
        />
        <Route
          path="/roles"
          element={<ProtectedRoute Component={Roles} dontChooseOrg />}
        >
          <Route index element={<Navigate to="explore-roles" />} />
          <Route
            path="explore-roles"
            element={<ProtectedRoute Component={ExploreRoles} dontChooseOrg />}
          />
          <Route
            path="add-role"
            element={
              <RouteAuth requiredAppAccess={AppAccessAuth.addRolesAll}>
                <AddRole />
              </RouteAuth>
            }
          />
          <Route
            path="access-approval"
            element={
              <RouteAuth requiredAppAccess={AppAccessAuth.AccessApprovalAll}>
                <AccessApproval />
              </RouteAuth>
            }
          />
        </Route>
        <Route
          path="/report-hub"
          element={
            <RouteAuth requiredAppAccess={AppAccessAuth.reportHubReadWriteAll}>
              <ProtectedRoute Component={ReportHub} />
            </RouteAuth>
          }
        >
          <Route index element={<Navigate to="aco-payment-report" />} />
          <Route
            path="aco-payment-report"
            element={<ProtectedRoute Component={AcoPaymentReport} />}
          ></Route>
        </Route>
        <Route
          path="/orders-to-sign"
          element={
            <RouteAuth requiredAppAccess={AppAccessAuth.ordersToSignAll}>
              <ProtectedRoute Component={OrdersToSign} />
            </RouteAuth>
          }
        >
          <Route index element={<Navigate to="cgm-noninsulin-users" />} />
          {/* <Route
            path="cgm-insulin-users"
            element={<ProtectedRoute Component={OrdersToSignCgmInsulin} />}
          /> */}
          <Route
            path="cgm-noninsulin-users"
            element={<ProtectedRoute Component={OrdersToSignCgmNoninsulin} />}
          />
          <Route
            path="cgm-declined-users"
            element={<ProtectedRoute Component={OrdersToSignCgmDeclined} />}
          />
        </Route>
        <Route
          path="/fulfillment"
          element={
            <RouteAuth requiredAppAccess={AppAccessAuth.fulfillmentAll}>
              <ProtectedRoute Component={Fulfillment} />
            </RouteAuth>
          }
        >
          <Route index element={<Navigate to="cgm" />} />
          <Route
            path="cgm"
            element={<ProtectedRoute Component={FulfillmentCgm} />}
          ></Route>
        </Route>
        <Route
          path="/file-share"
          element={
            <RouteAuth requiredAppAccess={AppAccessAuth.fileShareAll}>
              <ProtectedRoute Component={FileShare} />
            </RouteAuth>
          }
        />
        <Route path="/claims" element={<Claims />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/gds" element={<GdsReports embed={false} />} />
        <Route path="/gds-embed" element={<GdsReports embed={true} />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="*" element={<strong>Page not found</strong>} />
      </Routes>
    </>
  );
};

export default Routing;

type IProtectedRouteProps = {
  children?: ReactNode;
  Component: React.ComponentType;
  dontChooseOrg?: boolean;
};

const ProtectedRoute: FC<IProtectedRouteProps> = ({
  Component,
  dontChooseOrg,
}) => {
  const { userConsents } = useAppSelector(s => s.user);

  const account = useAccount();
  const { idTokenClaims } = account as any;

  const isNewUser = () => {
    if (idTokenClaims) {
      const { newUser = false } = idTokenClaims;

      return newUser;
    } else return false;
  };

  if (dontChooseOrg) {
    return (
      <AdAuthenticatedView>
        <Component />
      </AdAuthenticatedView>
    );
  }

  if (isNewUser() && !userConsents) {
    return (
      <AdAuthenticatedView>
        <ConsentedViewNewUser>{<Component />}</ConsentedViewNewUser>
      </AdAuthenticatedView>
    );
  } else {
    return (
      <AdAuthenticatedView>
        <ChooseOrgProvider>
          <ChooseOrganizationView Component={Component} />
        </ChooseOrgProvider>
      </AdAuthenticatedView>
    );
  }
};
