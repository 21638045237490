import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { Box, Flex, HStack, Progress, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Header from './components/Header';

import 'focus-visible/dist/focus-visible';
import { useLocation } from 'react-router-dom';
import { UserRoleProvider } from './app/context/UserRoleContext';
import {
  LOCALSTORAGE_KEYS,
  LOCALSTORAGE_VALUES,
  saveState,
} from './app/helpers/localStorageHelper';
import { loginRequest } from './app/services/auth/authConfig';
import { usePostPimGraphFirstSigninMutation } from './app/services/provider/api/pim';
import { setFirstSigninSuccess } from './app/slices/appSlice';
import { setIsAuthorized } from './app/slices/userSlice';
import { useAppDispatch, useAppSelector } from './app/state/hooks';
import Footer from './components/Footer';
import SideNav from './components/SideNav/SideNav';
import AuthenticatedUserWrapper from './features/AuthenticatedUserWrapper/AuthenticatedUserWrapper';
import Heap from './features/Heap';
import UnAuthorizedView from './features/UnAuthorizedView/UnAuthorizedView';
import Routing from './pages/Routing';

const App = () => {
  const { b2cRedirect } = LOCALSTORAGE_VALUES;
  const { isAuthorized } = useAppSelector(s => s.user);
  const { appInitComplete, firstSigninSuccess } = useAppSelector(s => s.app);
  const dispatch = useAppDispatch();
  const { inProgress } = useMsal();
  // @ts-expect-error: please fix if encountered - this
  const instance = globalThis.msalInstance;
  const { sideNavWidth } = useAppSelector(s => s.app);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [ssoObjectId, setSsoObjectId] = useState<string>();

  const [pimGraphTrigger] = usePostPimGraphFirstSigninMutation();
  // /**
  //  * Using the event API, you can register an event callback that will do something when an event is emitted.
  //  * When registering an event callback in a react component you will need to make sure you do 2 things.
  //  * 1) The callback is registered only once
  //  * 2) The callback is unregistered before the component unmounts.
  //  * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
  //  */

  useEffect(() => {
    instance.addEventCallback((event: EventMessage) => {
      //console.log("addEventCallback", event);

      // change password state
      if (b2cRedirect && b2cRedirect.length > 0) {
        saveState('', LOCALSTORAGE_KEYS.b2cRedirect);
        window.location.href = b2cRedirect;
      }
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          instance.setActiveAccount(account);

          if (account) {
            const { idTokenClaims } = account as any;

            const userHasSignedIn =
              (account?.idTokenClaims &&
                idTokenClaims.extension_Userhassignedin) ||
              false;

            if (!isLoaded && userHasSignedIn === false && !firstSigninSuccess) {
              setSsoObjectId(account.localAccountId);
              setIsLoaded(true);
            }
          }

          if (payload.state) window.location.href = payload.state;
        }
      }
    });
  }, []);

  useEffect(() => {
    const firstSignin = async () => {
      await pimGraphTrigger(ssoObjectId || '').then((resp: any) => {
        if (resp.data && resp.data.is_success) {
          dispatch(setFirstSigninSuccess(true));
        }
      });
    };

    if (isLoaded) firstSignin();
  }, [isLoaded]);

  return (
    <>
      <UnauthenticatedTemplate>
        <UnAuthRedirect />
      </UnauthenticatedTemplate>
      <Header />
      <Heap />
      <UnAuthorizedView
        show={!isAuthorized}
        onClick={() => {
          dispatch(setIsAuthorized(true));
          instance.logoutRedirect({
            postLogoutRedirectUri: '/',
            account: instance.getActiveAccount(),
          });

          sessionStorage.clear();
          localStorage.clear();
        }}
      />

      <Flex direction="column" align="stretch" alignItems="center" minH="100vh">
        {inProgress === InteractionStatus.HandleRedirect && (
          <Progress size="xs" isIndeterminate colorScheme="brand" />
        )}
        <UserRoleProvider>
          <>
            <Box flex="1 1 0%" overflow="auto" mt="60px" p="0" w="100%">
              <HStack alignItems="stretch" spacing={0}>
                <Box flexGrow={1}>
                  <SideNav />
                </Box>
                <VStack
                  spacing={0}
                  w="100%"
                  alignItems="stretch"
                  flexDir="column"
                >
                  <Flex
                    p={7}
                    bgColor="white"
                    padding="2rem 2.25rem 2.25rem"
                    style={{ width: `calc(100vw - ${sideNavWidth}px)` }}
                    h="100%"
                  >
                    <AuthenticatedUserWrapper>
                      <Routing />
                    </AuthenticatedUserWrapper>
                  </Flex>
                </VStack>
              </HStack>
            </Box>

            <Footer />
          </>
        </UserRoleProvider>
      </Flex>
    </>
  );
};

const UnAuthRedirect = () => {
  const { inProgress } = useMsal();
  // @ts-expect-error: please fix if encountered - this msal
  const instance = globalThis.msalInstance;
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance
        .loginRedirect({ ...loginRequest, state: location.pathname })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Loading...
    </div>
  );
};

export default App;
