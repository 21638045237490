import { providerApi } from './index';
import { PutUserDemographicDto, GetUserDemographicDto } from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getUserDemographicList: builder.query<GetUserDemographicDto[], number>({
      query: refUserId => `/userdemographic/user/${refUserId}`,
      providesTags: ['User'],
    }),
    getUserDemographicById: builder.query<GetUserDemographicDto, number>({
      query: demographicId => `/user/${demographicId}`,
      providesTags: ['User'],
    }),
    putUserDemographic: builder.mutation<void, PutUserDemographicDto>({
      query: params => ({
        url: `/userdemographic/${params.ref_user_id}/demographic`,
        method: 'PUT',
        body: params.demographic,
      }),
      invalidatesTags: ['User'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserDemographicListQuery,
  usePutUserDemographicMutation,
  useLazyGetUserDemographicListQuery,
  useLazyGetUserDemographicByIdQuery,
} = extendedApi;
