import { useIsUserHasRequiredAppAccessFunc } from '@/app/hooks/useIsUserHasRequiredRoles';
import { Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { FC, Fragment, useEffect, useRef } from 'react';
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useUserRoleContext } from '../../app/context/UserRoleContext';
import { setSideNavWidth } from '../../app/slices/appSlice';
import PageLoading from '../PageLoading/PageLoading';
import NavItem from './NavItem';
import { navItems } from './navItems';

type Props = object;

const SideNav: FC<Props> = () => {
  const dispatch = useDispatch();
  const node = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { userAppAccess } = useUserRoleContext();

  const hasRequiredRoles = useIsUserHasRequiredAppAccessFunc();

  useEffect(() => {
    if (isOpen) {
      dispatch(setSideNavWidth(252));
    } else {
      dispatch(setSideNavWidth(50));
    }
  });

  return (
    <>
      <Flex
        ref={node}
        direction="column"
        bgColor="blackAlpha.200"
        minH="100ch"
        px={1}
        py={1}
        overflowX="hidden"
        w={isOpen ? '252px' : '50px'}
        id="SideNav"
      >
        {isOpen ? (
          <IconButton
            maxW="30px"
            ml="auto"
            aria-label="Hide"
            onClick={onClose}
            icon={<HiOutlineChevronDoubleLeft />}
          />
        ) : (
          <IconButton
            maxW="30px"
            ml="auto"
            aria-label="Show"
            onClick={onOpen}
            icon={<HiOutlineChevronDoubleRight />}
          />
        )}
        {!userAppAccess ? (
          <PageLoading />
        ) : (
          <>
            {/* <Divider mt={1} /> */}
            {navItems.map((m, i) => (
              <Fragment key={i}>
                <NavItem
                  item={m}
                  requiredRoles={m.requiredAppAccess}
                  userHasRequiredRole={hasRequiredRoles(m.requiredAppAccess)}
                />
              </Fragment>
            ))}
          </>
        )}
      </Flex>
    </>
  );
};

export default SideNav;
