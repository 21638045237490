import { providerApi } from './index';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getAvatar: builder.query<string, string>({
      query: email => ({
        url: `/avatar/${email}`,
      }),
      providesTags: ['Avatar'],
    }),
    postAvatar: builder.mutation<void, FormData>({
      query: params => ({
        url: '/avatar/upload',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Avatar'],
    }),

    //     deleteOrganization: builder.mutation<void, number>({
    //       query: (id) => ({
    //         url: `/organization/${id}`,
    //         method: "DELETE",
    //       }),
    //       invalidatesTags: ["Organization"],
    //     }),
  }),

  overrideExisting: false,
});

export const { useGetAvatarQuery, usePostAvatarMutation } = extendedApi;
