import { useAccount } from '@azure/msal-react';
import { HStack, Skeleton, Stack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { getIdTokenClaims } from '../../app/services/auth/helper';

export const GdsReports: FC<{ embed: boolean }> = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const account = useAccount();

  const uri = `https://datastudio.google.com/embed/reporting/915b0130-5c38-440c-91fa-6e971864513b/page/p_ok5koz43yc?params=%7B%22vw_user_org.p_email%22%3A%22${emailAddress}%22%7D`;

  useEffect(() => {
    const { emails } = getIdTokenClaims(account);
    if (emails) {
      setEmailAddress(emails[0]);
    }
  }, []);

  return (
    <HStack w="full">
      {isLoading && (
        <Stack mt={1} w="100%">
          {[...Array(5)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      )}

      <iframe
        title="gds"
        style={{
          overflow: 'hidden',
          overflowX: 'hidden',
          overflowY: 'hidden',
          height: '100%',
          width: '100%',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
        }}
        height="100%"
        width="100%"
        onLoad={() => setIsLoading(false)}
        src={uri}
      />
    </HStack>
  );
};
