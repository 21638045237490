import { useIsUserHasRequiredAppAccessFunc } from '@/app/hooks/useIsUserHasRequiredRoles';
import { Flex, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useUserRoleContext } from '../../app/context/UserRoleContext';
import { UserRoleAppAccessByEmailModel } from '../../app/services/provider/types';
import { AppAuthRequiredAppAccess } from '../../app/types/appAccessAuth';
import { NavItemProps } from './navItems';

type IProps = {
  item: NavItemProps;
  requiredRoles: AppAuthRequiredAppAccess;
  userHasRequiredRole: boolean;
};

const NavItem: FC<IProps> = ({ item, requiredRoles, userHasRequiredRole }) => {
  const location = useLocation();
  const { userAppAccess } = useUserRoleContext();

  const isSelected = (href: string, strict?: boolean) => {
    if (strict) {
      return location.pathname === href;
    }
    return location.pathname === href || location.pathname.indexOf(href) === 0;
  };

  const hasRequiredRoles = useIsUserHasRequiredAppAccessFunc();

  return (
    <>
      {userHasRequiredRole || requiredRoles.length === 0 ? (
        <VStack
          className={`navItem ${isSelected(item.href) ? 'selected' : ''}`}
        >
          <Link
            py={3}
            px={3}
            as={RouterLink}
            to={item.href}
            w="100%"
            borderRadius={4}
          >
            <Flex align="center">
              <Icon as={item.icon} color="brand.main" fontSize={18} />
              <Text ml={4}>{item.label}</Text>
            </Flex>
          </Link>
          <VStack
            align="stretch"
            hidden={!isSelected(item.href)}
            overflowX="hidden"
            w="full"
            className="subNav"
          >
            {(item.subNav || []).map((sm, ii) =>
              hasRequiredRoles(sm.requiredAppAccess) ||
              sm.requiredAppAccess.length === 0 ? (
                <SubNav
                  key={sm.label}
                  sm={sm}
                  ii={ii}
                  isSelected={isSelected}
                />
              ) : (
                <></>
              )
            )}
          </VStack>
        </VStack>
      ) : (
        ''
      )}
    </>
  );
};

type SubNavProps = {
  sm: NavItemProps;
  ii: number;
  isSelected: (href: string, strict?: boolean) => boolean;
};
const SubNav = ({ sm, ii, isSelected }: SubNavProps) => {
  return (
    <Link
      key={ii}
      py={2}
      pl={3}
      as={RouterLink}
      to={sm.href}
      w="100%"
      borderColor="transparent"
      borderLeftWidth="3px"
      className={`${isSelected(sm.href) ? 'selected' : ''}`}
    >
      <HStack px={8} spacing={4} alignItems="center">
        <Icon as={sm.icon} fontSize={16} />
        <Text>{sm.label}</Text>
      </HStack>
    </Link>
  );
};

export default NavItem;
