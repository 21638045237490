import { useSteps } from 'chakra-ui-steps';
import { createContext, ReactNode, useContext, useState } from 'react';

export const StepperContext = createContext<any>('');

export const useStepperContext = () => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error('Context error');
  }

  return context;
};

export const StepperProvider = ({ children }: any) => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const [steps, setSteps] = useState<ReactNode[]>();
  const [completed, setCompleted] = useState<boolean>(false);

  return (
    <StepperContext.Provider
      value={{
        nextStep,
        prevStep,
        activeStep,
        reset,
        steps,
        setSteps,
        setCompleted,
        completed,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
