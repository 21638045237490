import { providerApi } from '.';
import {
  ListUserRoleByStatusDto as ListUserRoleRequestByStatusDto,
  ListUserRoleRequestModel,
  PostUserRoleRequestModel as PostUserRoleRequestDto,
  PostUserRoleRequestStatusDto,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    listUserRoleByStatus: builder.query<
      ListUserRoleRequestModel,
      ListUserRoleRequestByStatusDto
    >({
      query: params => ({
        url: '/userrolerequest/list',
        params,
      }),
      providesTags: ['UserRoleRequest'],
    }),
    postUserRoleRequest: builder.mutation<
      { user_role_request_id: number },
      PostUserRoleRequestDto
    >({
      query: params => ({
        url: `/userrolerequest`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['UserRoleRequest'],
    }),
    postUserRoleRequestStatus: builder.mutation<
      { user_role_request_tracking_id: number },
      PostUserRoleRequestStatusDto
    >({
      query: body => ({
        url: '/userrolerequest/status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserRoleRequest'],
    }),
  }),
});

export const {
  usePostUserRoleRequestMutation,
  useListUserRoleByStatusQuery,
  usePostUserRoleRequestStatusMutation,
} = extendedApi;
