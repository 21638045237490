import { SearchIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useNumberInput,
  VStack,
} from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
  CgChevronLeft,
  CgChevronRight,
} from 'react-icons/cg';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import {
  Column,
  RowPropGetter,
  SortingRule,
  TableState,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { appColors } from '../../app/theme';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import './CustomTable.css';

import { CSVLink } from 'react-csv';

type IProps = {
  title?: ReactNode | string;
  data: any[];
  hidePagination?: boolean;
  hideRowsPerPage?: boolean;
  hideDisplayRecords?: boolean;
  pageCount: number;
  pageSize: number;
  pageIndex?: number;
  totalRecords?: number;
  isLoading: boolean;
  isFetching: boolean;
  headers: Column<any>[];
  hiddenColumns?: string[];
  hasFilter?: boolean;
  hasLoadingIndicator?: boolean;
  filterElement?: JSX.Element;
  rowDisabledOnPropertyTrue?: string;
  manual: boolean;
  manualSortBy?: boolean;
  disableSortRemove?: boolean;
  isPageNumberEditable?: boolean;
  initialState?: Partial<TableState<object>> | undefined;
  exportName?: string;
  onExportClick?: () => Promise<Array<any>>;
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange?: (size: number) => void;
  onPageSearch?: (search: string) => void;
  onSort: (sort: SortingRule<object>[]) => void;
  onRowClick?: (row: any) => void;
  getRowProps?: (row: any) => RowPropGetter<object> | undefined;
  options?: {
    showSkeletonLoading?: boolean;
  };
};

const CustomTable: FC<IProps> = props => {
  const columns = useMemo<Column<any>[]>(() => props.headers, [props.headers]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [editablePage, setEditablePage] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [pageNumber, setPageNumber] = useState(
    props.pageIndex === 0 || props.pageIndex === undefined
      ? 1
      : props.pageIndex + 1
  );
  const [pageSize, setPageSize] = useState(props.pageSize);
  const [exportList, setExportList] = useState<any[] | null>(null);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.providerExportReadWriteAll
  );

  const { getInputProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    max: props.pageCount,
  });
  const input = getInputProps();
  const refCsvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-expect-error: please fix if encountered
    page,
    prepareRow,
    // @ts-expect-error: please fix if encountered
    gotoPage,
    // @ts-expect-error: please fix if encountered
    state: { pageIndex, sortBy },
    // @ts-expect-error: please fix if encountered
    nextPage,
    // @ts-expect-error: please fix if encountered
    previousPage,
    // @ts-expect-error: please fix if encountered
    canNextPage,
    // @ts-expect-error: please fix if encountered
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        // @ts-expect-error: please fix if encountered
        pageIndex: 0,
        pageSize: 18,
        hiddenColumns: props.hiddenColumns || [],
        ...props.initialState,
      },
      pageCount: tablePageCount,
      manualPagination: !!props.manual,
      autoResetPage: false, //prevent re-executing hook with initialState again
      manualSortBy: props.manualSortBy,
      disableSortRemove: props.disableSortRemove,
      autoResetSortBy: false,
      autoResetPagination: true,
      useFlexLayout,
    },
    useSortBy,
    usePagination
  );

  const keyUpHandler = (event: any) => {
    event.preventDefault();
    gotoPage(0);
    if (props.onPageSearch) props.onPageSearch(search);
  };

  const handleRowClick = (row: any) => {
    if (props.onRowClick) {
      props.onRowClick(row.original);
      setSelectedRowId(row.id);
    }
  };

  useEffect(() => {
    setTableData(props.data);
    setTablePageCount(props.pageCount);
  }, [props]);

  useEffect(() => {
    if (exportList) {
      refCsvLink.current?.link.click();
    }
  }, [exportList]);

  useEffect(() => {
    props.onSort(sortBy);
  }, [sortBy]);

  useEffect(() => {
    if (props.manual) {
      if (props.pageIndex !== null && props.pageIndex !== undefined) {
        gotoPage(props.pageIndex);
      }
    }
  }, [props.pageIndex]);

  useEffect(() => {
    setEditablePage(pageIndex + 1);
    setPageNumber(pageIndex + 1);
  }, [pageIndex]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  async function handleExportClick() {
    if (props.onExportClick) {
      try {
        setIsLoadingExport(true);
        const list = await props.onExportClick();
        setExportList(list);
      } catch (error) {
        console.log(error);
      }
      setIsLoadingExport(false);
    }
  }

  return (
    <VStack alignItems="start" w="full">
      {!props.hidePagination && (
        <VStack alignItems="stretch" flexDirection="column" w="full">
          <Flex justifyContent="space-between" mb={2} p={0} alignItems="center">
            {props.title &&
              (typeof props.title === 'string' ? (
                <Heading as="h5" size="md">
                  {props.title}
                </Heading>
              ) : (
                props.title
              ))}
            {!props.hideRowsPerPage && (
              <HStack p="4" w="100%">
                <Flex justifyContent="end">
                  <Text flexShrink={0} mr={4} fontSize="md" lineHeight={2}>
                    Rows per page
                  </Text>
                  <Select
                    size="sm"
                    w="16"
                    value={props.pageSize}
                    mr="3"
                    fontWeight="medium"
                    iconColor={appColors.brand.main.default}
                    onChange={e => {
                      gotoPage(0);
                      setPageSize(Number(e.target.value));
                      props.onPageSizeChange &&
                        props.onPageSizeChange(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        <Text as="span" fontWeight="medium">
                          {pageSize}
                        </Text>
                      </option>
                    ))}
                  </Select>
                  {!props.isLoading &&
                    !props.hideRowsPerPage &&
                    !props.hideDisplayRecords && (
                      <Badge
                        alignItems="center"
                        display="flex"
                        px="2"
                        py="1"
                        bg={appColors.hightLightColor}
                      >
                        {props.totalRecords} record
                        {(props?.totalRecords ?? 0) > 0 && 's'}
                      </Badge>
                    )}
                  {userHasRequiredRole && props.onExportClick && (
                    <>
                      <Button
                        ml="3"
                        boxShadow="sm"
                        onClick={handleExportClick}
                        size="sm"
                        isLoading={isLoadingExport}
                        isDisabled={isLoadingExport}
                      >
                        Export
                      </Button>
                      <CSVLink
                        data={exportList ?? []}
                        className="hidden"
                        filename={
                          props?.exportName ?? `export_csv_${Date.now()}`
                        }
                        ref={refCsvLink}
                        target="_blank"
                      />
                    </>
                  )}
                </Flex>
                {props.hasLoadingIndicator &&
                  (props.isLoading || props.isFetching) && <Spinner />}
              </HStack>
            )}

            {props.onPageSearch && (
              <>
                <Spacer />
                <HStack p="4">
                  <InputGroup justifyContent="flex-end">
                    <Input
                      id="search"
                      placeholder="search"
                      name="search"
                      w="100%"
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={keyUpHandler}
                    />
                    <InputRightElement
                      children={
                        <SearchIcon
                          className="SearchIcon"
                          color={appColors.brand.main.default}
                        />
                      }
                    />
                  </InputGroup>
                </HStack>
              </>
            )}
          </Flex>
        </VStack>
      )}
      {props.hasFilter && <Box px="4">{props.filterElement}</Box>}

      <TableContainer w="full">
        {props.isLoading ||
        (props.options?.showSkeletonLoading && props.isFetching) ? (
          <Stack mt={1}>
            {[...Array(props.pageSize)].map((m, i) => (
              <Skeleton key={i} height="18px" />
            ))}
          </Stack>
        ) : (
          <>
            <Table
              variant="simple"
              size="sm"
              mt={1}
              {...getTableProps()}
              style={{ borderCollapse: 'separate' }}
            >
              <Thead>
                {headerGroups.map(headerGroup => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    <Box
                      position="sticky"
                      top="8px"
                      left={0}
                      zIndex="2"
                      backgroundColor="#fff"
                      display="table-cell"
                    >
                      {headerGroup.headers
                        // @ts-expect-error: wll add prop type later
                        .filter(i => i.isSticky)
                        .map((column, key) => {
                          const columnStyle = {
                            ...(column as any).style,
                            ...((column as any).styleColumn || {}),
                          };
                          const HeaderCell = (column as any).HeaderCell;
                          const isSortable = (column as any).isSortable ?? true;

                          return (
                            <Th
                              {...column.getHeaderProps(
                                // @ts-expect-error: please fix if encountered
                                column.getSortByToggleProps({
                                  ...(isSortable ? {} : { onClick: undefined }),
                                })
                              )}
                              style={columnStyle}
                            >
                              {HeaderCell
                                ? HeaderCell
                                : column.render('Header')}
                              {
                                // @ts-expect-error: please fix if encountered
                                isSortable && column.isSorted && (
                                  <chakra.span pl="3">
                                    {
                                      // @ts-expect-error: please fix if encountered
                                      column.isSortedDesc ? (
                                        <Icon
                                          as={GoTriangleDown}
                                          aria-label="sorted descending"
                                        />
                                      ) : (
                                        <Icon
                                          as={GoTriangleUp}
                                          aria-label="sorted ascending"
                                        />
                                      )
                                    }
                                  </chakra.span>
                                )
                              }
                            </Th>
                          );
                        })}
                    </Box>
                    {headerGroup.headers
                      // @ts-expect-error: wll add prop type later
                      .filter(i => !i.isSticky)
                      .map((column, key) => {
                        const columnStyle = {
                          ...(column as any).style,
                          ...((column as any).styleColumn || {}),
                        };
                        const HeaderCell = (column as any).HeaderCell;
                        const isSortable = (column as any).isSortable ?? true;

                        return (
                          <Th
                            {...column.getHeaderProps(
                              // @ts-expect-error: please fix if encountered
                              column.getSortByToggleProps({
                                ...(isSortable ? {} : { onClick: undefined }),
                              })
                            )}
                            style={columnStyle}
                          >
                            {HeaderCell ? HeaderCell : column.render('Header')}
                            {
                              // @ts-expect-error: please fix if encountered
                              isSortable && column.isSorted && (
                                <chakra.span pl="3">
                                  {
                                    // @ts-expect-error: please fix if encountered
                                    column.isSortedDesc ? (
                                      <Icon
                                        as={GoTriangleDown}
                                        aria-label="sorted descending"
                                      />
                                    ) : (
                                      <Icon
                                        as={GoTriangleUp}
                                        aria-label="sorted ascending"
                                      />
                                    )
                                  }
                                </chakra.span>
                              )
                            }
                          </Th>
                        );
                      })}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {
                  // @ts-expect-error: please fix if encountered
                  page.map(row => {
                    prepareRow(row);

                    const rowProps = {
                      ...row.getRowProps(
                        props.getRowProps ? props.getRowProps(row) : undefined
                      ),
                    };

                    const rowStyle = rowProps.style || {};
                    if (
                      row.original[
                        props.rowDisabledOnPropertyTrue as keyof object
                      ]
                    ) {
                      rowStyle.color = appColors.inactive;
                    }

                    rowProps.style = rowStyle;

                    return (
                      <Tr
                        {...rowProps}
                        _hover={{
                          background: 'gray.50',
                        }}
                        cursor={props.onRowClick ? 'pointer' : 'initial'}
                        onClick={() => handleRowClick(row)}
                      >
                        <Box
                          as="td"
                          position="sticky"
                          top={0}
                          left={0}
                          zIndex="2"
                          backgroundColor="#fff"
                          display="table-cell"
                          borderBottom="var(--chakra-borders-1px)"
                          borderColor="var(--chakra-colors-gray-100)"
                        >
                          {row.cells
                            .filter((i: any) => i.column.isSticky)
                            .map((cell: any) => {
                              const columnStyle = (cell.column as any).style;
                              const dynamicStyle = (cell.column as any)
                                .dynamicStyle
                                ? (cell.column as any).dynamicStyle(row.values)
                                : null;
                              const cellValue = cell.value ? cell.value : '-';
                              return (
                                <Td
                                  {...cell.getCellProps()}
                                  className={
                                    selectedRowId === Number(row.id)
                                      ? 'active-row'
                                      : ''
                                  }
                                  style={dynamicStyle ?? columnStyle}
                                  width={`${cell.column.originalWidth}px`}
                                  borderBottom="none"
                                >
                                  {cell.column.Cell
                                    ? cell.render('Cell')
                                    : cellValue}
                                </Td>
                              );
                            })}
                        </Box>

                        {row.cells
                          .filter((i: any) => !i.column.isSticky)
                          .map((cell: any) => {
                            const columnStyle = (cell.column as any).style;
                            const dynamicStyle = (cell.column as any)
                              .dynamicStyle
                              ? (cell.column as any).dynamicStyle(row.values)
                              : null;
                            const cellValue = cell.value ? cell.value : '-';
                            return (
                              <Td
                                {...cell.getCellProps()}
                                className={
                                  selectedRowId === Number(row.id)
                                    ? 'active-row'
                                    : ''
                                }
                                style={dynamicStyle ?? columnStyle}
                              >
                                {cell.column.Cell
                                  ? cell.render('Cell')
                                  : cellValue}
                              </Td>
                            );
                          })}
                      </Tr>
                    );
                  })
                }
              </Tbody>
            </Table>
          </>
        )}
      </TableContainer>
      <Stack>
        {!props.isLoading && page.length > 0 && !props.hidePagination && (
          <Flex justifyContent="space-between" alignItems="center" mt={4}>
            <HStack hidden={props.hidePagination}>
              <IconButton
                aria-label="first page"
                onClick={() => {
                  if (props.manual) props.onPageChange(0);
                  else gotoPage(0);
                }}
                isDisabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleLeft />}
              />
              <IconButton
                aria-label="previous page"
                onClick={() => {
                  if (props.manual) props.onPageChange(pageIndex - 1);
                  else previousPage();
                }}
                isDisabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronLeft />}
              />
              {props.isPageNumberEditable ? (
                <>
                  <Tooltip label="Hit enter to go to page" openDelay={1000}>
                    <Input
                      size="xs"
                      w="40px"
                      {...input}
                      value={editablePage}
                      onChange={e => {
                        const val = e.target.value;
                        setEditablePage(val ? parseInt(val) : 0);
                      }}
                      onKeyDown={e => {
                        if (
                          e.key === 'Enter' &&
                          editablePage !== pageIndex + 1
                        ) {
                          const page =
                            editablePage > tablePageCount
                              ? tablePageCount
                              : editablePage < 1
                              ? 1
                              : editablePage;
                          setEditablePage(page);
                          if (props.manual) props.onPageChange(page - 1);
                          else gotoPage(page - 1);
                        }
                      }}
                    />
                  </Tooltip>
                  <Text fontSize="xs">{`of ${tablePageCount}`}</Text>
                </>
              ) : (
                <Text fontSize="xs">{`${
                  pageIndex + 1
                } of ${tablePageCount}`}</Text>
              )}
              <IconButton
                aria-label="next page"
                onClick={() => {
                  if (props.manual) props.onPageChange(pageIndex + 1);
                  else nextPage();
                }}
                isDisabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronRight />}
              />
              <IconButton
                aria-label="last page"
                onClick={() => {
                  if (props.manual) props.onPageChange(props.pageCount - 1);
                  else gotoPage(props.pageCount - 1);
                }}
                isDisabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleRight />}
              />
            </HStack>
          </Flex>
        )}
      </Stack>
    </VStack>
  );
};

export default CustomTable;
