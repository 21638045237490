import {
  Alert,
  Box,
  ButtonGroup,
  HStack,
  IconButton,
  Tooltip,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { RiMailSendFill } from 'react-icons/ri';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { useDebounce } from '../../app/hooks/useDebounce';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { useGetUserOrganizationByOrgQuery } from '../../app/services/provider/api/userOrganization';
import {
  GetUserOrganizationDto,
  OrganizationDto,
  UserOrganizationPagedDto,
} from '../../app/services/provider/types';
import { appColors } from '../../app/theme';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import CustomPopover from '../../components/CustomPopover';
import CustomTable from '../../components/CustomTable';
import AddRefUserButton from './AddRefUserButton';
import AddUserButton from './AddUserButton';
import EnableDisableUserOrgButton from './EnableDisableUserOrgButton';
import ResendInviteButton from './ResendInviteButton';

type Props = {
  selectedOrg: OrganizationDto;
  isUsersLoading: boolean;
  handleIsUsersLoading: (flag: boolean) => void;
};

const UserOrganizationList = ({
  selectedOrg,
  handleIsUsersLoading,
  isUsersLoading,
}: Props) => {
  const [pageIndexUser, setPageIndexUser] = useState(0);
  const [pageSizeUser, setPageSizeUser] = useState(20);
  const [searchUser, setSearchUser] = useState('');
  const [userList, setUserList] = useState<UserOrganizationPagedDto | null>(
    null
  );

  const [searchUserDebounce, setSearchUserDebounce] = useState('');
  const [isLargerThan1512] = useMediaQuery('(min-width: 1512px)');
  const debouncedSearchUser = useDebounce(searchUser, 400);
  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.userReadWriteAll
  );
  const userOrgResult = useGetUserOrganizationByOrgQuery(
    {
      orgId: selectedOrg?.organization_id || 0,
      filter: {
        page_number: pageIndexUser + 1,
        page_size: pageSizeUser,
        search: searchUserDebounce,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleOnSuccess = (addedUser: any) => {
    if (userList) {
      const updatedUsersList = [...userList.data, { ...addedUser }];
      setUserList({ ...userList, data: updatedUsersList });
    }
  };

  const handleResendInviteOnSuccess = () => {
    console.log('success');
  };

  useEffect(() => {
    if (selectedOrg) {
      setSearchUser('');
      setSearchUserDebounce('');
    }

    return () => {
      setUserList(null);
      setPageIndexUser(0);
    };
  }, [selectedOrg]);

  useEffect(() => {
    if (
      !userOrgResult.isLoading &&
      !userOrgResult.isFetching &&
      userOrgResult?.data?.data
    ) {
      setUserList(userOrgResult.data);
      handleIsUsersLoading(false);
    }
  }, [userOrgResult]);

  useEffect(() => {
    setSearchUserDebounce(debouncedSearchUser);
  }, [debouncedSearchUser]);

  const TableHeaderUsers = [
    {
      Header: 'Action',
      style: {
        display: 'flex',
        justifyContent: 'center',
      },
      Cell: ({
        row: { original },
      }: {
        row: { original: GetUserOrganizationDto };
      }) => {
        const auditInfo = [
          {
            key: 'USER ID',
            value: original.user_organization_id
              ? original.ref_user_id
              : 'Pending',
          },
          {
            key: 'CONSENT DATE',
            value: convertUtcToLocal(original.consent_date_time_utc) || '-',
          },
          {
            key: 'DISABLED',
            value: original.disabled_flag ? 'Yes' : 'No',
          },
          {
            key: 'DISABLED DATE',
            value: convertUtcToLocal(original.disabled_datetime_utc) || '-',
          },
          {
            key: 'CREATED BY',
            value: original.row_created_by_user_name || '-',
          },
          {
            key: 'DATE CREATED',
            value: convertUtcToLocal(original.row_created_date_time_utc) || '-',
          },
          {
            key: 'MODIFIED BY',
            value: original.row_modified_by_user_name || '-',
          },
          {
            key: 'DATE MODIFIED',
            value:
              convertUtcToLocal(original.row_modified_date_time_utc) || '-',
          },
        ];

        const invitePopoverItems = original.sent_by_ref_user_id
          ? [
              {
                key: 'INVITED BY',
                value: original.sent_by_user_name || '-',
              },
              {
                key: 'DATE INVITED',
                value: convertUtcToLocal(original.invite_date_time_utc) || '-',
              },
            ]
          : [];

        return (
          <ButtonGroup spacing={2}>
            <CustomPopover
              items={auditInfo}
              triggerElement={
                <IconButton
                  minWidth={1}
                  variant="link"
                  aria-label="View Details"
                  icon={<MdInfo />}
                  title="View Details"
                />
              }
            />
            <>
              {original.user_organization_id > 0 ? (
                <CustomPopover
                  items={invitePopoverItems}
                  triggerElement={
                    <IconButton
                      variant="link"
                      aria-label="View Details"
                      icon={<RiMailSendFill />}
                      minWidth={1}
                    />
                  }
                />
              ) : (
                <Tooltip
                  hasArrow
                  label="Pending"
                  bg="gray.100"
                  color="gray.600"
                  placement="top"
                >
                  <IconButton
                    minWidth={1}
                    variant="link"
                    aria-label="Pending"
                    icon={<RiMailSendFill />}
                  />
                </Tooltip>
              )}
            </>
            <EnableDisableUserOrgButton userOrganization={original} />
          </ButtonGroup>
        );
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
  ];

  return (
    <VStack align="stretch">
      <Box>
        <HStack
          display={isLargerThan1512 ? 'flex' : 'inherit'}
          align="stretch"
          justifyContent="space-between"
          alignItems="center"
        >
          {userHasRequiredRole && (
            <ButtonGroup spacing="2" display="flex" flexFlow="wrap" gap="2">
              <ResendInviteButton
                userList={userList?.data || []}
                onSuccess={handleResendInviteOnSuccess}
                orgId={selectedOrg.organization_id}
              />
              <AddRefUserButton
                onSuccess={handleOnSuccess}
                orgId={selectedOrg.organization_id}
              />
              <AddUserButton
                orgId={selectedOrg.organization_id}
                onSuccess={handleOnSuccess}
              />
            </ButtonGroup>
          )}
        </HStack>
        {!isUsersLoading &&
          (!userList?.data.length || userList?.data.length === 0) && (
            <HStack
              align="stretch"
              justifyContent="space-between"
              alignItems="center"
            >
              <Alert bg={appColors.hightLightColor} my={3}>
                No data found
              </Alert>
            </HStack>
          )}
      </Box>
      {
        /* Users Table */
        <CustomTable
          title="Users"
          hideRowsPerPage
          hideDisplayRecords
          isLoading={isUsersLoading}
          isFetching={isUsersLoading}
          data={userList?.data || []}
          pageCount={userList?.total_pages || 0}
          pageSize={pageSizeUser}
          pageIndex={pageIndexUser}
          totalRecords={userList?.total_records || 0}
          headers={TableHeaderUsers}
          rowDisabledOnPropertyTrue="disabled_flag"
          onPageChange={index => {
            setPageIndexUser(index);
          }}
          onPageSizeChange={size => {
            setPageIndexUser(0);
            setPageSizeUser(size);
          }}
          onPageSearch={search => {
            setSearchUser(search);
            setPageIndexUser(0);
          }}
          onSort={() => ({})}
          manual={true}
        />
      }
    </VStack>
  );
};

export default UserOrganizationList;
