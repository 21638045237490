export const getActivePathColor = (path: string) =>
  window?.location.pathname.indexOf(path) >= 0 ? 'teal' : 'gray';

export const isCommunityPage = (location?: any) => {
  const loc = location || window?.location;
  if (!loc) {
    return null;
  }

  return loc.pathname === '/community';
};
