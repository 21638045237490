import { useUserRoleContext } from '@/app/context/UserRoleContext';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Row, SortingRule } from 'react-table';
import {
  OrganizationContext,
  useChooseOrgContext,
} from '../../../app/context/ChooseOrgContext';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useDebounce } from '../../../app/hooks/useDebounce';
import { useGetOrganizationListQuery } from '../../../app/services/provider/api/organization';
import {
  useGetPatientDetailListQuery,
  useLazyGetPatientDetailListQuery,
} from '../../../app/services/provider/api/patientDetail';
import { GetPatientDetailListDataModel } from '../../../app/services/provider/types';
import { appColors } from '../../../app/theme';
import CustomTable from '../../../components/CustomTable';

type Props = object;

const InitialSortBy: SortingRule<GetPatientDetailListDataModel> = {
  id: 'bene_last_name',
  desc: false,
};
const TableHeader = [
  {
    Header: 'Patient Name',
    accessor: 'bene_last_name',
    Cell: ({
      row: { original: e },
    }: {
      row: Row<GetPatientDetailListDataModel>;
    }) => {
      return <>{e.bene_last_name + ', ' + e.bene_first_name}</>;
    },
    transform: (e: GetPatientDetailListDataModel) =>
      e.bene_last_name + ', ' + e.bene_first_name,
  },
  {
    Header: 'Patient DOB',
    Cell: ({
      row: { original: e },
    }: {
      row: Row<GetPatientDetailListDataModel>;
    }) => {
      return <>{convertUtcToLocal(e.bene_birth_dt, 'YYYY-MM-DD') || '-'}</>;
    },
    transform: (e: GetPatientDetailListDataModel) =>
      convertUtcToLocal(e.bene_birth_dt, 'YYYY-MM-DD') || '-',
  },
  {
    Header: 'Patient MBI',
    accessor: 'mr_mbi',
  },
  {
    Header: 'Provider',
    accessor: 'provider_last_name',
    dynamicStyle: (e: GetPatientDetailListDataModel) => {
      return !e.provider_first_name &&
        !e.provider_middle_name &&
        !e.provider_last_name
        ? { backgroundColor: 'lightyellow' }
        : {};
    },
    Cell: ({
      row: { original: e },
    }: {
      row: Row<GetPatientDetailListDataModel>;
    }) => {
      if (
        !e.provider_first_name &&
        !e.provider_middle_name &&
        !e.provider_last_name
      ) {
        return <Text>{e.organization_name}</Text>;
      }
      const middleName =
        !e.provider_middle_name || e.provider_middle_name === 'null'
          ? ''
          : e.provider_middle_name;
      return (
        <>
          {(
            e.provider_last_name +
            ', ' +
            e.provider_first_name +
            ' ' +
            middleName
          ).trim()}
        </>
      );
    },
    transform: (e: GetPatientDetailListDataModel) => {
      if (
        !e.provider_first_name &&
        !e.provider_middle_name &&
        !e.provider_last_name
      ) {
        return e.organization_name;
      }
      const middleName =
        !e.provider_middle_name || e.provider_middle_name === 'null'
          ? ''
          : e.provider_middle_name;
      return (
        e.provider_last_name +
        ', ' +
        e.provider_first_name +
        ' ' +
        middleName
      ).trim();
    },
  },
];

const PatientDetails: React.FC<Props> = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');
  const debouncedSearch = useDebounce(search, 400);
  const [sortBy, setSortBy] = useState<SortingRule<object>>(InitialSortBy);
  const [orgIdList, setOrgIdList] = useState<number[]>([]);
  const { userOrganizations } = useChooseOrgContext() as OrganizationContext;
  const { isUserLoreTeam } = useUserRoleContext();

  const orgListDetail = useGetOrganizationListQuery(
    { filter: { page_number: 1, page_size: 99999, search: '' } },
    { skip: !isUserLoreTeam }
  );

  const [getAsync] = useLazyGetPatientDetailListQuery();
  const { data, isLoading, isFetching } = useGetPatientDetailListQuery({
    organization_id_list:
      !isUserLoreTeam && orgIdList.length < 1
        ? userOrganizations?.map(({ organization_id }) => organization_id) ?? [
            -1,
          ] // none lore team should not fetch all patients
        : orgIdList,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    sort_column: sortBy.id as keyof GetPatientDetailListDataModel,
    PageNumber: pageIndex + 1,
    PageSize: pageSize,
    q: searchDebounce,
  });

  const handleSelectOrg = (orgId: number) => () => {
    const index = orgIdList.findIndex(f => f === orgId);
    if (index < 0) {
      setOrgIdList(s => [...s, orgId]);
    } else {
      setOrgIdList(s => {
        const list = s.slice();
        list.splice(index, 1);
        return list;
      });
    }
  };

  async function handleExportClick() {
    const lstEntire = await getAsync({
      organization_id_list: orgIdList,
      sort_order: sortBy.desc ? 'desc' : 'asc',
      sort_column: sortBy.id as keyof GetPatientDetailListDataModel,
      PageNumber: 1,
      PageSize: 99999,
      q: searchDebounce,
    });

    const list = lstEntire.data?.data.map(m =>
      // We reuse table headers so exported will have same arrangement as seen on UI
      TableHeader.reduce((prev, next) => {
        if (next.Header && next.transform) {
          return {
            ...prev,
            [next.Header]: next.transform(m),
          };
        }
        if (next.Header && next.accessor) {
          return {
            ...prev,
            [next.Header]:
              m[next.accessor as keyof GetPatientDetailListDataModel],
          };
        }
        return prev;
      }, {})
    );

    return list ?? [];
  }

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    setPageIndex(0);
  }, [debouncedSearch, orgIdList, pageSize, sortBy]);

  return (
    <VStack w="full" alignItems="start">
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          Choose Organization{' '}
          {orgIdList.length > 0 ? `(${orgIdList.length})` : ''}
        </MenuButton>
        <MenuList maxH="70vh" overflowY="auto">
          {(isUserLoreTeam && orgListDetail.data
            ? orgListDetail.data.data
            : userOrganizations
          )?.map(m => (
            <Box key={m.organization_id} px="4" py="2">
              <Checkbox
                isChecked={orgIdList.some(s => s === m.organization_id)}
                onChange={handleSelectOrg(m.organization_id)}
              >
                <Text title={m.organization_name} noOfLines={1} maxW="96">
                  {m.organization_name}
                </Text>
              </Checkbox>
            </Box>
          ))}
          <HStack px="4">
            <Button variant="ghost" ml="auto" onClick={() => setOrgIdList([])}>
              Clear
            </Button>
          </HStack>
        </MenuList>
      </Menu>

      {data && data.data.length < 1 && (
        <Box w="full">
          <Alert size="sm" bg={appColors.hightLightColor}>
            <AlertIcon />
            <Text fontSize={16} fontWeight="normal">
              No data found.
            </Text>
          </Alert>
        </Box>
      )}

      <Box w="full">
        <CustomTable
          isLoading={isLoading}
          isFetching={isFetching}
          data={(data && data.data) || []}
          pageCount={(data && data.total_pages) || 0}
          pageSize={(data && data.page_size) || pageSize}
          totalRecords={(data && data.total_records) || 0}
          pageIndex={pageIndex}
          headers={TableHeader}
          // @ts-expect-error: please fix if encountered
          initialState={{ sortBy: [InitialSortBy] }}
          exportName={`Patient_Details_${Date.now()}`}
          hasFilter
          hasLoadingIndicator
          manual
          manualSortBy
          disableSortRemove
          isPageNumberEditable
          onExportClick={handleExportClick}
          onPageChange={index => {
            if ((data?.total_pages || 1) > index) setPageIndex(index);
          }}
          onPageSizeChange={size => {
            setPageSize(size);
          }}
          onPageSearch={search => {
            setSearch(search);
          }}
          onSort={sort => {
            if (sort[0]) setSortBy(sort[0]);
          }}
        />
      </Box>
    </VStack>
  );
};

export default PatientDetails;
