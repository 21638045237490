import { Button } from '@chakra-ui/react';
import {
  LOCALSTORAGE_KEYS,
  saveState,
} from '../../app/helpers/localStorageHelper';
import { changePasswordRequest } from '../../app/services/auth/authConfig';

const ChangePassword = () => {
  // @ts-expect-error: please fix if encountered.
  const instance = globalThis?.msalInstance;

  const changePassword = () => {
    saveState('/change-password', LOCALSTORAGE_KEYS.b2cRedirect);
    instance.loginRedirect({ ...changePasswordRequest });
  };
  return (
    <div>
      <Button size="sm" color="brand" onClick={changePassword}>
        Change Password
      </Button>
    </div>
  );
};

export default ChangePassword;
