import {
  Alert,
  ButtonGroup,
  HStack,
  IconButton,
  Stack,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdInfo } from 'react-icons/md';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import { useDebounce } from '../../app/hooks/useDebounce';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { useGetOrganizationListQuery } from '../../app/services/provider/api/organization';
import { OrganizationDto } from '../../app/services/provider/types';
import { appColors } from '../../app/theme';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import CustomPopover from '../../components/CustomPopover';
import CustomTable from '../../components/CustomTable';
import EditOrganizationButton from './EditOrganizationButton';

type Props = {
  selectedOrg: OrganizationDto | null;
  onClickOrg: (org: OrganizationDto | null) => void;
};

const OrganizationList = ({ onClickOrg }: Props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [orgData, setOrgData] = useState<OrganizationDto[] | null>(null);
  const [searchDebounce, setSearchDebounce] = useState('');

  const debouncedSearch = useDebounce(search, 400);
  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.userReadWriteAll
  );

  const { data, isLoading, isFetching } = useGetOrganizationListQuery({
    filter: {
      page_number: pageIndex + 1,
      page_size: pageSize,
      search: searchDebounce.toLocaleUpperCase(),
    },
  });
  const handleRowClick = (row: OrganizationDto) => {
    if (userHasRequiredRole) {
      onClickOrg(row);
    }
  };

  useEffect(() => {
    onClickOrg(null);
  }, [search]);

  useEffect(() => {
    if (!isLoading && data) {
      setOrgData(() => data.data);
    }
  }, [data]);

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  const TableHeader = [
    {
      Header: 'Action',
      style: {
        width: 110,
      },
      Cell: (d: any) => {
        const {
          row: { original },
        } = d;

        const auditInfo = [
          { key: 'ID', value: original.organization_id || '-' },
          {
            key: 'TIN',
            value: original.organization_tin || '-',
          },
          {
            key: 'CREATED BY',
            value: original.row_created_by_user_name || '-',
          },
          {
            key: 'DATE CREATED',
            value: convertUtcToLocal(original.row_created_date_time_utc) || '-',
          },
          {
            key: 'MODIFIED BY',
            value: original.row_modified_by_user_name || '-',
          },
          {
            key: 'DATE MODIFIED',
            value:
              convertUtcToLocal(original.row_modified_date_time_utc) || '-',
          },
        ];

        return (
          <ButtonGroup spacing={2}>
            <EditOrganizationButton initialValues={original} />
            {userHasRequiredRole ? (
              <Tooltip
                hasArrow
                label="View Users"
                bg="gray.100"
                color="gray.600"
                placement="top"
              >
                <IconButton
                  minWidth={1}
                  variant="link"
                  aria-label="View Users"
                  icon={<BsFillEyeFill />}
                  title="View Users"
                  onClick={() => onClickOrg(original)}
                />
              </Tooltip>
            ) : (
              ''
            )}
            <CustomPopover
              items={auditInfo}
              triggerElement={
                <IconButton
                  minWidth={1}
                  variant="link"
                  aria-label="View Details"
                  icon={<MdInfo />}
                  title="View Details"
                />
              }
            />
          </ButtonGroup>
        );
      },
    },
    {
      Header: 'Name',
      style: {
        textTransform: 'uppercase',
      },
      accessor: 'organization_name',
    },
  ];

  return (
    <VStack align="stretch">
      <Stack align="stretch" justifyContent="space-between" alignItems="center">
        <Alert
          borderRadius={5}
          borderLeftWidth={4}
          bg={appColors.hightLightColor}
          borderLeftColor={appColors.brand.main.default}
        >
          Select an organization to add or assign users who will be able to
          update provider information for that organization.
        </Alert>
      </Stack>

      {!isLoading && !orgData?.length && (
        <HStack
          align="stretch"
          justifyContent="space-between"
          alignItems="center"
        >
          <Alert my={3} bg={appColors.hightLightColor}>
            No data found
          </Alert>
        </HStack>
      )}

      <CustomTable
        hideRowsPerPage
        hideDisplayRecords
        isLoading={isLoading}
        isFetching={isFetching}
        data={orgData || []}
        pageCount={(data && data.total_pages) || 0}
        pageSize={(orgData && pageSize) || pageSize}
        pageIndex={pageIndex}
        totalRecords={(orgData && orgData.length) || 0}
        headers={TableHeader}
        onPageChange={index => {
          if ((data?.total_pages || 1) > index) setPageIndex(index);
        }}
        onPageSizeChange={size => {
          setPageIndex(0);
          setPageSize(size);
        }}
        onPageSearch={search => {
          setPageIndex(0);
          setSearch(search);
        }}
        onSort={() => ({})}
        onRowClick={handleRowClick}
        manual={true}
      />
    </VStack>
  );
};

export default OrganizationList;
