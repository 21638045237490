import { providerApi } from './index';
import { OrganizationAcknowledgementDto } from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getOrganizationAcknowledgementByRefuserId: builder.query<
      Array<OrganizationAcknowledgementDto>,
      number
    >({
      query: refuserid => `/organizationacknowledgement/user/${refuserid}`,
      providesTags: ['OrganizationAcknowledgement'],
    }),

    postOrganizationAcknowledgement: builder.mutation<
      void,
      { refUserId: number; organizationId: number }
    >({
      query: ({ refUserId, organizationId }) => ({
        url: `/organizationacknowledgement/${refUserId}/${organizationId}`,
        method: 'POST',
      }),
      invalidatesTags: ['OrganizationAcknowledgement'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationAcknowledgementByRefuserIdQuery,
  usePostOrganizationAcknowledgementMutation,
} = extendedApi;
