import {
  AppAccessAuth,
  AppAuthRequiredAppAccess,
} from '@/app/types/appAccessAuth';
import { IconType } from 'react-icons';
import { AiFillFolderOpen } from 'react-icons/ai';
import { CgAdd } from 'react-icons/cg';
import {
  FaBan,
  FaFileSignature,
  FaHome,
  FaHospitalUser,
  FaRegHospital,
  FaUserCog,
  FaUsers,
  FaUsersCog,
} from 'react-icons/fa';
import { GiStamper } from 'react-icons/gi';
import { HiDatabase } from 'react-icons/hi';
import { MdApproval, MdDomain, MdOutlinePayments } from 'react-icons/md';

export type NavItemProps = {
  icon: IconType;
  label: string;
  subLabel?: string;
  href: string;
  requiredAppAccess: AppAuthRequiredAppAccess;
  subNav?: Array<NavItemProps>;
};

export const navItems: Array<NavItemProps> = [
  {
    icon: FaHome,
    label: 'Provider List',
    href: '/clinic-provider',
    requiredAppAccess: [],
    subNav: [
      {
        icon: FaUsers,
        label: 'Patient Details',
        href: '/clinic-provider/patient-details',
        requiredAppAccess: AppAccessAuth.ProviderPatientDetailsAll,
      },
    ],
  },
  {
    icon: FaUsers,
    label: 'User',
    href: '/user',
    requiredAppAccess: AppAccessAuth.userReadWriteAll,
  },
  {
    icon: FaUserCog,
    label: 'Roles',
    href: '/roles',
    requiredAppAccess: [],
    subNav: [
      {
        icon: FaUsersCog,
        label: 'Explore Roles',
        href: '/roles/explore-roles',
        requiredAppAccess: [],
      },
      {
        icon: CgAdd,
        label: 'Add Role',
        href: '/roles/add-role',
        requiredAppAccess: AppAccessAuth.addRolesAll,
      },
      {
        icon: MdApproval,
        label: 'Access Approval',
        href: '/roles/access-approval',
        requiredAppAccess: AppAccessAuth.AccessApprovalAll,
      },
    ],
  },
  {
    icon: MdDomain,
    label: 'Organization',
    href: '/organization',
    requiredAppAccess: AppAccessAuth.organizationReadAndWriteAll,
  },
  {
    icon: HiDatabase,
    label: 'Report Hub',
    href: '/report-hub',
    requiredAppAccess: AppAccessAuth.reportHubReadWriteAll,
    subNav: [
      {
        icon: MdOutlinePayments,
        label: 'ACO Payment Report',
        href: '/report-hub/aco-payment-report',
        requiredAppAccess: AppAccessAuth.reportHubReadWriteAll,
      },
    ],
  },
  {
    icon: GiStamper,
    label: 'Orders to Sign',
    href: '/orders-to-sign',
    requiredAppAccess: AppAccessAuth.ordersToSignAll,
    subNav: [
      // {
      //   icon: FaHospitalUser,
      //   label: 'CGM - Insulin Users',
      //   href: '/orders-to-sign/cgm-insulin-users',
      //   requiredAppAccess: AppAccessAuth.ordersToSignCgmAll,
      // },
      {
        icon: FaRegHospital,
        label: 'CGM - Non-insulin Users',
        href: '/orders-to-sign/cgm-noninsulin-users',
        requiredAppAccess: AppAccessAuth.ordersToSignCgmAll,
      },
      {
        icon: FaBan,
        label: 'CGM - Declined Users',
        href: '/orders-to-sign/cgm-declined-users',
        requiredAppAccess: AppAccessAuth.ordersToSignCgmAll,
      },
    ],
  },
  {
    icon: FaFileSignature,
    label: 'Fulfillment',
    href: '/fulfillment',
    requiredAppAccess: AppAccessAuth.fulfillmentAll,
    subNav: [
      {
        icon: FaHospitalUser,
        label: 'CGM',
        href: '/fulfillment/cgm',
        requiredAppAccess: AppAccessAuth.fulfillmentCgmAll,
      },
    ],
  },
  {
    icon: AiFillFolderOpen,
    label: 'File Share',
    href: '/file-share',
    requiredAppAccess: AppAccessAuth.fileShareAll,
  },
];
