import { FC, useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import {
  PostOrganizationDto,
  PutOrganizationDto,
} from '../../app/services/provider/types';
import { useGetListRefPaymentTrackQuery } from '@/app/services/provider/api/refPaymentTrack';
import { Select } from 'chakra-react-select';

type IProps = {
  mode: 'Add' | 'Edit';
  modalTitle: string;
  initialValues: PutOrganizationDto | PostOrganizationDto;
  isOpen: boolean;
  onClose: () => void;
  successMessage: string;
  onSubmit: (values: any) => void;
  submitButtonLabel: string;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  error: any | null;
};

const OrganizationFormModal: FC<IProps> = (props: IProps) => {
  const alertDisclosure = useDisclosure();
  const {
    mode,
    modalTitle,
    isOpen,
    onClose,
    successMessage,
    onSubmit,
    submitButtonLabel,
    isSuccess,
    isError,
    isLoading,
    error,
  } = props;

  const [initialValues, setInitialValues] = useState(props.initialValues);

  const paymentDetail = useGetListRefPaymentTrackQuery();

  useEffect(() => {
    setInitialValues(props.initialValues);
  }, [props]);

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        mode === 'Add' && resetForm();
        setTimeout(() => {
          onModalClose();
        }, 3000);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  const FormSchema = Yup.object().shape({
    organization_name: Yup.string().label('Name').required().max(150),
    organization_address_1: Yup.string().label('Address 1').max(150),
    organization_address_2: Yup.string().label('Address 2').max(150),
    organization_city: Yup.string().label('City').max(100),
    organization_state: Yup.string().label('State').max(100),
    organization_zip_code: Yup.string().label('Zip').max(9),
    ref_payment_track_id: Yup.number().label('Payment Track').moreThan(0),
    doing_business_as: Yup.string().label('Doing Business As').max(1000),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    resetForm,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: initialValues,
    onSubmit: values => {
      const val = {
        ...values,
        organization_tin:
          values.organization_tin === '' ? null : values.organization_tin,
      };
      onSubmit(val);
    },
  });

  const onModalClose = () => {
    onClose();
    resetForm();
    alertDisclosure.onClose();
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onModalClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={5} align="stretch">
                <HStack spacing={7} alignItems="flex-start">
                  <FormControl
                    isRequired
                    isInvalid={
                      !!errors.organization_name && touched.organization_name
                    }
                  >
                    <FormLabel htmlFor="organization-name">Name</FormLabel>
                    <Input
                      id="organization-name"
                      placeholder="Name"
                      name="organization_name"
                      onChange={handleChange}
                      value={values.organization_name}
                    />
                    <FormErrorMessage>
                      {errors.organization_name}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack spacing={7} alignItems="flex-start">
                  <FormControl
                    isInvalid={
                      !!errors.organization_address_1 &&
                      touched.organization_address_1
                    }
                  >
                    <FormLabel htmlFor="organization-address-1">
                      Address 1
                    </FormLabel>
                    <Input
                      id="organization-address-1"
                      placeholder="Address 1"
                      name="organization_address_1"
                      onChange={handleChange}
                      value={values.organization_address_1}
                    />
                    <FormErrorMessage>
                      {errors.organization_address_1}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack spacing={7} alignItems="flex-start">
                  <FormControl
                    isInvalid={
                      !!errors.organization_address_2 &&
                      touched.organization_address_2
                    }
                  >
                    <FormLabel htmlFor="organization-address-2">
                      Address 2
                    </FormLabel>
                    <Input
                      id="organization-address-2"
                      placeholder="Address 2"
                      name="organization_address_2"
                      onChange={handleChange}
                      value={values.organization_address_2}
                    />
                    <FormErrorMessage>
                      {errors.organization_address_2}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack spacing={7} alignItems="flex-start">
                  <FormControl
                    isInvalid={
                      !!errors.organization_city && touched.organization_city
                    }
                  >
                    <FormLabel htmlFor="organization-city">City</FormLabel>
                    <Input
                      id="organization-city"
                      placeholder="City"
                      name="organization_city"
                      onChange={handleChange}
                      value={values.organization_city}
                    />
                    <FormErrorMessage>
                      {errors.organization_city}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!errors.organization_state && touched.organization_state
                    }
                  >
                    <FormLabel htmlFor="organization-state">State</FormLabel>
                    <Input
                      id="organization-state"
                      placeholder="State"
                      name="organization_state"
                      onChange={handleChange}
                      value={values.organization_state}
                    />
                    <FormErrorMessage>
                      {errors.organization_state}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack spacing={7} alignItems="flex-start">
                  <FormControl
                    isInvalid={
                      !!errors.organization_zip_code &&
                      touched.organization_zip_code
                    }
                  >
                    <FormLabel htmlFor="organization-zip-code">Zip</FormLabel>
                    <Input
                      as={NumberFormat}
                      id="organization-zip-code"
                      placeholder="Zip"
                      name="organization_zip_code"
                      onChange={handleChange}
                      value={values.organization_zip_code}
                    />
                    <FormErrorMessage>
                      {errors.organization_zip_code}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!errors.organization_tin && touched.organization_tin
                    }
                  >
                    <FormLabel htmlFor="organization-tin">TIN</FormLabel>
                    <Input
                      as={NumberFormat}
                      id="organization-tin"
                      placeholder="TIN"
                      name="organization_tin"
                      onChange={handleChange}
                      value={values.organization_tin}
                    />
                    <FormErrorMessage>
                      {errors.organization_tin}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                {paymentDetail.data && (
                  <FormControl
                    isInvalid={
                      !!errors.ref_payment_track_id &&
                      touched.ref_payment_track_id
                    }
                  >
                    <FormLabel htmlFor="organization-tin">
                      Payment Track (Title - Cohort 1 | Cohort 2 | PCT)
                    </FormLabel>
                    <Select
                      id="ref_payment_track_id"
                      name="ref_payment_track_id"
                      useBasicStyles
                      size="sm"
                      placeholder="Select payment track..."
                      options={paymentDetail.data.map(m => ({
                        label: `${m.payment_track_desc} - $${
                          m.first_cohort_amt ?? 0
                        } | $${m.second_cohort_amt ?? 0} | ${
                          (m.performance_based_pct ?? 0) * 100
                        }%`,
                        value: m.ref_payment_track_id,
                      }))}
                      onChange={e => {
                        e &&
                          setValues({
                            ...values,
                            ref_payment_track_id: e.value,
                          });
                      }}
                      value={(() => {
                        const m = paymentDetail.data.find(
                          f =>
                            f.ref_payment_track_id ===
                            values.ref_payment_track_id
                        );
                        if (m) {
                          return {
                            label: `${m.payment_track_desc} - $${
                              m.first_cohort_amt ?? 0
                            } | $${m.second_cohort_amt ?? 0} | ${
                              (m.performance_based_pct ?? 0) * 100
                            }%`,
                            value: m.ref_payment_track_id,
                          };
                        }
                      })()}
                    />
                    <FormErrorMessage>
                      {errors.ref_payment_track_id ===
                      'Payment Track must be greater than 0'
                        ? 'Payment Track is required'
                        : errors.ref_payment_track_id}
                    </FormErrorMessage>
                  </FormControl>
                )}
                <HStack spacing={7} alignItems="flex-start">
                  <FormControl
                    isInvalid={
                      !!errors.doing_business_as && touched.doing_business_as
                    }
                  >
                    <FormLabel htmlFor="organization-dba">
                      Doing Business As
                    </FormLabel>
                    <Textarea
                      id="organization-dba"
                      placeholder="Doing Buisness As"
                      name="doing_business_as"
                      onChange={handleChange}
                      value={values.doing_business_as}
                    />
                    <FormErrorMessage>
                      {errors.doing_business_as}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>

                {alertDisclosure.isOpen && (
                  <Alert
                    status={isSuccess ? 'success' : isError ? 'error' : 'info'}
                  >
                    <AlertIcon />
                    {isSuccess && `${successMessage}`}
                    {isError &&
                      !error &&
                      'Something went wrong, please try again later or contact admin'}
                    {isError && error && 'data' in error && error?.data}
                  </Alert>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onModalClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="brand.main"
                type="submit"
                isLoading={isLoading}
              >
                {submitButtonLabel}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrganizationFormModal;
