import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Box,
  VStack,
  Text,
} from '@chakra-ui/react';

type IProps = {
  items: Array<ItemsModel>;
  triggerElement: JSX.Element;
};
type ItemsModel = {
  key: string;
  value: any;
};
const CustomPopover = ({ items, triggerElement }: IProps) => {
  return (
    <Popover isLazy trigger="hover" placement="top">
      <PopoverTrigger>{triggerElement}</PopoverTrigger>
      <PopoverContent width="100%">
        <PopoverArrow bg="gray.100" />
        <PopoverBody bg="gray.100" p={2}>
          {items.length > 0 ? (
            items.map((item, index) => {
              return (
                <Box key={index}>
                  <VStack p={2} align="stretch" lineHeight="0.5">
                    <Text as="small" color="blue.400">
                      {item.key}
                    </Text>
                    <Text as="strong" color="gray.600">
                      {item.value}
                    </Text>
                  </VStack>
                </Box>
              );
            })
          ) : (
            <>No Data</>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomPopover;
