import { Navigate } from 'react-router-dom';
import { useUserRoleContext } from '../../app/context/UserRoleContext';
import { UserRoleAppAccessByEmailModel } from '../../app/services/provider/types';
import { AppAuthRequiredAppAccess } from '../../app/types/appAccessAuth';

type RouteAuthProps = {
  children: JSX.Element;
  requiredAppAccess: AppAuthRequiredAppAccess;
};
export const RouteAuth = ({
  children,
  requiredAppAccess: requiredRoles,
}: RouteAuthProps) => {
  const { userAppAccess } = useUserRoleContext();

  const userHasRequiredRole = userAppAccess.some(
    (s: UserRoleAppAccessByEmailModel) =>
      requiredRoles.includes(s.app_access_name as any)
  );

  if (!userHasRequiredRole) {
    return <Navigate replace to={'/'} />;
  }

  return children;
};
