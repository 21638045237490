import {
  RefRoleDto,
  RefRoleUsersSummaryDto,
  RefRoleUsersSummaryParamDto,
} from '../types';
import { providerApi } from './index';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getRefRoleList: builder.query<RefRoleDto[], void>({
      query: () => `/refRole`,
    }),
    getRefRoleUsersSummary: builder.query<
      RefRoleUsersSummaryDto,
      RefRoleUsersSummaryParamDto
    >({
      query: params =>
        `/refRole/users/summary?sort_order=${
          params.sort_order || 'desc'
        }&sort_column=${params.sort_column || 'ref_role_id'}&PageNumber=${
          params.PageNumber || 1
        }&PageSize=${params.PageSize || 5}&q=${params.q || ''}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefRoleListQuery, useGetRefRoleUsersSummaryQuery } =
  extendedApi;
