import { providerApi } from './index';
import {
  GetUserOrganizationDto,
  PostUserOrganizationDto,
  PutUserOrganizationDto,
  UserOrganizationPagedDto,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getUserOrganizationByOrg: builder.query<UserOrganizationPagedDto, any>({
      query: params =>
        `/userorganization/organization/${params.orgId}?pagenumber=${params.filter.page_number}&pagesize=${params.filter.page_size}&q=${params.filter.search}`,
      providesTags: ['UserOrganization'],
    }),
    getUserOrganizationById: builder.query<GetUserOrganizationDto, number>({
      query: orgId => `/userorganization/${orgId}`,
      providesTags: ['UserOrganization'],
    }),
    getUserOrganizationByRefUserId: builder.query<
      GetUserOrganizationDto[],
      number
    >({
      query: refuserId => `/userorganization/ref_user_id/${refuserId}`,
      providesTags: ['UserOrganization'],
    }),
    getUserOrganizationList: builder.query<GetUserOrganizationDto[], void>({
      query: () => `/userorganization`,
      providesTags: ['UserOrganization'],
    }),
    postUserOrganization: builder.mutation<
      { id: number },
      PostUserOrganizationDto
    >({
      query: params => ({
        url: '/userorganization',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['UserOrganization'],
    }),
    putUserOrganization: builder.mutation<void, PutUserOrganizationDto>({
      query: params => ({
        url: '/userorganization',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['UserOrganization'],
    }),
    patchUserOrganizationDisableFlag: builder.mutation<void, number>({
      query: userOrgId => ({
        url: `/userorganization/disable/${userOrgId}/reverseflag`,
        method: 'PATCH',
      }),
      invalidatesTags: ['UserOrganization'],
    }),
    deleteUserOrganization: builder.mutation<void, number>({
      query: id => ({
        url: `/userorganization/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserOrganization'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetUserOrganizationByOrgQuery,
  useGetUserOrganizationByRefUserIdQuery,
  useGetUserOrganizationListQuery,
  useLazyGetUserOrganizationListQuery,
  useLazyGetUserOrganizationByOrgQuery,
  useLazyGetUserOrganizationByRefUserIdQuery,
  useLazyGetUserOrganizationByIdQuery,
  usePostUserOrganizationMutation,
  usePutUserOrganizationMutation,
  useDeleteUserOrganizationMutation,
  usePatchUserOrganizationDisableFlagMutation,
} = extendedApi;
