import { providerApi } from './index';
import { GetOrganizationInvite } from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getOrganizationInviteByUserOrgId: builder.query<
      Array<GetOrganizationInvite>,
      number
    >({
      query: userOrgId => `/organizationinvite/${userOrgId}`,
      providesTags: ['OrganizationInvite'],
    }),
    postOrganizationResendInvites: builder.mutation<void, Array<string>>({
      query: emails => ({
        url: '/organizationinvite/resend',
        method: 'POST',
        body: emails,
      }),
      invalidatesTags: ['OrganizationInvite'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useLazyGetOrganizationInviteByUserOrgIdQuery,
  usePostOrganizationResendInvitesMutation,
} = extendedApi;
