import { Flex, Box, Heading, Text, Link } from '@chakra-ui/react';
import React, { FC } from 'react';

type UnAuthorizedViewProps = {
  show: boolean;
  onClick: () => void;
};

const UnAuthorizedView: FC<UnAuthorizedViewProps> = ({ show, onClick }) => {
  return (
    <>
      {show && (
        <Flex
          direction="column"
          align="stretch"
          alignItems="center"
          justifyContent="center"
          padding="2.25rem"
          minH="100vh"
          maxW="180ch"
        >
          <Box shadow="md" p={5} textAlign="center" borderWidth={1}>
            <Heading fontSize="5rem" color="gray.500">
              401
            </Heading>
            <Box mt={5} color="gray.400" fontWeight="medium">
              <Text>SORRY, YOUR REQUESTS IS UNAUTHORIZED</Text>
              <Text>
                Go Back to login{' '}
                <Link color="blue.500" onClick={onClick}>
                  page
                </Link>
              </Text>
            </Box>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default UnAuthorizedView;
