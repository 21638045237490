import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import counterReducer from '../../features/Counter/counterSlice';
import appReducer from '../slices/appSlice';
import { providerApi } from '../services/provider/api';
import { persistReducer, persistStore } from 'redux-persist';
import { cmsApi } from '../services/cms/api';
import userReducer from './../slices/userSlice';
import userRoleReducer from './../slices/userRoleSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(rootPersistConfig, userReducer);
const appPersistedReducer = persistReducer(
  {
    key: 'app',
    storage: storage,
  },
  appReducer
);
const userRolePersistedReducer = persistReducer(
  { key: 'userRole', storage: storage },
  userRoleReducer
);

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    app: appPersistedReducer,
    userRole: userRolePersistedReducer,
    counter: counterReducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(providerApi.middleware, cmsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);
