import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Steps, useSteps, Step } from 'chakra-ui-steps';
import { Center, Flex, Heading } from '@chakra-ui/layout';
import { useStepperContext } from '../../app/context/StepperContext';
import { Button } from '@chakra-ui/button';
import { ChildProcess } from 'child_process';

type IStepperSteps = {
  children: ReactNode;
};
const StepperSteps: FC<IStepperSteps> = ({ children }) => {
  const { setSteps, activeStep, setCompleted } = useStepperContext();
  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    const stepperSteps = childrenArray.map((step: any) => step.props);

    setSteps(stepperSteps);
  }, [setSteps]);

  useEffect(() => {
    if (activeStep === childrenArray.length) {
      setCompleted(true);
    }
  });

  return (
    <>
      <Steps
        colorScheme="brand.main"
        labelOrientation="vertical"
        activeStep={activeStep}
        w="40%"
        m="0 auto"
      >
        {childrenArray.map(child => {
          if (React.isValidElement(child)) {
            return (
              <Step {...child.props} key={child.props.id}>
                {child.props.children}
              </Step>
            );
          }
          return null;
        })}
      </Steps>
    </>
  );
};

export default StepperSteps;
