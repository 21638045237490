import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
} from '@azure/msal-react';
import {
  Avatar,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isCommunityPage } from '../../app/helpers/navigationHelpers';
import { loginRequest } from '../../app/services/auth/authConfig';
import { useGetAvatarQuery } from '../../app/services/provider/api/avatar';
import { useAppSelector } from '../../app/state/hooks';
import { appColors } from '../../app/theme';
import Feedback from '../../features/Feedback';

type IProps = object;

const Header: FC<IProps> = () => {
  const account = useAccount();
  // @ts-expect-error: please fix if encountered
  const instance = globalThis.msalInstance;
  const location = useLocation();
  const { isShowMobileTab } = useAppSelector(s => s.app);
  const { onClose } = useDisclosure();
  const [userFullName, setUserFullName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const { logonUser } = useAppSelector(s => s.user);
  const isTest = Boolean(process.env.REACT_APP_BORDERED_HEADER);

  const { data, isLoading, isFetching } = useGetAvatarQuery(
    logonUser?.user_email || '',
    {
      skip: !isAuthenticated,
    }
  );

  const navigateProfile = () => {
    navigate('/profile');
  };

  useEffect(() => {
    if (isAuthenticated) {
      const { idTokenClaims } = account as any;
      if (idTokenClaims) {
        const upn =
          account?.username ||
          (idTokenClaims.emails && idTokenClaims.emails[0]) ||
          '';
        setUserEmail(upn);
      }
    }
  }, [account, isAuthenticated]);

  useEffect(() => {
    if (location.pathname !== '/widgets') {
      onClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      logonUser &&
      logonUser?.user_first_name.length !== 0 &&
      logonUser?.user_last_name.length !== 0
    ) {
      setUserFullName(
        `${logonUser.user_first_name} ${logonUser.user_last_name}`
      );
    } else {
      setUserFullName(logonUser?.user_email || '');
    }

    if (logonUser && logonUser.user_email) {
      setUserEmail(logonUser.user_email);
    }
  }, [logonUser]);

  useEffect(() => {
    if (!isFetching && data) {
      setProfilePic(data);
    }
  }, [isFetching]);

  return (
    <>
      <Flex
        bg={appColors.header.bgColor}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        w="100%"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottomWidth={isTest ? 3 : 1}
        borderBottomColor={isTest ? appColors.testEnvironmentIndicator : ''}
        borderStyle={'solid'}
        borderTopColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        // @ts-expect-error: please fix if encountered
        hidden={isShowMobileTab && isCommunityPage(location)}
        justifyContent="space-between"
        position="fixed"
        zIndex={3}
      >
        <Heading color="white" size={{ base: 'sm', sm: 'md' }}>
          Provider Portal
        </Heading>
        <Stack
          justify={'flex-end'}
          alignItems="center"
          direction={'row'}
          spacing={3}
        >
          {/* <Tooltip label="FAQ">
            <Link as={RouterLink} to={"/faq"} height="18px">
              <Icon as={RiQuestionAnswerFill} color="white" fontSize={18} />
            </Link>
          </Tooltip> */}
          {logonUser && <Feedback />}
          <Menu>
            {!isLoading && (
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                {profilePic ? (
                  <Avatar size={'sm'} bg="gray.300" src={profilePic} />
                ) : (
                  <Avatar size={'sm'} bg="gray.300" name={userFullName} />
                )}
              </MenuButton>
            )}

            <MenuList>
              <AuthenticatedTemplate>
                <MenuGroup
                  title={userFullName.length > 0 ? userFullName : userEmail}
                >
                  <MenuDivider />
                  <>
                    <MenuItem onClick={navigateProfile}>Edit Profile</MenuItem>
                    <MenuDivider />
                  </>

                  <MenuItem
                    onClick={() => {
                      instance
                        .logoutRedirect({
                          postLogoutRedirectUri: '/',
                          account: instance.getActiveAccount(),
                        })
                        .then(() => ({}));

                      sessionStorage.clear();
                      localStorage.clear();
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </MenuGroup>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <MenuGroup>
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      instance.loginRedirect(loginRequest);
                    }}
                  >
                    Sign In
                  </MenuItem>
                </MenuGroup>
              </UnauthenticatedTemplate>
            </MenuList>
          </Menu>
        </Stack>
      </Flex>
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="xs"
      >
        <DrawerContent>
          <DrawerCloseButton mt={3} />
          <Box mt={9}>
            <MobileNav navItems={navItems} />
          </Box>
        </DrawerContent>
      </Drawer> */}
    </>
  );
};

export default Header;
