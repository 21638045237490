import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { GoCircleSlash } from 'react-icons/go';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { usePatchUserOrganizationDisableFlagMutation } from '../../app/services/provider/api/userOrganization';
import { GetUserOrganizationDto } from '../../app/services/provider/types';

type IProps = {
  userOrganization: GetUserOrganizationDto;
};

const EnableDisableUserOrgButton: FC<IProps> = ({
  userOrganization,
}: IProps) => {
  const alertDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mode, setMode] = useState<'enable' | 'disable'>('enable');
  const cancelRef = useRef(null);
  const [updateAsync, { isLoading, isSuccess, isError }] =
    usePatchUserOrganizationDisableFlagMutation();

  const onSubmit = () => {
    updateAsync(userOrganization.user_organization_id);
  };

  const userName =
    `${userOrganization.first_name}${userOrganization.last_name}` === ''
      ? userOrganization.email
      : `${userOrganization.first_name} ${userOrganization.last_name}`;

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          alertDisclosure.onClose();
          onClose();
        }, 2000);
      }
    }
    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isOpen) {
      setMode(userOrganization.disabled_flag ? 'enable' : 'disable');
    }
  }, [isOpen]);

  return (
    <>
      <Tooltip
        hasArrow
        label={
          userOrganization.disabled_flag
            ? 'Click to Enable'
            : 'Click to Disable'
        }
        bg="gray.100"
        color="gray.600"
        placement="top"
      >
        <IconButton
          variant="link"
          color={userOrganization.disabled_flag ? 'gray.500' : 'green.500'}
          aria-label="Delete"
          icon={
            userOrganization.disabled_flag ? (
              <GoCircleSlash />
            ) : (
              <IoIosCheckmarkCircle />
            )
          }
          onClick={onOpen}
          minWidth={1}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Text textTransform="capitalize">{mode}</Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              {`Are you sure want to ${
                mode === 'enable' ? 'grant' : 'remove'
              } access for ${userName} from ${
                userOrganization.organization_name
              }?`}

              {alertDisclosure.isOpen && (
                <Alert
                  status={isSuccess ? 'success' : isError ? 'error' : 'info'}
                  mt={3}
                >
                  <AlertIcon />
                  {isSuccess &&
                    `Successfully ${
                      mode === 'enable' ? 'enabled' : 'disabled'
                    }`}
                  {isError &&
                    'Something went wrong, please try again later or contact admin'}
                </Alert>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme={mode === 'enable' ? 'brand.main' : 'orange'}
                onClick={onSubmit}
                ml={3}
                isLoading={isLoading}
                disabled={alertDisclosure.isOpen}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EnableDisableUserOrgButton;
