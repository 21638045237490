import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDeleteClinicProviderMutation } from '../../app/services/provider/api/clinicProvider';
import { appColors } from '../../app/theme';

type IProps = {
  clinic_provider_id: number;
};

const DeleteProviderButton: FC<IProps> = ({ clinic_provider_id }) => {
  const alertDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [deleteAsync, { isLoading, isSuccess, isError }] =
    useDeleteClinicProviderMutation();

  const onDelete = () => {
    deleteAsync(clinic_provider_id);
  };

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          alertDisclosure.onClose();
          onClose();
        }, 1800);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);
  return (
    <>
      <Tooltip
        hasArrow
        label="Delete"
        bg="gray.100"
        color="gray.600"
        placement="top"
      >
        <IconButton
          variant="outline"
          color="brand.error"
          size="sm"
          aria-label="Delete"
          icon={<MdDelete />}
          onClick={onOpen}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Provider
            </AlertDialogHeader>

            <AlertDialogBody>
              This action cannot be undone. Are you sure you want to delete this
              record?
              {alertDisclosure.isOpen && (
                <Alert
                  status={isSuccess ? 'success' : isError ? 'error' : 'info'}
                  mt={3}
                >
                  <AlertIcon />
                  {isSuccess && `Successfully deleted record`}
                  {isError &&
                    'Something went wrong, please try again later or contact admin'}
                </Alert>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="orange"
                onClick={onDelete}
                ml={3}
                isLoading={isLoading}
                disabled={alertDisclosure.isOpen}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteProviderButton;
