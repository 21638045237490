import { Alert, AlertIcon, Box, ButtonGroup, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { SortingRule } from 'react-table';
import {
  OrganizationContext,
  useChooseOrgContext,
} from '../../app/context/ChooseOrgContext';
import { StepperProvider } from '../../app/context/StepperContext';
import { useDebounce } from '../../app/hooks/useDebounce';
import {
  useGetClinicProviderListByOrgV2Query,
  useLazyGetClinicProviderListByOrgV2Query,
} from '../../app/services/provider/api/clinicProvider';
import { ClinicProviderDto } from '../../app/services/provider/types';
import { appColors } from '../../app/theme';
import CustomTable from '../../components/CustomTable';
import DeleteProviderButton from './DeleteProviderButton';
import ViewProviderDetailsButton from './ViewProviderDetailsButton';

type Props = {
  orgId: number;
};

const ClinicalProviderTableHeader = [
  {
    Header: 'Action',
    Cell: (d: any) => {
      const {
        row: { original },
      } = d;
      return (
        <>
          <ButtonGroup variant="outline">
            <ViewProviderDetailsButton
              clinicProviderId={original.clinic_provider_id}
            />
            <DeleteProviderButton
              clinic_provider_id={original.clinic_provider_id}
            />
          </ButtonGroup>
        </>
      );
    },
  },
  // {
  //   Header: " ",
  //   Cell: (d: any) => {
  //     const {
  //       row: { original },
  //     } = d;
  //     return (
  //       <>
  //         {original.incorrect_data_flag ? (
  //           <Tooltip label="Incorrect data" placement="auto-start">
  //             <span>
  //               <Icon as={RiFileWarningLine} color="#FFBB17" w={5} h={5} />
  //             </span>
  //           </Tooltip>
  //         ) : (
  //           <></>
  //         )}
  //       </>
  //     );
  //   },
  // },
  {
    Header: 'NPI',
    accessor: 'provider_npi',
  },
  {
    Header: 'Last Name',
    accessor: 'provider_last_name',
  },
  {
    Header: 'First Name',
    accessor: 'provider_first_name',
  },
  {
    Header: 'Middle Name',
    accessor: 'provider_middle_name',
    style: { textAlign: 'center' },
  },
  {
    Header: 'Gender',
    accessor: 'provider_gender',
    style: { textAlign: 'center' },
  },
  {
    Header: 'PECOS ID',
    accessor: 'provider_pecos_id',
  },
  {
    Header: 'Enrollment ID',
    accessor: 'provider_enrollment_id',
  },
  {
    Header: 'Provider Type CD',
    accessor: 'provider_provider_type_cd',
    style: { textAlign: 'center' },
  },
  {
    Header: 'Provider Type Desc',
    accessor: 'provider_provider_type_desc',
  },
  {
    Header: 'State CD',
    accessor: 'provider_state_cd',
    style: { textAlign: 'center' },
  },
  {
    Header: 'Org Name',
    accessor: 'organization_name',
  },
];

const InitialSortBy: SortingRule<object> = {
  id: 'provider_last_name',
  desc: false,
};

const ClinicProviderList = (props: Props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');
  const debouncedSearch = useDebounce(search, 400);
  const [checkboxIncorrectFlag, setCheckboxIncorrectFlag] = useState(false);
  const [sortBy, setSortBy] = useState<SortingRule<object>>(InitialSortBy);
  const { selectedOrg } = useChooseOrgContext() as OrganizationContext;

  const { data, isLoading, isFetching } = useGetClinicProviderListByOrgV2Query({
    orgId: props.orgId,
    filter: {
      page_number: pageIndex + 1,
      page_size: pageSize,
      search: searchDebounce,
      incorrect_data_flag: checkboxIncorrectFlag,
      sortColumn: sortBy.id,
      sortOrder: sortBy.desc ? 'desc' : 'asc',
    },
  });

  const [entireList] = useLazyGetClinicProviderListByOrgV2Query();

  async function handleExportClick() {
    const lstEntire = await entireList({
      orgId: props.orgId,
      filter: {
        page_number: 1,
        page_size: 1000000,
        search: searchDebounce,
        incorrect_data_flag: checkboxIncorrectFlag,
        sortColumn: sortBy.id,
        sortOrder: sortBy.desc ? 'desc' : 'asc',
      },
    });

    const list = lstEntire.data?.data.map(m =>
      // We reuse table headers so exported will have same arrangement as seen on UI
      ClinicalProviderTableHeader.reduce((prev, next) => {
        if (next.Header && next.accessor) {
          return {
            ...prev,
            [next.Header]: m[next.accessor as keyof ClinicProviderDto],
          };
        }
        return prev;
      }, {})
    );

    return list ?? [];
  }

  const onClinicProviderFilter =
    (setFilter: React.Dispatch<React.SetStateAction<boolean>>) =>
    (isCheck: boolean) => {
      setPageIndex(0);
      setFilter(isCheck);
    };

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    setPageIndex(0);
  }, [debouncedSearch, pageSize, sortBy]);

  return (
    <>
      {data && data.data.length < 1 && (
        <Box>
          <Alert size="sm" bg={appColors.hightLightColor}>
            <AlertIcon />
            <Text fontSize={16} fontWeight="normal">
              No data found.
            </Text>
          </Alert>
        </Box>
      )}
      <StepperProvider>
        <CustomTable
          isLoading={isLoading}
          isFetching={isFetching}
          data={(data && data.data) || []}
          pageCount={(data && data.total_pages) || 0}
          pageSize={(data && data.page_size) || pageSize}
          totalRecords={(data && data.total_records) || 0}
          pageIndex={pageIndex}
          headers={ClinicalProviderTableHeader}
          // @ts-expect-error: please fix if encountered
          initialState={{ sortBy: [InitialSortBy] }}
          exportName={`${selectedOrg?.organization_name}_${Date.now()}`}
          hasFilter
          hasLoadingIndicator
          manual
          manualSortBy
          disableSortRemove
          isPageNumberEditable
          onExportClick={handleExportClick}
          onPageChange={index => {
            if ((data?.total_pages || 1) > index) setPageIndex(index);
          }}
          onPageSizeChange={size => {
            setPageSize(size);
          }}
          onPageSearch={search => {
            setSearch(search);
          }}
          onSort={sort => {
            if (sort[0]) setSortBy(sort[0]);
          }}
        />
      </StepperProvider>
    </>
  );
};

export default ClinicProviderList;
