import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import React, { useEffect } from 'react';
import { useStepperContext } from '../../app/context/StepperContext';

type IStepperActions = {
  prevLabel?: string;
  nextLabel?: string;
  finishLabel?: string;
  onCompleted?: () => void;
  onNextStep: () => void;
};
const StepperActions = ({
  prevLabel,
  nextLabel,
  finishLabel,
  onCompleted,
  onNextStep,
}: IStepperActions) => {
  const { activeStep, nextStep, prevStep, steps, completed } =
    useStepperContext();

  useEffect(() => {
    if (completed && onCompleted) {
      onCompleted();
    }
  }, [completed]);

  return (
    <>
      {steps && steps.length > 0 && (
        <Flex justify="flex-end">
          <Button
            isDisabled={activeStep === 0 || completed}
            mr={4}
            onClick={prevStep}
            size="sm"
            variant="ghost"
          >
            {prevLabel || 'Prev'}
          </Button>
          {onNextStep ? (
            <Button
              colorScheme="brand.main"
              size="sm"
              isLoading={completed}
              onClick={onNextStep}
              variant="solid"
            >
              {activeStep === steps.length - 1
                ? finishLabel || 'Finish'
                : nextLabel || 'Next'}
            </Button>
          ) : (
            <Button
              colorScheme="brand.main"
              size="sm"
              onClick={nextStep}
              variant="solid"
            >
              {activeStep === steps.length - 1
                ? finishLabel || 'Finish'
                : nextLabel || 'Next'}
            </Button>
          )}
        </Flex>
      )}
    </>
  );
};

export default StepperActions;
