import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useEffect } from 'react';

type Props = {
  message: string | null;
  handleClose: () => void;
  handleClick: () => void;
};

const FileShareConfirmModal: React.FC<Props> = ({
  message,
  handleClose,
  handleClick,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (message) onOpen();
    else onClose();
  }, [message, onOpen, onClose]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="md"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Duplicate Upload</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={handleClose} ml="auto">
              Cancel
            </Button>
            <Button colorScheme="brand.main" onClick={handleClick}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileShareConfirmModal;
