import { Validator } from 'use-file-picker';

export const createFormData = (obj: any): FormData => {
  const formData = new FormData();
  Object.keys(obj).forEach((key, index) => {
    if (obj[key] instanceof Array<File>) {
      obj[key].forEach((file: File) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const fileTypeValidator = ({
  acceptedFiles,
  maxFileSize,
}: {
  acceptedFiles: string[];
  maxFileSize: number;
}): Validator => {
  return {
    validateBeforeParsing: async (config, plainFiles) =>
      new Promise((res, rej) => {
        const invalidFiles = plainFiles.filter(f => {
          const selectedFileType = (f.name || '').split('.').pop() || '';
          return !acceptedFiles.includes(selectedFileType);
        });
        if (invalidFiles.length) {
          return rej({
            fileTypeError: `File type${
              invalidFiles.length > 1 ? 's are' : ' is'
            }  not allowed: ${invalidFiles.map(m => m.name).join(', ')}`,
          });
        }
        if (
          config.limitFilesConfig?.max &&
          plainFiles.length > config.limitFilesConfig.max
        ) {
          return rej({
            fileTypeError: `The number of attachment exceeds its limit (${config.limitFilesConfig.max})`,
          });
        }

        const overSizedFiles = plainFiles.filter(f => {
          return f.size > maxFileSize * 1000000;
        });
        if (overSizedFiles.length) {
          return rej({
            fileTypeError: `${
              overSizedFiles.length > 1
                ? 'These files exceed'
                : 'This file exceeds'
            }  the maximum file size limit: ${overSizedFiles
              .map(m => m.name)
              .join(', ')}`,
          });
        }
        return res();
      }),
    validateAfterParsing: async (config, file, reader) =>
      new Promise((res, rej) => {
        res();
      }),
  };
};
