import { defaultErrorMessage } from '@/app/constants';
import { convertUtcToLocal } from '@/app/helpers/dateHelper';
import { debounce } from '@/app/helpers/utilities';
import useIsUserHasRequiredAppAccess from '@/app/hooks/useIsUserHasRequiredRoles';
import {
  useListUserRoleByStatusQuery,
  usePostUserRoleRequestStatusMutation,
} from '@/app/services/provider/api/userRoleRequest';
import { UserRoleRequestModel } from '@/app/services/provider/types';
import CustomTable from '@/components/CustomTable';
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { GoCheck, GoX } from 'react-icons/go';
import { Row } from 'react-table';
import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
  status: Yup.string(),
  deny_reason: Yup.string()
    .label('Reason')
    .when(['status'], {
      is: (status: string) => {
        return status === 'denied';
      },
      then: schema =>
        schema.required().max(1500, 'Text exceed the character limit of 1500'),
      otherwise: schema => schema.optional(),
    }),
});

const AccessApproval = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    'accessapproval.write'
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data, isLoading, isFetching } = useListUserRoleByStatusQuery({
    pagenumber: pageIndex + 1,
    pagesize: 10,
    q: search,
    sort_column: 'ref_role_id',
    sort_order: 'asc',
    status: 'submitted',
  });
  const [postAsync, postDetail] = usePostUserRoleRequestStatusMutation();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      deny_reason: '',
      request: null as UserRoleRequestModel | null,
      status: null as 'approved' | 'denied' | null,
    },
    onSubmit: async (values, form) => {
      if (values.status && values.request) {
        await postAsync({
          status: values.status,
          tracking_notes: values.deny_reason,
          user_role_request_id: values.request.user_role_request_id,
        })
          .unwrap()
          .then(() =>
            toast({
              description: `Successfully ${
                values.status === 'approved' ? 'approved' : 'rejected'
              } request`,
              status: 'success',
            })
          )
          .catch(() =>
            toast({ description: defaultErrorMessage, status: 'error' })
          );
        form.resetForm();
        clearModal();
      }
    },
  });

  const TableHeader = [
    ...(userHasRequiredRole
      ? [
          {
            Header: 'Action',

            Cell: ({
              row: { original },
            }: {
              row: Row<UserRoleRequestModel>;
            }) => {
              return (
                <HStack>
                  <Tooltip label="Approve" openDelay={1000}>
                    <IconButton
                      aria-label="Approve"
                      size="sm"
                      colorScheme="brand.main"
                      icon={<GoCheck />}
                      onClick={() => {
                        setValues({
                          ...values,
                          status: 'approved',
                          request: original,
                        });
                        onOpen();
                      }}
                      isLoading={false}
                      isDisabled={postDetail.isLoading}
                    />
                  </Tooltip>
                  <Tooltip label="Reject" openDelay={1000}>
                    <IconButton
                      aria-label="Reject"
                      size="sm"
                      bg="brand.error"
                      colorScheme="brand.error"
                      icon={<GoX />}
                      onClick={() => {
                        setValues({
                          ...values,
                          status: 'denied',
                          request: original,
                        });
                        onOpen();
                      }}
                      isLoading={false}
                      isDisabled={postDetail.isLoading}
                    />
                  </Tooltip>
                </HStack>
              );
            },
          },
        ]
      : []),
    {
      Header: 'User',
      style: {},
      accessor: 'role_owner_user_name',
    },
    {
      Header: 'Role',
      style: {},
      accessor: 'role_display_name',
    },
    {
      Header: 'Business Justification',
      style: {
        maxWidth: '40%',
        whiteSpace: 'initial',
      },
      accessor: 'business_justification',
    },
    {
      Header: 'Creation Date',
      style: { textAlign: 'center' },
      Cell: ({ row: { original } }: { row: Row<UserRoleRequestModel> }) => {
        return <>{convertUtcToLocal(original.status_datetime_utc) || '-'}</>;
      },
    },
  ];

  const clearModal = () => {
    onClose();
    resetForm();
  };

  return (
    <>
      <VStack w="100%" alignItems="start">
        <HStack flex={1}>
          <Heading size="md" whiteSpace="nowrap">
            Access Approval
          </Heading>
        </HStack>
        <Divider />

        <CustomTable
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.data || []}
          pageCount={(data && data.total_pages) || 0}
          pageSize={pageSize}
          pageIndex={pageIndex}
          totalRecords={data?.total_records || 0}
          headers={TableHeader}
          onPageChange={index => {
            setPageIndex(index);
          }}
          onPageSizeChange={size => {
            setPageIndex(0);
            setPageSize(size);
          }}
          onPageSearch={debounce(search => {
            setPageIndex(0);
            setSearch(search);
          })}
          onSort={() => ({})}
          // onRowClick={() => ({})}
          manual={true}
          options={{ showSkeletonLoading: true }}
        />
      </VStack>

      <Modal isOpen={isOpen} onClose={clearModal}>
        <ModalOverlay />
        {values.request && values.status && (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>
                {values.status === 'approved' ? 'Approve' : 'Reject'}
              </ModalHeader>
              <ModalCloseButton isDisabled={postDetail.isLoading} />
              <ModalBody>
                {values.status === 'approved' ? (
                  <>
                    <Text>
                      Are you sure you want to approve the request by{' '}
                      <Text fontWeight="bold" as="span">
                        {values.request.role_owner_user_name}
                      </Text>
                      ?
                    </Text>
                  </>
                ) : (
                  <>
                    <FormControl
                      isInvalid={!!errors.deny_reason && touched.deny_reason}
                    >
                      <Textarea
                        id="deny_reason"
                        placeholder="Enter Reason"
                        name="deny_reason"
                        onChange={handleChange}
                        value={values.deny_reason}
                        maxLength={1500}
                      />
                      <FormErrorMessage>{errors.deny_reason}</FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={clearModal}
                  mr="3"
                  isDisabled={postDetail.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme={
                    values.status === 'approved' ? 'brand.main' : 'red'
                  }
                  type="submit"
                  isLoading={postDetail.isLoading}
                  isDisabled={postDetail.isLoading}
                >
                  {values.status === 'approved' ? 'Approve' : 'Reject'}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      </Modal>
    </>
  );
};

export default AccessApproval;
