import { providerApi } from './index';
import { FeedbackModel, FeedbackAttachmentModel } from '../types';

const controllerPath = '/feedback';
const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getFeedback: builder.query<Array<FeedbackModel>, void>({
      query: () => `${controllerPath}`,
      providesTags: ['Feedback'],
    }),
    getFeedbackAttachments: builder.query<
      Array<FeedbackAttachmentModel>,
      number
    >({
      query: feedbackId => `${controllerPath}/attachment/${feedbackId}`,
      providesTags: ['FeedbackAttachment'],
    }),
    postFeedback: builder.mutation<void, FormData>({
      query: params => ({
        url: `${controllerPath}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Feedback'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFeedbackQuery,
  useGetFeedbackAttachmentsQuery,
  usePostFeedbackMutation,
} = extendedApi;
