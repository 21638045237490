import { ViewIcon, RepeatIcon } from '@chakra-ui/icons';
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  VStack,
  Flex,
  Box,
  Grid,
  GridItem,
  Tooltip,
  Skeleton,
  Stack,
  Alert,
  AlertIcon,
  Checkbox,
  Spinner,
  HStack,
  Link,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useStepperContext } from '../../app/context/StepperContext';
import { convertUtcToLocal } from '../../app/helpers/dateHelper';
import {
  getClinicProviderNpiExternal,
  usePutClinicProviderMutation,
  useLazyGetClinicProviderIdQuery,
} from '../../app/services/provider/api/clinicProvider';
import { ClinicProviderDto } from '../../app/services/provider/types';
import { useAppSelector } from '../../app/state/hooks';
import { appColors } from '../../app/theme';
import CustomStepper from '../../components/CustomStepper/CustomStepper';
import StepperActions from '../../components/CustomStepper/StepperActions';
import CmsList, { cmsFields } from './CmsModal/CmsList';
import CmsTabs from './CmsModal/CmsTabs';
import IncorrectTagList from './CmsModal/IncorrectTagList';
import NpiList, { npiFields } from './CmsModal/NpiList';

type ViewProviderDetailsButtonProps = {
  clinicProviderId: number;
};

type AlertStatusType = 'success' | 'error' | 'info' | 'warning';

const InstructionText = () => {
  return (
    <Box px={2}>
      <Alert
        bg={appColors.hightLightColor}
        borderRadius={5}
        my={2}
        borderTopWidth={4}
        borderTopColor={appColors.brand.main.default}
      >
        <VStack align="stretch">
          <Text>
            {'If elements of this data are incorrect, visit '}
            <Link
              color={appColors.links}
              href="https://nppes.cms.hhs.gov/IAWeb/login.do"
            >
              https://nppes.cms.hhs.gov/IAWeb/login.do
            </Link>
            {' to update the CMS entry.'}
          </Text>
        </VStack>
      </Alert>
    </Box>
  );
};

export function InfoBox(props: any) {
  return (
    <Box fontSize={16} fontWeight="500" display="flex" justifyContent="start">
      <Text lineHeight="37px">{props.label}</Text>
      &nbsp;&nbsp;
      <Text lineHeight="37px">
        {props.title && props.title.length !== 0 ? props.title : '-'}
      </Text>
    </Box>
  );
}

function ProviderNPI(props: { providerData: ClinicProviderDto }) {
  return (
    <HStack justifyContent="space-between" w="100%" p="5px 10px">
      <InfoBox label="NPI" title={props.providerData.provider_npi} />
      <InfoBox
        label="Data updated as of"
        title={
          props.providerData.npireg_last_processed_datetime_utc
            ? convertUtcToLocal(
                props.providerData.npireg_last_processed_datetime_utc,
                'YYYY-MM-DD'
              )
            : ''
        }
      />
    </HStack>
  );
}

const parseLatestData = (value: ClinicProviderDto, _d: ClinicProviderDto) => {
  return {
    ...value,
    npireg_address_1: _d.npireg_address_1,
    npireg_city: _d.npireg_city,
    npireg_enumeration_date: _d.npireg_enumeration_date,
    npireg_last_processed_datetime_utc: _d.npireg_last_processed_datetime_utc,
    npireg_last_updated: _d.npireg_last_updated,
    npireg_postal_code: _d.npireg_postal_code,
    npireg_state: _d.npireg_state,
    npireg_taxonomy: _d.npireg_taxonomy,
    npireg_telephone_number: _d.npireg_telephone_number,
    provider_enrollment_id: _d.provider_enrollment_id,
    provider_first_name: _d.provider_first_name,
    provider_gender: _d.provider_gender,
    provider_last_name: _d.provider_last_name,
    provider_middle_name: _d.provider_middle_name,
    provider_npi: _d.provider_npi,
    provider_state_cd: _d.provider_state_cd,
    provider_pecos_id: _d.provider_pecos_id,
    provider_provider_type_cd: _d.provider_provider_type_cd,
    provider_provider_type_desc: _d.provider_provider_type_desc,
    provider_org_name: _d.provider_org_name,
    manual_entry_flag: false,
  };
};

const ViewProviderDetailsButton: FC<ViewProviderDetailsButtonProps> = ({
  clinicProviderId,
}) => {
  const alertDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logonUser } = useAppSelector(s => s.user);
  const [providerData, setProviderData] = useState<ClinicProviderDto | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [correctedFlag, setCorrectedFlag] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string | null>();
  const [alertStatus, setAlertStatus] = useState<AlertStatusType>('success');
  const [trigger] = getClinicProviderNpiExternal.useLazyQuery();
  const [showEmptySelectionMessage, setShowEmptySelectionMessage] =
    useState<boolean>(false);

  const [putClinicProviderData, putClinicProviderDataStatus] =
    usePutClinicProviderMutation();
  const [getClinicProviderTrigger] = useLazyGetClinicProviderIdQuery();
  const successMessage = 'Changes was successfully saved.';
  const defaultMessage =
    'Something went wrong, please try again later or contact admin';
  const [selectedIncorrectData, setSelectedIncorrectData] = useState<
    { key: string; label: string; checked: boolean }[]
  >(cmsFields.concat(npiFields));
  const { nextStep, steps, activeStep, reset, setCompleted } =
    useStepperContext();

  const onCheckData = (key: string, checkFlag: boolean) => {
    const newList: any = [...selectedIncorrectData];
    const indexToUpdate = newList.findIndex(
      (f: any) => f.key.toLowerCase() === key.toLowerCase()
    );
    newList[indexToUpdate].checked = checkFlag;
    setSelectedIncorrectData(newList);

    setCorrectedFlag(selectedIncorrectData.some(s => s.checked));
  };

  const resetSelectedIncorrectFields = () => {
    setSelectedIncorrectData([
      ...selectedIncorrectData.map(item => {
        return { ...item, checked: false };
      }),
    ]);
    setCorrectedFlag(false);
  };

  const handleViewProviderDetails = async () => {
    onOpen();
    setIsLoading(true);
    const resp = await getClinicProviderTrigger(clinicProviderId);
    if (resp.isSuccess) {
      setProviderData(resp.data);
      setCorrectedFlag(resp.data.incorrect_data_flag ? true : false);

      const newIncorrectList = [...selectedIncorrectData];
      setSelectedIncorrectData(
        newIncorrectList.map((item: any) => {
          return {
            ...item,
            checked: resp.data.list_incorrect_fields?.includes(item.key),
          };
        })
      );
    }

    if (resp.isError) {
      alertDisclosure.onOpen();
      const errorObj = resp.error as any;
      if (errorObj.status > 200 && errorObj.status < 500)
        setStatusMessage(defaultMessage);
      else setStatusMessage(errorObj.data?.errors);
    }

    if (resp.isError) {
      alertDisclosure.onOpen();
      const errorObj = resp.error as any;
      setAlertStatus('error');
      if (errorObj.status > 200 && errorObj.status < 500)
        setStatusMessage(defaultMessage);
      else setStatusMessage(errorObj.data?.errors);
    }

    setIsLoading(false);
  };

  const onRefresh = () => {
    setIsLoading(true);

    const npi = providerData?.provider_npi || '';
    trigger(npi).then(resp => {
      if (resp.isSuccess) {
        const _d = resp.data;
        setProviderData(value => value && parseLatestData(value, _d));
        setStatusMessage(null);

        if (_d.warning_message) {
          alertDisclosure.onOpen();
          setAlertStatus('warning');
          setStatusMessage(_d.warning_message);
        }
      }

      if (resp.isError) {
        alertDisclosure.onOpen();

        setAlertStatus('error');
        const errorObj = resp.error as any;
        if (errorObj.status > 200 && errorObj.status < 500)
          setStatusMessage(defaultMessage);
        else setStatusMessage(errorObj.data?.errors);
      }

      setIsLoading(false);
    });
  };

  const onUpdateProviderData = async () => {
    if (providerData && !showEmptySelectionMessage) {
      await putClinicProviderData({
        ref_user_id: logonUser?.ref_user_id || 0,
        data: {
          ...providerData,
          incorrect_data_flag: correctedFlag,
          list_incorrect_fields: selectedIncorrectData
            .filter(f => f.checked)
            .map(m => m.key),
        },
      });
    }
  };

  useEffect(() => {
    const isSuccess = putClinicProviderDataStatus.isSuccess;
    const isError = putClinicProviderDataStatus.isError;

    if (isSuccess || isError) {
      alertDisclosure.onOpen();

      if (isError) {
        setAlertStatus('error');
        setCompleted(false);
        const errorObj = putClinicProviderDataStatus.error as any;
        if (errorObj.status > 200 && errorObj.status < 500)
          setStatusMessage(defaultMessage);
        else setStatusMessage(errorObj.data?.error_message);
      }

      if (isSuccess) {
        setAlertStatus('success');
        setStatusMessage(successMessage);
        setCompleted(false);
        setTimeout(() => {
          onModalClose();
        }, 1700);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [
    putClinicProviderDataStatus.isSuccess,
    putClinicProviderDataStatus.isError,
  ]);

  const onModalClose = () => {
    resetSelectedIncorrectFields();
    setStatusMessage(null);
    setCorrectedFlag(false);
    setProviderData(null);
    onClose();
    alertDisclosure.onClose();
    reset();
    setCompleted(false);
    setShowEmptySelectionMessage(false);
    setAlertStatus('success');
  };

  const nextStepClick = () => {
    if (activeStep === steps.length - 1) {
      setCompleted(true);
      onUpdateProviderData();
      return;
    }

    nextStep();
  };

  return (
    <div>
      <Tooltip
        hasArrow
        label="View Provider Details"
        bg="gray.100"
        color="gray.600"
        placement="top"
      >
        <IconButton
          variant="outline"
          colorScheme="#207bbc"
          aria-label="view provider"
          size="sm"
          icon={<ViewIcon />}
          onClick={handleViewProviderDetails}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onModalClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <VStack align="stretch" width="100%" justifyContent="space-between">
            <ModalHeader pb={0}>
              <Flex justifyContent="space-between" marginRight={35}>
                <Text as="span" mr={2}>
                  Provider Details
                </Text>
                <Button
                  leftIcon={<RepeatIcon />}
                  boxShadow="sm"
                  size="sm"
                  onClick={onRefresh}
                  isLoading={isLoading}
                >
                  Refresh data
                </Button>
              </Flex>
            </ModalHeader>
          </VStack>

          <ModalCloseButton />
          <ModalBody>
            {isLoading && (
              <Stack mt={1}>
                {[...Array(3)].map((m, i) => (
                  <Skeleton key={i} height="18px" />
                ))}
              </Stack>
            )}
            {!isLoading && providerData && (
              <CustomStepper>
                <>
                  <CustomStepper.Steps>
                    <CustomStepper.Step id="Step 1">
                      <Flex flexDirection="column" alignItems="center">
                        <Box mt={4}>Check for information - CMS</Box>
                        <InstructionText />
                        <ProviderNPI providerData={providerData} />
                        <Box w="100%">
                          <CmsList
                            cmsData={providerData}
                            onCheckData={onCheckData}
                            checkboxData={selectedIncorrectData}
                          />
                        </Box>
                      </Flex>
                    </CustomStepper.Step>
                    <CustomStepper.Step id="Step 2">
                      <Flex flexDirection="column" alignItems="center">
                        <Box mt={4}>Check for information - NPI</Box>
                        <InstructionText />
                        <ProviderNPI providerData={providerData} />
                        <Box w="100%">
                          <NpiList
                            npiData={providerData}
                            onCheckData={onCheckData}
                            checkboxData={selectedIncorrectData}
                          />
                        </Box>
                      </Flex>
                    </CustomStepper.Step>
                  </CustomStepper.Steps>
                  {/* <Box py={3}>
                    {selectedIncorrectData.some((s) => s.checked) && (
                      <IncorrectTagList
                        list={selectedIncorrectData}
                        onClear={resetSelectedIncorrectFields}
                      />
                    )}
                  </Box> */}
                  {alertDisclosure.isOpen && statusMessage && (
                    <Alert status={alertStatus} mb={3}>
                      <AlertIcon />
                      {statusMessage && Array.isArray(statusMessage) ? (
                        <span>
                          {(statusMessage as any).map(
                            (e: string, i: number) => (
                              <p key={'Key-' + i}>{e}</p>
                            )
                          )}
                        </span>
                      ) : (
                        <span>
                          <p>{statusMessage}</p>
                        </span>
                      )}
                    </Alert>
                  )}

                  <HStack justifyContent="space-between">
                    <InfoBox label="TIN" title={providerData.provider_tin} />
                    <StepperActions
                      prevLabel="Previous"
                      nextLabel="Next"
                      finishLabel="Save"
                      onNextStep={nextStepClick}
                    />
                  </HStack>
                </>
              </CustomStepper>
            )}
          </ModalBody>

          <ModalFooter justifyContent="space-between"></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ViewProviderDetailsButton;
