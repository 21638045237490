import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { AddRoleModel } from '.';
import { ValueOf } from '../../../app/helpers/utilities';
import { FocusEvent } from 'react';

type Props = {
  data: AddRoleModel;
  onChangeData: (
    field: keyof AddRoleModel,
    data: ValueOf<AddRoleModel>,
    isReset?: boolean
  ) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const AddRoleDetails: React.FC<Props> = ({
  data,
  onChangeData,
  errors,
  touched,
  tabErrorIndex,
}) => {
  const onBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AddRoleModel['details']
  ) => {
    data.details &&
      onChangeData('details', {
        ...data.details,
        [field]: e.target.value.trim(),
      });
  };

  return (
    <VStack spacing={5} p={1} pt={3} alignItems="start">
      <VStack w="container.md">
        <FormControl
          isInvalid={
            (!!(
              errors.details &&
              (errors.details as unknown as AddRoleModel['details'])?.roleName
            ) &&
              !!(
                touched.details &&
                (touched.details as unknown as AddRoleModel['details'])
                  ?.roleName
              )) ||
            tabErrorIndex === 0
          }
          display="flex"
        >
          <FormLabel minW="150px">Role Name</FormLabel>
          <VStack w="100%">
            <Input
              size="sm"
              value={data.details.roleName}
              onChange={e =>
                data.details &&
                onChangeData('details', {
                  ...data.details,
                  roleName: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'roleName')}
            />
            <FormErrorMessage alignSelf="start">
              {errors.details?.roleName}
            </FormErrorMessage>
          </VStack>
        </FormControl>
        <FormControl
          isInvalid={
            (!!(
              errors.details &&
              (errors.details as unknown as AddRoleModel['details'])
                ?.roleDisplayName
            ) &&
              !!(
                touched.details &&
                (touched.details as unknown as AddRoleModel['details'])
                  ?.roleDisplayName
              )) ||
            tabErrorIndex === 0
          }
          display="flex"
        >
          <FormLabel minW="150px">Role Display Name</FormLabel>
          <VStack w="100%">
            <Input
              size="sm"
              value={data.details.roleDisplayName}
              onChange={e =>
                data.details &&
                onChangeData('details', {
                  ...data.details,
                  roleDisplayName: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'roleDisplayName')}
            />
            <FormErrorMessage alignSelf="start">
              {errors.details?.roleDisplayName}
            </FormErrorMessage>
          </VStack>
        </FormControl>
        <FormControl
          isInvalid={
            !!(
              errors.details &&
              (errors.details as unknown as AddRoleModel['details'])?.roleDesc
            ) &&
            !!(
              touched.details &&
              (touched.details as unknown as AddRoleModel['details'])?.roleDesc
            )
          }
          display="flex"
        >
          <FormLabel minW="150px">Role Description</FormLabel>
          <VStack w="100%">
            <Textarea
              size="sm"
              value={data.details.roleDesc}
              onChange={e =>
                data.details &&
                onChangeData('details', {
                  ...data.details,
                  roleDesc: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'roleDesc')}
            ></Textarea>
            <FormErrorMessage alignSelf="start">
              {errors.details?.roleDesc}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      </VStack>
    </VStack>
  );
};

export default AddRoleDetails;
