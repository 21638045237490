import { useAccount } from '@azure/msal-react';
import { Alert, AlertIcon, Progress } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  useLazyGetUserByIdQuery,
  useLazyGetUserByUpnQuery,
  usePostUserMutation,
} from '../../app/services/provider/api/user';
import {
  setIsUserOnboard,
  setLogonUser,
  setIsAuthorized,
  setRefUserId,
} from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';

type IProps = {
  children: ReactNode;
};

const AdAuthenticatedView: FC<IProps> = ({ children }) => {
  const account = useAccount();
  const { idTokenClaims } = account as any;
  const { logonUser, isUserOnboard, refUserId } = useAppSelector(s => s.user);
  const dispatch = useAppDispatch();

  const [postAsync] = usePostUserMutation();

  const [triggerGetUserByEmail] = useLazyGetUserByUpnQuery();
  const [triggerGetUserById] = useLazyGetUserByIdQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>('');

  const isNewUser = () => {
    if (idTokenClaims) {
      const { newUser = false } = idTokenClaims;
      return newUser;
    }

    return false;
  };

  const getUser = async (trigger: any, params: any) => {
    setIsLoading(true);
    trigger(params)
      .then((result: any) => {
        if (result.data && result.isSuccess && !result.data.disabled_flag) {
          dispatch(setIsUserOnboard(true));
          dispatch(setIsAuthorized(true));
          dispatch(
            setLogonUser({
              ref_user_id: result.data.ref_user_id,
              user_first_name: result.data.first_name,
              user_last_name: result.data.last_name,
              user_middle_name: result.data.middle_name,
              user_email: result.data.email,
              avatar_url: result.data.avatar_url,
            })
          );
        } else {
          if (!logonUser && !refUserId && !isUserOnboard) {
            dispatch(setIsAuthorized(false));
          }

          setStatus('An error occurred while loading data.');
        }

        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log('Error fetching user demographics.', error);
        setStatus('An error occurred while loading data.');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (idTokenClaims) {
      const upn =
        account?.username ||
        (idTokenClaims.emails && idTokenClaims.emails[0]) ||
        '';
      const { newUser = false, oid = '' } = idTokenClaims;

      if (newUser && !logonUser) {
        postAsync({
          app_guid: process.env.REACT_APP_APP_GUID || '',
          sso_name: process.env.REACT_APP_SSO_NAME || '',
          sso_object_id: oid,
          email: upn,
        })
          .then((resp: any) => {
            dispatch(setIsUserOnboard(true));
            setIsLoading(false);
            if (resp.data) {
              const refUserId = resp.data.ref_user_id.result.ref_user_id;
              dispatch(setRefUserId(refUserId));
              getUser(triggerGetUserById, refUserId);
            } else {
              getUser(triggerGetUserByEmail, { upn: upn });
            }
          })
          .catch((error: any) => {
            console.log('Error add user to pim.', error);
            setStatus('An error occurred while loading data.');
            setIsLoading(false);
          });
      } else {
        getUser(triggerGetUserByEmail, { upn: upn });
      }
    }
  }, []);

  return (
    <>
      {isNewUser() ? (
        <>{children}</>
      ) : (
        <>
          {isUserOnboard ? (
            <>{children}</>
          ) : (
            <>
              {isLoading ? (
                <Progress size="xs" isIndeterminate colorScheme="brand" />
              ) : (
                <Alert status="warning">
                  <AlertIcon />
                  {status}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdAuthenticatedView;
