import { providerApi } from '.';
import {
  AddOrganizationFolderDto,
  GetContentsByOrganizationModel,
  OrganizationFolderModel,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    addOrganizationFolder: builder.mutation<
      { status: number; message: string },
      AddOrganizationFolderDto
    >({
      query: body => ({
        url: '/organizationfolder',
        method: 'POST',
        body,
      }),
    }),
    getRootFoldersByOrganization: builder.query<
      OrganizationFolderModel[],
      number
    >({
      query: organization_id =>
        `/organizationfolder/root/list/${organization_id}`,
    }),
    getContentsByOrganization: builder.query<
      GetContentsByOrganizationModel,
      number
    >({
      query: organization_folder_id =>
        `/organizationfolder/contents/${organization_folder_id}`,
    }),
    deleteOrganizationFolder: builder.mutation<
      { message: string; status: number },
      number
    >({
      query: organization_folder_id => ({
        url: `/organizationfolder/${organization_folder_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddOrganizationFolderMutation,
  useGetRootFoldersByOrganizationQuery,
  useLazyGetRootFoldersByOrganizationQuery,
  useGetContentsByOrganizationQuery,
  useLazyGetContentsByOrganizationQuery,
  useDeleteOrganizationFolderMutation,
} = extendedApi;
