import { UseToastOptions } from '@chakra-ui/react';

export const DeviceOrderStatus = {
  created: 1,
  approved: 2,
  preparingForShipment: 3,
  cancelled: 4,
  declined: 5,
  emr: 6,
};

export const DefaultToastOptions: UseToastOptions = {
  position: 'top-right',
  duration: 3000,
  variant: 'left-accent',
  isClosable: true,
};

export const defaultErrorMessage = `Something went wrong, please try again later or contact admin`;

export const fileConfig = {
  acceptedFiles: (process.env.REACT_APP_FEEDBACK_FILETYPES || '').split(','),
  maxFileSize: process.env.REACT_APP_FEEDBACK_MAX_FILE_SIZE,
  maxFileCount: process.env.REACT_APP_FEEDBACK_MAX_FILE_COUNT,
};