import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { BsPencilFill } from 'react-icons/bs';
import { useUserRoleContext } from '../../app/context/UserRoleContext';
import { usePutOrganizationMutation } from '../../app/services/provider/api/organization';
import {
  PutOrganizationDto,
  UserRoleAppAccessByEmailModel,
} from '../../app/services/provider/types';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import OrganizationFormModal from './OrganizationFormModal';

type IProps = {
  initialValues: PutOrganizationDto;
};

const EditOrganizationButton: FC<IProps> = (props: IProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [initialValues, setInitialValues] = useState(props.initialValues);
  const [putAsync, { isLoading, isSuccess, isError, error }] =
    usePutOrganizationMutation();
  const { userAppAccess } = useUserRoleContext();

  const userHasRequiredRole = userAppAccess.some(
    (s: UserRoleAppAccessByEmailModel) =>
      AppAccessAuth.organizationWrite.includes(s.app_access_name as any)
  );

  const onSubmit = (values: PutOrganizationDto) => {
    putAsync(values);
  };
  useEffect(() => {
    setInitialValues(props.initialValues);
  }, [props.initialValues]);

  return (
    <>
      {userHasRequiredRole ? (
        <>
          <Tooltip
            hasArrow
            label="Edit"
            bg="gray.100"
            color="gray.600"
            placement="top"
          >
            <IconButton
              minWidth={1}
              variant="link"
              aria-label="Edit"
              icon={<BsPencilFill />}
              onClick={onOpen}
            />
          </Tooltip>

          <OrganizationFormModal
            mode="Edit"
            modalTitle="Edit Organization"
            isOpen={isOpen}
            onClose={onClose}
            submitButtonLabel="Update"
            successMessage="Successfully updated organization"
            initialValues={initialValues}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default EditOrganizationButton;
