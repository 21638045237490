import { convertUtcToLocal } from '@/app/helpers/dateHelper';
import { useLazyGetUserRoleListByRefRoleIdQuery } from '@/app/services/provider/api/userRole';
import {
  RefRoleUserSummaryDto,
  UsersByRefRoleIdDto,
} from '@/app/services/provider/types';
import { ViewIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';

type UserListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isGettingUserList: boolean;
  userList: UsersByRefRoleIdDto[] | undefined;
};

const UsersTable: FC<{ userList: UserListModalProps['userList'] }> = ({
  userList,
}) => {
  return (
    <TableContainer overflowY="scroll">
      <Table variant="simple" layout="fixed">
        <Thead>
          <Tr>
            <Th style={{ width: '21%' }}>USERS</Th>
            <Th>DATE ASSIGNED</Th>
            <Th>ASSIGNED BY</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userList?.map(user => (
            <Tr key={`${user.user}-${user.date_assigned_utc}`}>
              <Td whiteSpace="break-spaces">{user.user}</Td>
              <Td whiteSpace="break-spaces">
                {convertUtcToLocal(
                  user.date_assigned_utc,
                  'MMMM D, YYYY h:mm A'
                ) || '-'}
              </Td>
              <Td whiteSpace="break-spaces">{user.assigned_by}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
const UserListModal: FC<UserListModalProps> = ({
  isOpen,
  onClose,
  isGettingUserList,
  userList,
}) => {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size="6xl"
    >
      <AlertDialogOverlay />

      <AlertDialogContent h={'70vh'}>
        <AlertDialogHeader>Users</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDir="column"
          h="100%"
          pt="18px"
          overflow="hidden"
        >
          {isGettingUserList ? <Spinner /> : <UsersTable userList={userList} />}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const UserCount = ({
  row: { original },
}: {
  row: { original: RefRoleUserSummaryDto };
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userList, setUserList] = useState<UsersByRefRoleIdDto[]>();
  const [isGettingUserList, setIsGettingUserList] = useState(false);
  const [getUserList] = useLazyGetUserRoleListByRefRoleIdQuery();

  const onClickEye = async () => {
    onOpen();
    try {
      setIsGettingUserList(true);
      const users = await getUserList(original.ref_role_id);

      setUserList(users.data);
      setIsGettingUserList(false);
    } catch (e) {
      setIsGettingUserList(false);
      console.log('error getting user list', e);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Text minW="9">{original.user_count}</Text>
      <IconButton
        onClick={onClickEye}
        aria-label="Peek user list"
        boxSize="30px"
        icon={<ViewIcon mt={1} />}
      />
      <UserListModal
        isOpen={isOpen}
        onClose={onClose}
        isGettingUserList={isGettingUserList}
        userList={userList}
      />
    </Box>
  );
};

export default UserCount;
