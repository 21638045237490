import {
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { ClinicProviderDto } from '../../../app/services/provider/types';

export const npiFields = [
  {
    key: 'npireg_address_1',
    label: 'Address',
    checked: false,
  },
  {
    key: 'npireg_city',
    label: 'City',
    checked: false,
  },
  {
    key: 'npireg_state',
    label: 'State',
    checked: false,
  },
  {
    key: 'npireg_postal_code',
    label: 'Postal Code',
    checked: false,
  },
  {
    key: 'npireg_taxonomy',
    label: 'Taxonomy (Specialty)',
    checked: false,
  },
  {
    key: 'npireg_enumeration_date',
    label: 'Enumeration Date (NPI assigned)',
    checked: false,
  },
  {
    key: 'npireg_last_updated',
    label: 'Last Updated',
    checked: false,
  },
  {
    key: 'npireg_telephone_number',
    label: 'Telephone',
    checked: false,
  },
];

type IProps = {
  npiData: ClinicProviderDto;
  onCheckData: (str: string, checkFlag: boolean) => void;
  checkboxData: Array<{ key: string; label: string; checked: boolean }>;
};

const NpiList: FC<IProps> = ({ npiData, onCheckData, checkboxData }) => {
  const formatText = (text: string | undefined) => {
    if (text) {
      return text;
    }

    return '-';
  };
  return (
    <>
      <TableContainer>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_address_1", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "npireg_address_1")?.checked}
                > */}
                <Text fontSize="xs">Address</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(npiData.npireg_address_1)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_enumeration_date", e.target.checked)}
                  isChecked={
                    checkboxData.find((f) => f.key === "npireg_enumeration_date")?.checked
                  }
                > */}
                <Text fontSize="xs">Enumeration Date (NPI assigned)</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>
                {convertUtcToLocal(
                  npiData?.npireg_enumeration_date,
                  'YYYY-MM-DD'
                )}
              </Td>
            </Tr>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_city", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "npireg_city")?.checked}
                > */}
                <Text fontSize="xs">City</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(npiData.npireg_city)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_last_updated", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "npireg_last_updated")?.checked}
                > */}
                <Text fontSize="xs">Last Updated</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>
                {convertUtcToLocal(npiData?.npireg_last_updated, 'YYYY-MM-DD')}
              </Td>
            </Tr>

            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_state", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "npireg_state")?.checked}
                > */}
                <Text fontSize="xs">State</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(npiData.npireg_state)}</Td>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_telephone_number", e.target.checked)}
                  isChecked={
                    checkboxData.find((f) => f.key === "npireg_telephone_number")?.checked
                  }
                > */}
                <Text fontSize="xs">Telephone</Text>
                {/* </Checkbox> */}
              </Th>
              <Td>{formatText(npiData.npireg_telephone_number)}</Td>
            </Tr>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_postal_code", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "npireg_postal_code")?.checked}
                > */}
                <Text fontSize="xs">Postal Code</Text>
                {/* </Checkbox> */}
              </Th>
              <Td colSpan={3}>{formatText(npiData.npireg_postal_code)}</Td>
            </Tr>
            <Tr>
              <Th>
                {/* <Checkbox
                  size="md"
                  onChange={(e) => onCheckData("npireg_taxonomy", e.target.checked)}
                  isChecked={checkboxData.find((f) => f.key === "npireg_taxonomy")?.checked}
                > */}
                <Text fontSize="xs">Taxonomy (Specialty)</Text>
                {/* </Checkbox> */}
              </Th>
              <Td colSpan={3}>{formatText(npiData.npireg_taxonomy)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default NpiList;
