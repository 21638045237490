import { useColorModeValue, Stack, Text } from '@chakra-ui/react';

const Footer = () => {
  // const location = useLocation();
  return (
    <>
      <Stack
        h={63}
        w="100%"
        // mt={isCommunityPage(location) ? 0 : 9}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justifyContent="center"
        alignItems="center"
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}
      >
        <Text fontSize="sm">
          &copy; {new Date().getFullYear()} Provider Portal
        </Text>
      </Stack>
    </>
  );
};

export default Footer;
