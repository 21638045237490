import { providerApi } from './index';
import {
  GetOrganizationDocumentListDto,
  GetOrganizationDocumentListModel,
} from '../types';
import { createFormData } from '../../../helpers/fileHelper';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getOrganizationDocumentList: builder.query<
      GetOrganizationDocumentListModel,
      GetOrganizationDocumentListDto
    >({
      query: ({ organization_id, filter }) => ({
        url: `/organizationdocument/organization/${organization_id}??pagenumber=${filter.page_number}&pagesize=${filter.page_size}&q=${filter.search}&sort_order=asc&sort_column=document_filename`,
        method: 'GET',
      }),
    }),
    getOrganizationDocument: builder.query<
      string,
      { organization_id: number; filename: string }
    >({
      query: ({ organization_id, filename }) => ({
        url: `/organizationdocument/download/organization/${organization_id}/file/${filename}`,
        method: 'GET',
      }),
    }),
    postOrganizationDocumentUpload: builder.mutation<
      void,
      {
        organization_id: number;
        organization_folder_id: number | null;
        document: File;
      }
    >({
      query: params => ({
        url: '/organizationdocument/upload',
        method: 'POST',
        body: createFormData(params),
      }),
    }),
    deleteOrganizationDocument: builder.mutation<void, number>({
      query: organization_document_id => ({
        url: `/organizationdocument/${organization_document_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetOrganizationDocumentListQuery,
  useLazyGetOrganizationDocumentQuery,
  usePostOrganizationDocumentUploadMutation,
  useDeleteOrganizationDocumentMutation,
} = extendedApi;
