import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { BsFileEarmark } from 'react-icons/bs';
import { Row, UseTableColumnProps } from 'react-table';
import { useFilePicker, Validator } from 'use-file-picker';
import { fileConfig } from '../../app/constants';

type Props = {
  name?: string;
  value: any;
  row: Row;
  column: UseTableColumnProps<any>;
  updateData: (
    row: number,
    col: string,
    value: string | File | number | number[],
    fileColumnName?: string
  ) => void;
  type?: React.HTMLInputTypeAttribute;
  dropdownOptions?: Array<{
    text: string;
    value: string;
    isDisabled?: boolean;
  }>;
  fileColumnName?: string;
  errors?: any;
  touched?: any;
  isInvalid?: boolean;
};
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  type,
  dropdownOptions,
  fileColumnName,
  name,
  errors,
  touched,
  isInvalid,
}: Props) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target?.value || e);
  };

  const onChangeDropdown = (e: any) => {
    updateData(index, id, e ? parseInt(e) : e);
  };

  const onChangeDropdownMultiple = (e: number[]) => {
    updateData(index, id, e);
  };

  const onBlur = (e: any) => {
    const finalVal = value.trim();
    setValue(finalVal);
    updateData(index, id, finalVal);
  };

  const onSelectFile = (file: File) => {
    updateData(index, id, file, fileColumnName);
  };

  const [openFileSelector, filePicker] = useFilePicker({
    multiple: false,
    accept: fileConfig.acceptedFiles.map((m: any) => '.' + m),
    limitFilesConfig: { max: parseInt(fileConfig.maxFileCount || '0') },
    maxFileSize: parseInt(fileConfig.maxFileSize || '0'),
    readFilesContent: false,
    validators: [fileTypeValidator],
  });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (filePicker.plainFiles.length > 0) {
      onSelectFile(filePicker.plainFiles[0]);
    }
  }, [filePicker.plainFiles]);

  let component = <></>;
  switch (type) {
    case 'file':
      component = (
        <InputGroup size="sm">
          <Input value={value || ''} readOnly />
          <InputRightElement>
            <IconButton
              mr="3px"
              variant="solid"
              icon={<BsFileEarmark />}
              h="1.75rem"
              size="sm"
              onClick={openFileSelector}
              aria-label={'Choose File'}
            />
          </InputRightElement>
        </InputGroup>
      );
      break;
    case 'textarea':
      component = (
        <FormControl minW="200px" isInvalid={isInvalid}>
          <Textarea
            name={name}
            id={name}
            rows={3}
            minW="200px"
            size="sm"
            value={value || ''}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
          ></Textarea>
        </FormControl>
      );
      break;
    case 'dropdown':
      component = (
        <FormControl minW="200px" isInvalid={isInvalid}>
          <Select
            size="sm"
            useBasicStyles
            value={
              dropdownOptions
                ? {
                    label: dropdownOptions.find(
                      f => f.value.toString() === value.toString()
                    )?.text,
                    value: dropdownOptions.find(
                      f => f.value.toString() === value.toString()
                    )?.value,
                  }
                : {}
            }
            options={
              dropdownOptions
                ? dropdownOptions.map(m => {
                    return {
                      label: m.text,
                      value: m.value,
                      isDisabled: m.isDisabled,
                    };
                  })
                : undefined
            }
            onChange={e => {
              onChangeDropdown(e?.value);
            }}
            maxMenuHeight={250}
            menuPortalTarget={document.body}
          />
        </FormControl>
      );
      break;
    case 'multiple-dropdown':
      // eslint-disable-next-line no-case-declarations
      const multiValues =
        dropdownOptions
          ?.filter(f => value.includes(+f.value))
          .map(m => ({
            label: m.text,
            value: m.value,
          })) || [];
      component = (
        <FormControl minW="200px">
          <Select
            isMulti
            size="sm"
            useBasicStyles
            value={multiValues}
            options={
              dropdownOptions
                ? dropdownOptions.map(m => {
                    return {
                      label: m.text,
                      value: m.value,
                      isDisabled: m.isDisabled,
                    };
                  })
                : undefined
            }
            onChange={e => {
              onChangeDropdownMultiple(e?.map(m => +m.value) || []);
            }}
            maxMenuHeight={500}
            menuPortalTarget={document.body}
          />
        </FormControl>
      );
      break;
    default:
      component = (
        <FormControl isInvalid={isInvalid}>
          <Input
            id={name}
            name={name}
            size="sm"
            value={value || ''}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
          />
        </FormControl>
      );
      break;
  }

  return component;
};

export default EditableCell;

const fileTypeValidator: Validator = {
  validateBeforeParsing: async (config, plainFiles) =>
    new Promise((res, rej) => {
      const invalidFiles = plainFiles.filter(f => {
        const selectedFileType = (f.name || '').split('.').pop() || '';
        return !fileConfig.acceptedFiles.includes(selectedFileType);
      });
      if (invalidFiles.length) {
        return rej({
          fileTypeError: `File type${
            invalidFiles.length > 1 ? 's are' : ' is'
          }  not allowed: ${invalidFiles.map(m => m.name).join(', ')}`,
        });
      }
      if (
        config.limitFilesConfig?.max &&
        plainFiles.length > config.limitFilesConfig.max
      ) {
        return rej({
          fileTypeError: `The number of attachment exceeds its limit (10)`,
        });
      }

      const overSizedFiles = plainFiles.filter(f => {
        return f.size > parseInt(fileConfig.maxFileSize || '0') * 1e6;
      });
      if (overSizedFiles.length) {
        return rej({
          fileTypeError: `${
            overSizedFiles.length > 1
              ? 'These files exceed'
              : 'This file exceeds'
          }  the maximum file size limit: ${overSizedFiles
            .map(m => m.name)
            .join(', ')}`,
        });
      }
      return res();
    }),
  validateAfterParsing: async (config, file, reader) =>
    new Promise((res, rej) => {
      res();
    }),
};
