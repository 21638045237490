import {
  Heading,
  VStack,
  Text,
  Button,
  Progress,
  Link,
  Flex,
  Alert,
  AlertIcon,
  ButtonGroup,
  Image,
} from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useChooseOrgContext } from '../../app/context/ChooseOrgContext';
import {
  LOCALSTORAGE_KEYS,
  LOCALSTORAGE_VALUES,
  saveState,
} from '../../app/helpers/localStorageHelper';
import {
  useGetOrganizationAcknowledgementByRefuserIdQuery,
  usePostOrganizationAcknowledgementMutation,
} from '../../app/services/provider/api/organizationAcknowledgement';
import { setIsUserConsent } from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';

type IProps = {
  children: ReactNode;
};

const ConsentedView: FC<IProps> = ({ children }: IProps) => {
  const { logonUser, userConsents } = useAppSelector(s => s.user);
  const dispatch = useAppDispatch();
  const { clinicProvider } = LOCALSTORAGE_VALUES;
  const { selectedOrg, setSelectedOrg, userHasMultipleOrganization } =
    useChooseOrgContext();
  const [isConsented, setIsConsented] = useState(false);

  const { data, isError, isSuccess, error, isLoading } =
    useGetOrganizationAcknowledgementByRefuserIdQuery(
      logonUser?.ref_user_id || 0
    );

  const [
    postAsync,
    {
      isLoading: isPostLoading,
      isSuccess: isPostSuccess,
      isError: isPostError,
      error: postError,
    },
  ] = usePostOrganizationAcknowledgementMutation();

  const [isShowConsent, setIsShowConsent] = useState(false);

  useEffect(() => {
    if (!isLoading && data) {
      const uconsents = {
        ...userConsents,
      };

      if (data.length === 0) {
        setIsConsented(false);
        setIsShowConsent(true);
      } else {
        data.forEach((oc: any) => {
          uconsents[oc.user_organization_id] = true;
        });

        dispatch(setIsUserConsent(uconsents));

        const isConsented = data.some(
          (f: any) =>
            f.user_organization_id === selectedOrg?.user_organization_id
        );

        setIsConsented(isConsented);
        setIsShowConsent(!isConsented);
      }
    }

    if (isError) {
      console.log('ConsentedView get', { error });
    }
  }, [data, isError, isSuccess, isError, error, isLoading]);

  useEffect(() => {
    if (isPostSuccess) {
      setIsShowConsent(false);
      setIsConsented(true);
    }
    if (isPostError) {
      console.log('ConsentedView post', { postError });
    }
  }, [isPostLoading, isPostSuccess, isPostError, postError]);

  const onConsentClick = async () => {
    await postAsync({
      refUserId: logonUser?.ref_user_id || 0,
      organizationId: selectedOrg?.organization_id,
    });
  };

  const cancelClick = () => {
    saveState(
      {
        ...clinicProvider,
        selectedOrg: null,
      },
      LOCALSTORAGE_KEYS.clinicProvder
    );
    setSelectedOrg(null);
  };

  return (
    <>
      {isConsented ? (
        <>{children}</>
      ) : !isShowConsent ? (
        <Progress size="xs" isIndeterminate colorScheme="brand" />
      ) : (
        <Flex maxW="900px" mx="auto" flexDirection="column" alignItems="center">
          <Heading mb={9}>Acknowledgement</Heading>
          {(isError || isPostError) && (
            <Alert status="warning" mb={3}>
              <AlertIcon />
              Your account was not setup. Please contact admin.
            </Alert>
          )}
          <ConsentContent />

          <VStack spacing={7}>
            {/* <FormControl as="fieldset" textAlign="center">
              <Checkbox
                disabled={!logonUser?.ref_user_id}
                isChecked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              >
                I agree
              </Checkbox>
            </FormControl> */}
            {userHasMultipleOrganization ? (
              <ButtonGroup>
                <Button
                  onClick={cancelClick}
                  variant="outline"
                  colorScheme="brand.main"
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="brand.main"
                  disabled={isPostLoading || isLoading}
                  isLoading={isPostLoading || isLoading}
                  onClick={onConsentClick}
                >
                  Home
                </Button>
              </ButtonGroup>
            ) : (
              <Button
                colorScheme="brand.main"
                disabled={isPostLoading || isLoading}
                isLoading={isPostLoading || isLoading}
                onClick={onConsentClick}
              >
                Home
              </Button>
            )}
            <Image
              src="assets/images/lore-logo-med.png"
              alt="Lore Healthcare logo"
              w="auto"
              h="70"
            />
          </VStack>
        </Flex>
      )}
    </>
  );
};

const ConsentedViewNewUser: FC<IProps> = () => {
  const { refUserId, logonUser, userConsents } = useAppSelector(s => s.user);
  const [userId, setUserId] = useState<number | null>(null);
  const [isConsented, setIsConsented] = useState(false);
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetOrganizationAcknowledgementByRefuserIdQuery(
    logonUser?.ref_user_id || 0
  );

  const [
    postAsync,
    {
      isLoading: isPostLoading,
      isSuccess: isPostSuccess,
      isError: isPostError,
      error: postError,
    },
  ] = usePostOrganizationAcknowledgementMutation();

  useEffect(() => {
    if (isPostSuccess) {
      setIsConsented(true);
    }
    if (isPostError) {
      console.log('ConsentedView post', { postError });
    }
  }, [isPostLoading, isPostSuccess, isPostError, postError]);

  const onConsentClick = async () => {
    await postAsync({
      refUserId: refUserId || logonUser?.ref_user_id || 0,
      organizationId: 1,
    });
  };

  useEffect(() => {
    if (refUserId) {
      setUserId(refUserId);
    }

    if (!refUserId && logonUser?.ref_user_id) {
      setUserId(logonUser?.ref_user_id);
    }

    return () => {
      setUserId(null);
    };
  }, [refUserId, logonUser?.ref_user_id]);

  useEffect(() => {
    if (!isLoading && data) {
      const uconsents = {
        ...userConsents,
      };

      data.forEach((oc: any) => {
        uconsents[oc.user_organization_id] = true;
      });

      dispatch(setIsUserConsent(uconsents));
    }
  }, [isConsented]);

  return (
    <>
      {!isConsented && !isPostLoading && !isLoading && (
        <Flex maxW="900px" mx="auto" flexDirection="column" alignItems="center">
          <Heading mb={9}>User Consent</Heading>
          {isPostError && (
            <Alert status="warning" mb={3}>
              <AlertIcon />
              There's an error encountered, while processing you request. Please
              contact admin.
            </Alert>
          )}
          <ConsentContent />
          <VStack spacing={7}>
            {/* <FormControl as="fieldset" textAlign="center">
              <Checkbox
                disabled={isPostLoading || !userId}
                isChecked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              >
                I agree
              </Checkbox>
            </FormControl> */}
            <Button
              colorScheme="brand"
              disabled={isPostLoading || isLoading}
              isLoading={isPostLoading || isLoading}
              onClick={onConsentClick}
            >
              Home
            </Button>
            <Image
              src="/images/lore-logo-med.png"
              alt="Lore Healthcare logo"
              w={100}
              h={100}
            />
          </VStack>
        </Flex>
      )}
    </>
  );
};

export { ConsentedViewNewUser, ConsentedView };

const ConsentContent = () => {
  return (
    <VStack spacing={9} mb="90px">
      {/* <Text fontSize="sm">
        As a representative of the provider associated with the logged-in user, I consent to use
        this portal for entering information for Appendix 1 of the Lore Health ACO – Provider
        Memorandum of Understanding agreement. I agree to use this online portal for entering data
        that will become part of the Lore Health ACO – Provider Memorandum of Understanding
        agreement.
      </Text>

      <Text fontSize="sm">
        Note: If you are not willing to consent to using this portal for completing Appendix 1 of
        the Lore Health ACO – Provider Memorandum of Understanding agreement, please contact Mark
        Briesacher (
        <Link href="mailto:mbriesacher@sequel.ae" color="blue">
          mbriesacher@sequel.ae
        </Link>
        ) or Mark Atalla (
        <Link href="mailto:matalla@sequel.ae" color="blue">
          matalla@sequel.ae
        </Link>
        ) for alternative methods. If you are not able to complete Appendix 1 of the agreement, the
        agreement will be voided.
      </Text> */}

      <Text fontSize="sm">
        The Lore Health Provider Portal contains Protected Health Information
        and other protected or confidential information. By accessing the
        provider portal, you acknowledge you will maintain the privacy and
        confidentiality of all information contained within in accordance with
        Federal privacy and all other applicable laws. Any breaches should be
        reported to{' '}
        <Link href="compliance@lorehealthcare.com" color="blue">
          compliance@lorehealthcare.com
        </Link>{' '}
        when discovered.
      </Text>
    </VStack>
  );
};
