import { IconButton } from '@chakra-ui/button';
import { Stack, HStack, Heading, Box } from '@chakra-ui/layout';
import { FC } from 'react';
import { CgClose } from 'react-icons/cg';

type IPanelCardProps = {
  children?: JSX.Element | string;
  header?: JSX.Element | string;
  fullHeight?: boolean;
  onClose?: () => void;
};
export const PanelCard: FC<IPanelCardProps> = ({
  children,
  header,
  fullHeight,
  onClose,
}) => {
  return (
    <Box
      p={5}
      shadow="md"
      border="1px"
      borderColor="gray.200"
      borderRadius={3}
      height={fullHeight ? '100%' : ''}
    >
      <Stack direction="column">
        <HStack justifyContent="space-between">
          <Heading as="h5" size="md" mb={2}>
            {header}
          </Heading>
          {onClose && (
            <IconButton
              variant="link"
              colorScheme="gray"
              size="md"
              aria-label="Close"
              icon={<CgClose />}
              title="Close"
              onClick={onClose}
            />
          )}
        </HStack>
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
};
