import { SearchIcon } from '@chakra-ui/icons';
import {
  Badge,
  Container,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Skeleton,
  Stack,
  Text,
  VStack,
  theme,
} from '@chakra-ui/react';
import { FC, Fragment, memo, useEffect, useMemo, useState } from 'react';
import {
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
  CgChevronLeft,
  CgChevronRight,
} from 'react-icons/cg';
import {
  Column,
  RowPropGetter,
  SortingRule,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { appColors } from '../../app/theme';

type IProps = {
  data: any[];
  hidePagination?: boolean;
  hideRowsPerPage?: boolean;
  hideDisplayRecords?: boolean;
  pageCount: number;
  pageSize: number;
  totalRecords?: number;
  isLoading: boolean;
  isFetching: boolean;
  headers: Column<any>[];
  hiddenColumns?: string[];
  hasFilter?: boolean;
  filterElement?: JSX.Element;
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange?: (size: number) => void;
  onPageSearch: (search: string) => void;
  onSort: (sort: SortingRule<object>[]) => void;
  onRowClick?: (row: any) => void;
  getRowProps?: (row: any) => RowPropGetter<object> | undefined;
  search: string;
  rowDisabledOnPropertyTrue?: string;
  activeRow?: { property: string; value: any };
};

const GridView: FC<IProps> = props => {
  const columns = useMemo<Column<any>[]>(() => props.headers, []);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [search, setSearch] = useState('');

  const {
    // @ts-expect-error: please fix if encountered
    page,
    prepareRow,
    // @ts-expect-error: please fix if encountered
    gotoPage,
    // @ts-expect-error: please fix if encountered
    state: { pageIndex, pageSize, sortBy },
    // @ts-expect-error: please fix if encountered
    nextPage,
    // @ts-expect-error: please fix if encountered
    previousPage,
    // @ts-expect-error: please fix if encountered
    canNextPage,
    // @ts-expect-error: please fix if encountered
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        // @ts-expect-error: please fix if encountered
        pageIndex: 0,
        pageSize: 18,
        hiddenColumns: props.hiddenColumns || [],
      },
      pageCount: tablePageCount,
      manualPagination: true,
      autoResetPage: false, //prevent re-executing hook with initialState again
      manualSortBy: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  const keyUpHandler = (event: any) => {
    event.preventDefault();
    gotoPage(0);
    props.onPageSearch(search);
  };

  useEffect(() => {
    setSearch(props.search);
  }, []);

  useEffect(() => {
    setTableData(props.data);
    setTablePageCount(props.pageCount);
  }, [props]);

  useEffect(() => {
    const index = pageIndex === 0 ? 1 : pageIndex + 1;
    props.onPageChange(index);
    !props.isFetching && props.onSort(sortBy);
  }, [pageIndex, pageSize, sortBy]);

  return (
    <VStack>
      {!props.hidePagination && (
        <Flex justifyContent="space-between" mb={2} p={0} w="100%">
          <HStack w="100%">
            {props.hideDisplayRecords && (
              <Badge alignItems="center" display="flex" px="2" py="1">
                {props.totalRecords || 0} record
                {(props.totalRecords || 0) > 1 && <>s</>}
              </Badge>
            )}
            <InputGroup justifyContent="flex-end">
              <Input
                id="search"
                placeholder="search"
                name="search"
                w="60%"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyUp={keyUpHandler}
              />
              <InputRightElement
                children={
                  <SearchIcon
                    className="SearchIcon"
                    color={appColors.brand.main.default}
                  />
                }
              />
            </InputGroup>
          </HStack>
        </Flex>
      )}

      {props.isLoading || props.isFetching ? (
        <Stack mt={1} w="100%">
          {[...Array(props.pageSize)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      ) : (
        <>
          <Container p={0} maxWidth="100%">
            <List spacing={2}>
              {
                // @ts-expect-error: please fix if encountered
                page.map((row, index) => {
                  prepareRow(row);

                  const rowProps = {
                    ...row.getRowProps(
                      props.getRowProps ? props.getRowProps(row) : undefined
                    ),
                  };

                  const rowStyle = rowProps.style || {};
                  if (
                    row.original[
                      props.rowDisabledOnPropertyTrue as keyof object
                    ]
                  ) {
                    rowStyle.borderLeftColor = appColors.inactive;
                  }
                  if (props.activeRow) {
                    if (
                      (
                        (row.original[
                          props.activeRow.property as keyof object
                        ] || '') as any
                      ).toString() === (props.activeRow.value || '').toString()
                    ) {
                      rowStyle.boxShadow = theme.shadows.md;
                      rowStyle.background = appColors.activeRow;
                    }
                  }

                  rowProps.style = rowStyle;

                  return (
                    <ListItem key={index}>
                      <Stack
                        p={2}
                        width="100%"
                        border="1px"
                        borderLeft={'8px'}
                        borderColor="gray.200"
                        borderLeftColor="brand.main.default"
                        {...rowProps}
                      >
                        <HStack alignItems="start">
                          <Stack flexGrow={1} wordBreak="break-all">
                            {row.cells.map((cell: any, i: number) => {
                              const isAction = (cell.column as any).isAction;
                              return (
                                !isAction && (
                                  <Flex key={i}>
                                    <Text
                                      color="gray.600"
                                      as="strong"
                                      pr={2}
                                      whiteSpace="nowrap"
                                    >
                                      {cell.column.render('Header')}:
                                    </Text>
                                    <Text borderRightColor="gray.200">
                                      {cell.render('Cell')}
                                    </Text>
                                  </Flex>
                                )
                              );
                            })}
                          </Stack>
                          <Stack>
                            {row.cells.map((cell: any, ii: number) => {
                              const isAction = (cell.column as any).isAction;
                              return (
                                isAction && (
                                  <Fragment key={ii}>
                                    {cell.render('Cell')}
                                  </Fragment>
                                )
                              );
                            })}
                          </Stack>
                        </HStack>
                      </Stack>
                    </ListItem>
                  );
                })
              }
            </List>
          </Container>
        </>
      )}

      <Stack>
        {!props.isLoading && page.length === 0 ? (
          <></>
        ) : (
          <Flex justifyContent="end" alignItems="center" mt={4}>
            <HStack hidden={props.hidePagination}>
              <IconButton
                aria-label="first page"
                onClick={() => {
                  gotoPage(0);
                }}
                disabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleLeft />}
              />
              <IconButton
                aria-label="previous page"
                onClick={previousPage}
                disabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronLeft />}
              />
              <Text>{pageIndex + 1}</Text>
              <IconButton
                aria-label="next page"
                onClick={() => {
                  console.log('next page');
                  nextPage();
                }}
                disabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronRight />}
              />
              <IconButton
                aria-label="last page"
                onClick={() => {
                  gotoPage(props.pageCount - 1);
                }}
                disabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleRight />}
              />
            </HStack>
          </Flex>
        )}
      </Stack>
    </VStack>
  );
};

export default memo(GridView);
