import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { chakraComponents, Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { defaultErrorMessage } from '@/app/constants';
import { useGetRefRoleUsersSummaryQuery } from '@/app/services/provider/api/refrole';
import { usePostUserRoleRequestMutation } from '@/app/services/provider/api/userRoleRequest';
import {
  PostUserRoleRequestModel,
  RefRoleUserSummaryDto,
} from '@/app/services/provider/types';
import { useAppSelector } from '@/app/state/hooks';
import { ErrorData } from '@/app/types';
import CustomDatePicker from '@/components/CustomDatePicker';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import dayjs, { Dayjs } from 'dayjs';
import { FaUserCog } from 'react-icons/fa';
import RequestQuestionItem from './RequestQuestionItem';

const minDate = dayjs().toDate();
const maxDate = dayjs().add(1, 'year').toDate();
const defaultDate = dayjs().add(30, 'day').toDate();

type Props = {
  ref_role_list?: RefRoleUserSummaryDto[];
  ref_role_id?: number | undefined;
  access_expiration_datetime_utc?: string | undefined;
  access_request_business_justification?: string | undefined;
  triggerElement: JSX.Element;
};

const RequestAccessDialog = ({
  ref_role_list,
  ref_role_id,
  access_expiration_datetime_utc,
  access_request_business_justification,
  triggerElement,
}: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [expirationDate, setExpirationDate] = useState<Date>(defaultDate);
  const { logonUser } = useAppSelector(s => s.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postAsync, postDetail] = usePostUserRoleRequestMutation();

  const { data } = useGetRefRoleUsersSummaryQuery(
    {
      sort_order: 'asc',
      sort_column: 'ref_role_id',
      PageNumber: 1,
      PageSize: 99999,
      q: '',
    },
    { skip: !!ref_role_list }
  );

  const FormSchema = Yup.object().shape({
    ref_role_id: Yup.number().label('Role').required(),
    access_expiration_datetime_utc: Yup.string()
      .test((str, { createError }) => {
        const date: dayjs.Dayjs = dayjs(str, 'YYYY-MM-DD', true);
        return date.isValid()
          ? true
          : createError({
              message: 'Expiration Date in invalid',
              path: 'access_expiration_datetime_utc',
            });
      })
      .test((str, { createError }) => {
        const date: Dayjs = dayjs(str, 'YYYY-MM-DD', true);
        return date.isAfter(minDate)
          ? true
          : createError({
              message: 'Expiration Date must be later than today',
              path: 'access_expiration_datetime_utc',
            });
      })
      .test((str, { createError }) => {
        const date = dayjs(str, 'YYYY-MM-DD', true);
        return date.isBefore(maxDate)
          ? true
          : createError({
              message:
                'Expiration Date may not exceed one year from current date',
              path: 'access_expiration_datetime_utc',
            });
      })
      .label('Expiration Date')
      .required(),
    access_request_business_justification: Yup.string()
      .label('Business Justification')
      .required()
      .max(1500, 'Text exceed the character limit of 1500'),
    provider_npi: Yup.number()
      .label('Provider NPI')
      .when(['ref_role_id'], {
        is: (ref_role_id: number) => {
          const isParticipantProvider =
            data?.data.find(f => f.ref_role_id === ref_role_id)?.role_name ===
            'participant.provider.phi';
          return isParticipantProvider;
        },
        then: schema => schema.required().min(1),
        otherwise: schema => schema.optional(),
      }),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setValues,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_role_id: ref_role_id,
      access_expiration_datetime_utc: dayjs(
        access_expiration_datetime_utc || defaultDate
      ).format('YYYY-MM-DD'),
      access_request_business_justification:
        access_request_business_justification || '',
      provider_npi: undefined as number | undefined,
    },
    onSubmit: values => {
      if (logonUser?.ref_user_id && values.ref_role_id) {
        const isParticipantProvider =
          data?.data.find(f => f.ref_role_id === values.ref_role_id)
            ?.role_name === 'participant.provider.phi';

        const request: PostUserRoleRequestModel = {
          ...values,
          ref_role_id: values.ref_role_id,
          ref_user_id: logonUser.ref_user_id,
          provider_npi:
            isParticipantProvider && !!values.provider_npi
              ? values.provider_npi
              : null,
        };
        postAsync(request);
      }
    },
  });

  const clearModal = () => {
    resetForm();
    setAlertMessage('');
    setExpirationDate(defaultDate);
  };

  const selectCustomComponents = {
    Option: ({ children, ...props }: any) => (
      <chakraComponents.Option {...props}>
        {props.data.icon} {children}
      </chakraComponents.Option>
    ),
    SingleValue: ({ children, ...props }: any) => (
      <chakraComponents.SingleValue {...props}>
        <Flex align="center">
          {props.data.icon} {children}
        </Flex>
      </chakraComponents.SingleValue>
    ),
  };

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading, error } = postDetail;

    if (isSuccess) {
      setAlertMessage('Request Access successfully submitted.');
      resetForm();
      setTimeout(() => {
        clearModal();
        onClose();
      }, 3000);
    } else if (isError) {
      const data = (error as FetchBaseQueryError)?.data as ErrorData;
      setAlertMessage(data?.error_message || defaultErrorMessage);
    } else {
      setAlertMessage('');
    }

    if (isLoading) {
      setAlertMessage('');
    }
  }, [postDetail]);

  const displayedData = useMemo(() => {
    return ref_role_list && (ref_role_list?.length ?? 0) > 0
      ? ref_role_list
      : data && (data?.data.length ?? 0) > 0
      ? data.data
      : [];
  }, [ref_role_list, data]);

  const isParticipantProvider =
    data?.data.find(f => f.ref_role_id === values.ref_role_id)?.role_name ===
    'participant.provider.phi';

  return (
    <>
      <Box
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Access</ModalHeader>
          <ModalCloseButton isDisabled={postDetail.isLoading} />
          <>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <List>
                  {
                    <ListItem>
                      <RequestQuestionItem
                        index={1}
                        label="Choose Role"
                        isComplete={!!values.ref_role_id}
                      >
                        <FormControl
                          isInvalid={
                            !!errors.ref_role_id && touched.ref_role_id
                          }
                        >
                          <Select
                            id="ref_role_id"
                            name="ref_role_id"
                            useBasicStyles
                            size="sm"
                            components={selectCustomComponents}
                            defaultValue={displayedData.map(
                              (m: RefRoleUserSummaryDto) =>
                                m.ref_role_id == ref_role_id
                                  ? {
                                      label: m.role_display_name,
                                      icon: <Icon as={FaUserCog} mr={2} />,
                                      value: m.ref_role_id,
                                    }
                                  : null
                            )}
                            value={(() => {
                              const m = displayedData.find(
                                f => f.ref_role_id === values.ref_role_id
                              );
                              return m
                                ? {
                                    label: m.role_display_name,
                                    icon: <Icon as={FaUserCog} mr={2} />,
                                    value: m.ref_role_id,
                                  }
                                : null;
                            })()}
                            options={displayedData.map(m => {
                              return {
                                label: m.role_display_name,
                                icon: <Icon as={FaUserCog} mr={2} />,
                                value: m.ref_role_id,
                              };
                            })}
                            onChange={e => {
                              e?.value &&
                                setValues({
                                  ...values,
                                  ref_role_id: e.value,
                                });
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: provided => ({
                                ...provided,
                                zIndex: 1401,
                              }),
                            }}
                            maxMenuHeight={500}
                          />
                          <FormErrorMessage>
                            {errors.ref_role_id}
                          </FormErrorMessage>
                        </FormControl>
                      </RequestQuestionItem>
                    </ListItem>
                  }

                  {isParticipantProvider && (
                    <ListItem>
                      <RequestQuestionItem
                        hasNoLine
                        index={2}
                        label="Fill Provider NPI"
                        isComplete={(values.provider_npi ?? 0) > 0}
                      >
                        <FormControl
                          isInvalid={
                            !!errors.provider_npi && touched.provider_npi
                          }
                        >
                          <NumberInput
                            id="provider_npi"
                            placeholder="Enter Provider NPI"
                            name="provider_npi"
                            // onChange={handleChange}
                            onChange={e => {
                              setValues({
                                ...values,
                                provider_npi: parseInt(e || '0'),
                              });
                            }}
                            value={values.provider_npi}
                            size="sm"
                          >
                            <NumberInputField />
                          </NumberInput>
                          <FormErrorMessage>
                            {errors.provider_npi}
                          </FormErrorMessage>
                        </FormControl>
                      </RequestQuestionItem>
                    </ListItem>
                  )}

                  <ListItem>
                    <RequestQuestionItem
                      index={isParticipantProvider ? 3 : 2}
                      label={
                        <HStack align="center" spacing={1}>
                          <Text>Choose Expiration</Text>
                          <Text fontWeight="normal" as="small">
                            (YYYY-MM-DD)
                          </Text>
                        </HStack>
                      }
                      isComplete={
                        !errors.access_expiration_datetime_utc &&
                        values.access_expiration_datetime_utc.length > 0
                      }
                    >
                      <FormControl
                        isInvalid={!!errors.access_expiration_datetime_utc}
                      >
                        <CustomDatePicker
                          id="access_expiration_datetime_utc"
                          name="access_expiration_datetime_utc"
                          date={expirationDate}
                          onDateChange={(date: Date) => {
                            setValues({
                              ...values,
                              access_expiration_datetime_utc:
                                dayjs(date).format('YYYY-MM-DD'),
                            });
                            setExpirationDate(date);
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                          propsConfigs={{
                            dateNavBtnProps: {
                              colorScheme: 'brand.main.default',
                              variant: 'outline',
                            },
                            dayOfMonthBtnProps: {
                              defaultBtnProps: {
                                _hover: {
                                  background: 'brand.main.default',
                                  color: 'white',
                                },
                              },
                              selectedBtnProps: {
                                background: 'brand.main.default',
                                color: 'white',
                              },
                              todayBtnProps: {
                                background: 'gray.400',
                              },
                            },
                            inputProps: {
                              placeholder: 'YYYY-MM-DD',
                              size: 'sm',
                              value: values.access_expiration_datetime_utc,
                              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  access_expiration_datetime_utc:
                                    e.target.value,
                                });
                                if (dayjs(e.target.value)?.isValid()) {
                                  setExpirationDate(
                                    dayjs(e.target.value).toDate()
                                  );
                                }
                              },
                            },
                          }}
                        />
                        <FormErrorMessage>
                          {errors.access_expiration_datetime_utc}
                        </FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                  <ListItem>
                    <RequestQuestionItem
                      hasNoLine
                      index={isParticipantProvider ? 4 : 3}
                      label="Fill in business justification"
                      isComplete={
                        values.access_request_business_justification.length > 0
                      }
                    >
                      <FormControl
                        isInvalid={
                          !!errors.access_request_business_justification &&
                          touched.access_request_business_justification
                        }
                      >
                        <Textarea
                          id="access_request_business_justification"
                          placeholder="Enter Message"
                          name="access_request_business_justification"
                          onChange={handleChange}
                          value={values.access_request_business_justification}
                          maxLength={1500}
                        />
                        <FormErrorMessage>
                          {errors.access_request_business_justification}
                        </FormErrorMessage>
                      </FormControl>
                    </RequestQuestionItem>
                  </ListItem>
                </List>

                <VStack spacing={5}>
                  {alertMessage && (
                    <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                      <AlertIcon />
                      {alertMessage}
                    </Alert>
                  )}
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={onClose}
                  mr={3}
                  ml="auto"
                  isDisabled={postDetail.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.main"
                  isLoading={postDetail.isLoading}
                  isDisabled={alertMessage !== '' && postDetail.isSuccess}
                >
                  Send
                </Button>
              </ModalFooter>
            </form>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestAccessDialog;
