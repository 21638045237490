import { defaultErrorMessage } from '@/app/constants';
import { useUserRoleContext } from '@/app/context/UserRoleContext';
import { useAddOrganizationFolderMutation } from '@/app/services/provider/api/organizationFolder';
import { useGetRefRoleListQuery } from '@/app/services/provider/api/refrole';
import { GetContentsByOrganizationModel } from '@/app/services/provider/types';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useFormik } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import * as Yup from 'yup';

type Props = {
  organizationId?: number;
  parentFolderId: number | null;
  onCreated: () => void;
  content: GetContentsByOrganizationModel | undefined;
};

const FormSchema = Yup.object().shape({
  organization_folder_name: Yup.string()
    .label('Name')
    .trim()
    .required()
    .max(1000, 'Text exceed the character limit of 1000')
    .test((str, { createError }) => {
      const regex = /^[a-zA-Z0-9_ ]+$/;
      return str && !regex.test(str)
        ? createError({
            message:
              'Folder names only accepts upper & lower-case characters, numbers, spaces and underscores',
            path: 'organization_folder_name',
          })
        : true;
    }),
  list_role_id: Yup.array<number[]>()
    .label('Roles')
    .min(1)
    .of(Yup.number().moreThan(0)),
});

const FileShareAddFolderModal: React.FC<Props> = ({
  organizationId,
  parentFolderId,
  onCreated,
  content,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { isUserLoreAdmin } = useUserRoleContext();

  const [addFolderAsync, addFolderDetail] = useAddOrganizationFolderMutation();
  const refRoleDetail = useGetRefRoleListQuery();

  const handleClose = () => {
    onClose();
    resetForm();
  };

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    resetForm,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      organization_folder_name: '',
      list_role_id: content
        ? content.main_folder.allowed_roles
        : ([] as number[]),
    },
    onSubmit: values => {
      if (organizationId && !hasDuplicate()) {
        addFolderAsync({
          organization_folder_name: values.organization_folder_name.trim(),
          organization_id: organizationId,
          parent_folder_id: parentFolderId,
          list_role_id: values.list_role_id,
        })
          .unwrap()
          .then(() => {
            onCreated();
            toast({
              description: 'Successfully created folder',
              status: 'success',
            });
            handleClose();
          })
          .catch(() =>
            toast({ description: defaultErrorMessage, status: 'error' })
          );
      }
    },
  });

  const hasDuplicate = () => {
    return content?.folders.some(f => {
      return (
        f.organization_folder_name.toLowerCase() ===
        values.organization_folder_name.toLowerCase()
      );
    });
  };

  return !isUserLoreAdmin ||
    refRoleDetail.isLoading ||
    refRoleDetail.isFetching ? (
    <></>
  ) : (
    <>
      <Button
        colorScheme="brand.main"
        leftIcon={<AddIcon />}
        isDisabled={addFolderDetail.isLoading}
        onClick={onOpen}
      >
        Add Folder
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="md"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Folder</ModalHeader>
          <ModalCloseButton
            onClick={handleClose}
            isDisabled={addFolderDetail.isLoading}
          />
          <form onSubmit={handleSubmit}>
            <ModalBody display="flex" flexDir="column" gap="3">
              <FormControl
                isInvalid={
                  (hasDuplicate() || !!errors.organization_folder_name) &&
                  !!touched.organization_folder_name
                }
              >
                <FormLabel htmlFor="organization_folder_name">
                  Folder Name
                </FormLabel>
                <Input
                  id="organization_folder_name"
                  placeholder="Enter folder name..."
                  name="organization_folder_name"
                  onChange={handleChange}
                  value={values.organization_folder_name}
                  maxLength={1000}
                />
                <FormErrorMessage>
                  {(hasDuplicate() &&
                    'Must not be duplicate with existing folders') ||
                    errors.organization_folder_name}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!errors.list_role_id && !!touched.list_role_id}
              >
                <FormLabel htmlFor="list_role_id">Roles</FormLabel>
                <Box>
                  <Select
                    id="list_role_id"
                    name="list_role_id"
                    useBasicStyles
                    placeholder="Select role..."
                    value={null}
                    options={refRoleDetail.data?.map(m => ({
                      label: m.role_display_name,
                      value: m.ref_role_id,
                      isDisabled: values.list_role_id.some(
                        s => s === m.ref_role_id
                      ),
                    }))}
                    onChange={e => {
                      if (e?.value)
                        setValues({
                          ...values,
                          list_role_id: [...values.list_role_id, e.value],
                        });
                    }}
                  />
                  <>
                    {values.list_role_id.length > 0 && refRoleDetail.data && (
                      <Box mt="3">
                        {refRoleDetail.data
                          .filter(f =>
                            values.list_role_id.some(s => s === f.ref_role_id)
                          )
                          .map(m => (
                            <HStack key={m.ref_role_id}>
                              <IconButton
                                variant="link"
                                aria-label={`remove ${m.role_display_name}`}
                                icon={<FaTrashAlt />}
                                size="xs"
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    list_role_id: values.list_role_id.filter(
                                      f => f !== m.ref_role_id
                                    ),
                                  });
                                }}
                              />
                              <Text>{m.role_display_name}</Text>
                            </HStack>
                          ))}
                      </Box>
                    )}
                  </>
                </Box>
                <FormErrorMessage>{errors.list_role_id}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter gap={3}>
              <Button
                onClick={handleClose}
                ml="auto"
                isDisabled={addFolderDetail.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="brand.main"
                type="submit"
                isDisabled={addFolderDetail.isLoading}
                isLoading={addFolderDetail.isLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileShareAddFolderModal;
