import { Button, ButtonProps } from '@chakra-ui/react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

type Props<T = any> = {
  children: ReactNode;
  exportName?: string;
  button?: {
    props?: ButtonProps;
  };
  isDisabled?: boolean;
  handleDownload: () => Promise<T[]>;
  handleEmptyDownload?: () => void;
  transform?: {
    Header: string;
    accessor?: keyof T;
    transform?(e: T): any;
  }[];
};

function CsvDownloadButton<T = any>(props: Props<T>) {
  const {
    exportName,
    handleDownload,
    handleEmptyDownload,
    button,
    children,
    isDisabled,
    transform,
  } = props;
  const refCsvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [exportList, setExportList] = useState<T[] | any[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (exportList) {
      refCsvLink.current?.link.click();
      setExportList(null);
      setIsLoading(false);
    }
  }, [exportList]);

  const handleOnClick = async () => {
    try {
      setIsLoading(true);
      let list: unknown[] | null | undefined;
      const tmpList = await handleDownload();
      if (transform) {
        list = tmpList.map(m =>
          transform.reduce((prev, next) => {
            if (next.Header && next.transform) {
              return {
                ...prev,
                [next.Header]: next.transform(m),
              };
            }
            if (next.Header && next.accessor) {
              return {
                ...prev,
                [next.Header]: m[next.accessor as keyof T],
              };
            }
            return prev;
          }, {})
        );
      } else {
        list = tmpList;
      }

      if (!list || !Array.isArray(list) || list.length < 1) {
        !!handleEmptyDownload && handleEmptyDownload();
        throw 'empty list';
      } else {
        setExportList(list);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        isLoading={isLoading}
        isDisabled={isLoading || isDisabled}
        onClick={handleOnClick}
        colorScheme="brand.main"
        {...button?.props}
      >
        {children}
      </Button>
      <CSVLink
        data={(exportList ?? []) as object[]}
        className="hidden"
        filename={exportName ?? `export_csv_${Date.now()}`}
        ref={refCsvLink}
        target="_blank"
      />
    </>
  );
}

export default CsvDownloadButton;
