import { Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  useLazyGetUserOrganizationByIdQuery,
  usePostUserOrganizationMutation,
} from '../../app/services/provider/api/userOrganization';

const AssignButton = ({ refUserId, orgId, setError, setIsSuccess }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [postAsync] = usePostUserOrganizationMutation();
  const [userOrgByIdTrigger] = useLazyGetUserOrganizationByIdQuery();

  const assignUser = async (userId: number) => {
    setIsLoading(true);
    try {
      const postResponse = await postAsync({
        organization_id: orgId,
        ref_user_id: userId,
        row_created_date_time_utc: dayjs().format(),
        row_modified_date_time_utc: dayjs().format(),
        row_created_ref_user_id: userId,
        row_modified_ref_user_id: userId,
        row_created_by_user_name: '',
        row_modified_by_user_name: '',
      }).unwrap();
      const getResponse = await userOrgByIdTrigger(postResponse.id);
      setIsSuccess(getResponse.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Button
        size="xs"
        colorScheme="brand.main"
        variant="outline"
        justifyContent="space-between"
        isLoading={isLoading}
        onClick={() => assignUser(refUserId)}
      >
        Assign
      </Button>
    </div>
  );
};

export default AssignButton;
