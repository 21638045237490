import { Box } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = object;

const OrdersToSign = (props: Props) => {
  return (
    <Box w="100%">
      <Outlet />
    </Box>
  );
};

export default OrdersToSign;
