import { Heading, VStack, Text, Container, Link } from '@chakra-ui/react';
import { appColors } from '../../app/theme';
import { MailTo } from './MailTo';

type Props = object;

const cmsLink =
  'https://data.cms.gov/provider-characteristics/medicare-provider-supplier-enrollment/medicare-fee-for-service-public-provider-enrollment/data';
const npiLink = 'https://npiregistry.cms.hhs.gov/search';

const FAQ = (props: Props) => {
  return (
    <VStack>
      <VStack>
        <Heading>Frequently Asked Questions</Heading>
      </VStack>
      <VStack>
        <Container maxW="container.xl">
          <VStack alignItems={'left'}>
            {/* <Text fontWeight={"bold"}>
              Q: How to update the provider information I have identified as incorrect?
            </Text>
            <Text>
              <strong>A:</strong> For CMS related data, you may verify the imported information{" "}
              <Link isExternal color={appColors.links} href={cmsLink}>
                here
              </Link>{" "}
              and send an email to{" "}
              <MailTo toEmailAddress={"providerenrollmentdatarequests@cms.hhs.gov"} /> for
              assistance to update the data.
              <br />
            </Text>
            <Text paddingLeft={"20px"}>
              For NPI related data, you may verify the imported information{" "}
              <Link color={appColors.links} href={npiLink} isExternal>
                here
              </Link>{" "}
              and send an email to <MailTo toEmailAddress={"customerservice@npienumerator.com"} />{" "}
              for assistance to update the data.
            </Text> */}
          </VStack>
        </Container>
      </VStack>
    </VStack>
  );
};

export default FAQ;
