import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { DefaultToastOptions } from './app/constants';
import { msalConfig } from './app/services/auth/authConfig';
import { persistor, store } from './app/state/store';
import theme from './app/theme';
import Offline from './features/Offline/Offline';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.

  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// @ts-expect-error: please fix if encountered - src globalthis
globalThis.msalInstance = msalInstance;

const container = document.getElementById('root')!;
const root = createRoot(container);
const isOffline = Boolean(
  process.env.REACT_APP_IS_OFFLINE === 'yes' ? true : false
);

root.render(
  <React.StrictMode>
    {isOffline ? (
      <ChakraProvider theme={theme} resetCSS>
        <Offline />
      </ChakraProvider>
    ) : (
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ChakraProvider
                theme={theme}
                resetCSS
                toastOptions={{ defaultOptions: DefaultToastOptions }}
              >
                <App />
              </ChakraProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </MsalProvider>
    )}
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// if (process.env.NODE_ENV === "production") {
//   serviceWorkerRegistration.register();
// } else {
//   serviceWorkerRegistration.unregister();
// }
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
