import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = object;

const Roles = (props: Props) => {
  return (
    <Box w="100%">
      <Outlet />
    </Box>
  );
};

export default Roles;
