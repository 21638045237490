import {
  Box,
  Flex,
  HStack,
  Stack,
  Heading,
  SimpleGrid,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useGetAvatarQuery } from '../../app/services/provider/api/avatar';
import { useGetUserDemographicListQuery } from '../../app/services/provider/api/userDemographic';
import {
  GetUserDemographicDto,
  LogonUserDto,
} from '../../app/services/provider/types';
import { setLogonUser } from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import Avatar from '../../components/Avatar/Avatar';
import EditDemographicsButton from './EditDemographicsButton';
import UploadAvatarButton from './UploadAvatarButton';

type ProfileProps = object;

export const Profile: FunctionComponent<ProfileProps> = () => {
  const [avatar, setAvatar] = useState('');
  const { logonUser } = useAppSelector(s => s.user);

  const { data, isLoading, isFetching } = useGetAvatarQuery(
    logonUser?.user_email || ''
  );
  const [demographic, setDemographic] = useState<
    GetUserDemographicDto[] | null
  >(null);

  const dispatch = useAppDispatch();

  const userDemographic = useGetUserDemographicListQuery(
    logonUser?.ref_user_id || 0
  );

  const parseUserDemographics = (data: any, key: string) => {
    let value = '';
    data.forEach((item: GetUserDemographicDto) => {
      if (item.demographic_name.toLowerCase() === key.toLowerCase()) {
        value = item.demographic_value;
      }
    });

    return value;
  };

  useEffect(() => {
    if (!isFetching && data) setAvatar(data);
  }, [isFetching]);

  useEffect(() => {
    if (!userDemographic.isFetching && userDemographic.data) {
      setDemographic(userDemographic.data);

      const modifiedLogonUser = {
        ...logonUser,
        user_first_name: parseUserDemographics(
          userDemographic.data,
          'first_name'
        ),
        user_last_name: parseUserDemographics(
          userDemographic.data,
          'last_name'
        ),
        user_middle_name: parseUserDemographics(
          userDemographic.data,
          'middle_name'
        ),
      } as LogonUserDto;

      dispatch(setLogonUser(modifiedLogonUser));
    }
  }, [userDemographic.isFetching]);

  return (
    <>
      <HStack spacing={8} justifyContent="center" alignItems="flex-start">
        {isLoading || userDemographic.isLoading || !logonUser ? (
          <Stack mt={1}>
            {[...Array(5)].map((m, i) => (
              <Skeleton key={i} height="18px" />
            ))}
          </Stack>
        ) : (
          <>
            <Box p={5} shadow="md" borderWidth="1px" textAlign="center">
              <Avatar src={avatar} />
              <UploadAvatarButton title="Change" logonUser={logonUser} />
              <Box color="gray.600">
                <Text fontSize={16} fontWeight="medium">
                  {logonUser?.user_email}
                </Text>
                <Text fontSize={12}>Email Address</Text>
              </Box>
            </Box>
            <Box p={5} shadow="md" borderWidth="1px">
              <Heading as="h5" size="md" color="gray.600">
                Profile
              </Heading>

              <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={90} pt={6}>
                {demographic &&
                  demographic.map((u: GetUserDemographicDto) => (
                    <Box height="80px">
                      <Text fontSize="sm" fontWeight="bold" color="gray.600">
                        {u.demographic_label}
                      </Text>
                      <Text minW={100}>
                        {u.demographic_value.length > 0
                          ? u.demographic_value
                          : '-'}
                      </Text>
                    </Box>
                  ))}
              </SimpleGrid>
              <Flex
                height="80px"
                alignItems="center"
                justifyContent="flex-end"
                maxH="62px"
              >
                {!userDemographic.isFetching &&
                  demographic &&
                  demographic.length > 0 && (
                    <EditDemographicsButton
                      title="Edit"
                      data={demographic}
                      userId={logonUser?.ref_user_id || 0}
                    />
                  )}
              </Flex>
            </Box>
          </>
        )}
      </HStack>
    </>
  );
};
