export type ValueOf<T> = T[keyof T];

export const debounce = (cb: (p: any) => void, delay = 250) => {
  let timeout: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      // @ts-expect-error: please fix if encountered - args spread
      cb(...args);
    }, delay);
  };
};
