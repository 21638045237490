import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  IconButton,
  Spacer,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDeleteUserMutation } from '../../app/services/provider/api/user';
import { UserDto } from '../../app/services/provider/types';
import { useAppSelector } from '../../app/state/hooks';

type IProps = {
  refUser: UserDto;
};

const DeleteUserButton: FC<IProps> = ({ refUser }: IProps) => {
  const alertDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const { logonUser } = useAppSelector(s => s.user);
  const [deleteAsync, { isLoading, isSuccess, isError }] =
    useDeleteUserMutation();

  const onDelete = () => {
    deleteAsync({
      ref_user_id: refUser.ref_user_id,
      deleted_by_user_id: logonUser?.ref_user_id || 0,
    });
  };

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          alertDisclosure.onClose();
          onClose();
        }, 2000);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError, alertDisclosure, onClose]);
  return (
    <>
      <Tooltip
        hasArrow
        label={refUser.disabled_flag ? 'Deleted' : 'Delete'}
        bg="gray.100"
        color="gray.600"
        placement="top"
      >
        <IconButton
          variant="link"
          color={refUser.disabled_flag ? 'gray.300' : 'brand.error'}
          aria-label="Delete"
          icon={<MdDelete />}
          onClick={refUser.disabled_flag ? () => ({}) : onOpen}
          minWidth={1}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to Delete this account?
              <Spacer mb={3} />
              Once Deleted the user will not have any access to the Provider
              system.
              {alertDisclosure.isOpen && (
                <Alert
                  status={isSuccess ? 'success' : isError ? 'error' : 'info'}
                  mt={3}
                >
                  <AlertIcon />
                  {isSuccess && `Successfully deleted record`}
                  {isError &&
                    'Something went wrong, please try again later or contact admin'}
                </Alert>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="brand.main"
                onClick={onDelete}
                ml={3}
                isLoading={isLoading}
                disabled={alertDisclosure.isOpen}
              >
                Confirm Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteUserButton;
