import { Button, Center, Divider, Flex, Heading } from '@chakra-ui/react';
import { FC, FunctionComponent, useState } from 'react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import StepperSteps from './StepperSteps';
import StepperStep from './StepperStep';
import React from 'react';
import { StepperProvider } from '../../app/context/StepperContext';
import StepperActions from './StepperActions';

type CustomStepperProps = {
  children: JSX.Element;
};

const CustomStepper = ({ children }: CustomStepperProps) => {
  return (
    <Flex flexDir="column" width="100%">
      {children}
    </Flex>
  );
};

CustomStepper.Step = StepperStep;
CustomStepper.Steps = StepperSteps;
CustomStepper.Actions = StepperActions;

export default CustomStepper;
