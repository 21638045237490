import React, { FC, ReactNode } from 'react';
import { Step } from 'chakra-ui-steps';

type IStepperStep = {
  children: JSX.Element;
  label?: ReactNode;
  description?: string;
  id?: string;
};
const StepperStep: FC<IStepperStep> = ({
  children,
  label,
  description,
  id,
}) => {
  return (
    <Step label={label} key={id} description={description}>
      {children}
    </Step>
  );
};

export default StepperStep;
