import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WindowSize } from '../types/appAccessAuth';
export type AppState = {
  isShowMobileTab: boolean;
  windowSize: WindowSize;
  appInitComplete: boolean;
  sideNavWidth: number;
  firstSigninSuccess: boolean;
};

const initialState: AppState = {
  appInitComplete: false,
  isShowMobileTab: false,
  windowSize: { width: 0, height: 0 },
  sideNavWidth: 0,
  firstSigninSuccess: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsShowMobileTab: (state, action: PayloadAction<boolean>) => {
      state.isShowMobileTab = action.payload;
    },
    setWindowSize: (state, action: PayloadAction<WindowSize>) => {
      state.windowSize = action.payload;
    },
    setAppInitComplete: (state, action: PayloadAction<boolean>) => {
      state.appInitComplete = action.payload;
    },
    setSideNavWidth: (state, action: PayloadAction<number>) => {
      state.sideNavWidth = action.payload;
    },
    setFirstSigninSuccess: (state, action: PayloadAction<boolean>) => {
      state.firstSigninSuccess = action.payload;
    },
  },
});

export const {
  setIsShowMobileTab,
  setWindowSize,
  setAppInitComplete,
  setSideNavWidth,
  setFirstSigninSuccess,
} = appSlice.actions;
export default appSlice.reducer;
