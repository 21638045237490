import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useChooseOrgContext } from '../../app/context/ChooseOrgContext';
import { useUserRoleContext } from '../../app/context/UserRoleContext';
import { UserRoleAppAccessByEmailModel } from '../../app/services/provider/types';
import { appColors } from '../../app/theme';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import ChangeOrganizationButton from './ChangeOrganizationButton';
import ClinicProviderList from './ClinicProviderList';
import SearchCmsButton from './SearchCmsButton';
type Props = object;

const ClinicProvider = (props: Props) => {
  const { selectedOrg, setSelectedOrg, userHasMultipleOrganization, refetch } =
    useChooseOrgContext();
  const { userAppAccess } = useUserRoleContext();

  const userHasPatientDetailsAccess = userAppAccess.some(
    (s: UserRoleAppAccessByEmailModel) =>
      AppAccessAuth.ProviderPatientDetailsAll.includes(s.app_access_name as any)
  );

  const onClickSwitch = (param: any) => {
    setSelectedOrg(param);
    refetch();
  };

  return (
    <VStack alignItems="stretch" flexDir="column" w="full">
      {selectedOrg && !selectedOrg.disabled_flag ? (
        <>
          <VStack spacing={3} alignItems="start">
            <Heading>Lore Health ACO – Participant Providers</Heading>
            <HStack>
              <SearchCmsButton
                userOrgId={selectedOrg.user_organization_id}
                organizationTin={selectedOrg.organization_tin}
              />
            </HStack>
            <HStack>
              {userHasMultipleOrganization ? (
                <ChangeOrganizationButton
                  onChange={e => onClickSwitch(e)}
                  organizationName={selectedOrg.organization_name}
                />
              ) : (
                <Heading as="h5" size="md">
                  Organization:
                  <Text as="span" p={3}>
                    {selectedOrg.organization_name}
                  </Text>
                </Heading>
              )}
            </HStack>

            <Alert
              bg={appColors.hightLightColor}
              borderRadius={5}
              my={2}
              borderLeftWidth={4}
              borderLeftColor={appColors.brand.main.default}
            >
              <VStack align="stretch">
                <Text>
                  Click "Add Provider" above to import your information from CMS
                  using your NPI. You can use your NPI to look up your PECOS ID{' '}
                  <Link
                    href="https://data.cms.gov/provider-characteristics/medicare-provider-supplier-enrollment/medicare-fee-for-service-public-provider-enrollment/data"
                    color={appColors.links}
                    isExternal
                  >
                    here.{' '}
                    <ExternalLinkIcon
                      fontSize={12}
                      transform="translate(-3px,-3px)"
                    />
                  </Link>
                </Text>

                {/* <Text>
                  Information submitted here will be added to Appendix 1 of the Lore Health ACO –
                  Provider Memorandum of Understanding.
                </Text> */}

                {userHasPatientDetailsAccess && (
                  <Text>
                    To see detailed list of patients per provider, click{' '}
                    <Link
                      as={RouterLink}
                      to="./patient-details"
                      color={appColors.links}
                    >
                      here
                    </Link>
                    .
                  </Text>
                )}
              </VStack>
            </Alert>
          </VStack>
          <VStack flexDir="column" alignItems="stretch">
            <ClinicProviderList orgId={selectedOrg.organization_id} />
          </VStack>
        </>
      ) : (
        <Alert status="warning" mb={3}>
          <AlertIcon />
          Organization is Unavailable
        </Alert>
      )}
    </VStack>
  );
};

export default ClinicProvider;
