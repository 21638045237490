import { OrganizationFolderModel } from '@/app/services/provider/types';
import { appColors } from '@/app/theme';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Fragment, useMemo } from 'react';

type Props = {
  orgId: number | undefined;
  folderId: number | null;
  folders: OrganizationFolderModel[];
  isLoading: boolean;
  setFolderId: React.Dispatch<React.SetStateAction<number | null>>;
  setFolders: React.Dispatch<React.SetStateAction<OrganizationFolderModel[]>>;
  fetch: (prop: { folderId?: number | null; orgId?: number }) => void;
};
const _numberOfFoldersToShow = 2;

const FileShareBreadCrumb: React.FC<Props> = ({
  orgId,
  folders,
  folderId,
  isLoading,
  setFolderId,
  setFolders,
  fetch,
}) => {
  const displayedFolders = useMemo(() => {
    if (folders.length > _numberOfFoldersToShow) {
      return folders.slice(-_numberOfFoldersToShow);
    } else {
      return folders;
    }
  }, [folders]);

  if (isLoading) return <></>;

  return (
    <Box h="26px" mb="3">
      {folderId && !!displayedFolders.length && (
        <HStack>
          <Text
            _hover={{ textDecoration: 'underline' }}
            cursor="pointer"
            onClick={() => {
              setFolderId(null);
              setFolders([]);
              fetch({ orgId });
            }}
          >
            Root
          </Text>
          <Text>/</Text>

          {folders.length > _numberOfFoldersToShow && (
            <>
              <Text>...</Text>
              <Text>/</Text>
            </>
          )}

          {displayedFolders.map((m, i) => {
            const isLast = i === displayedFolders.length - 1;
            return (
              <Fragment key={m.organization_folder_id}>
                <Text
                  maxW="72"
                  isTruncated
                  title={m.organization_folder_name}
                  _hover={{
                    textDecoration: !isLast ? 'underline' : undefined,
                  }}
                  cursor={!isLast ? 'pointer' : undefined}
                  color={isLast ? appColors.brand.main.default : undefined}
                  onClick={
                    isLast
                      ? undefined
                      : () => {
                          const index = folders.findIndex(
                            f =>
                              f.organization_folder_id ===
                              m.organization_folder_id
                          );
                          setFolderId(m.organization_folder_id);
                          setFolders(f => {
                            const list = [...f];
                            list.splice(index);
                            return list;
                          });
                          fetch({ folderId: m.organization_folder_id });
                        }
                  }
                >
                  {m.organization_folder_name}
                </Text>
                {!isLast && <Text>/</Text>}
              </Fragment>
            );
          })}
        </HStack>
      )}
    </Box>
  );
};

export default FileShareBreadCrumb;
