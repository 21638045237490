import { FC, useEffect, useMemo, useState } from 'react';
import { SortingRule } from 'react-table';

// should be changed
import { useUserRoleContext } from '@/app/context/UserRoleContext';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetRefRoleUsersSummaryQuery } from '@/app/services/provider/api/refrole';
import {
  RefRoleUsersSummaryDto,
  RefRoleUserSummaryDto,
  UserRoleAppAccessByEmailModel,
} from '@/app/services/provider/types';
import { AppAccessAuth } from '@/app/types/appAccessAuth';
import LastModified from './CellComponents/LastModified';
import UserCount from './CellComponents/UserCount';
import ExploreRolesView from './ExploreRoles.view';

type Props = object;

export type SortType = SortingRule<RefRoleUsersSummaryDto>;
export const InitialSortBy: SortType = {
  id: 'ref_role_id',
  desc: false,
};

const ExploreRoles: FC<Props> = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState(InitialSortBy);
  const [pageSize, setPageSize] = useState(10);
  const [searchDebounce, setSearchDebounce] = useState('');
  const debouncedSearch = useDebounce(search, 400);
  const { userAppAccess } = useUserRoleContext();

  const userHasRequiredRole = userAppAccess.some(
    (s: UserRoleAppAccessByEmailModel) =>
      AppAccessAuth.exploreRolesWrite.includes(s.app_access_name as any)
  );

  const {
    data: refRoleUsersSummary,
    isLoading: isLoadingUsersSummary,
    isFetching: isFetchingUsersSummary,
  } = useGetRefRoleUsersSummaryQuery({
    sort_order: sortBy.desc ? 'desc' : 'asc',
    sort_column: (sortBy.id || 'ref_role_id') as keyof RefRoleUserSummaryDto,
    PageNumber: pageIndex + 1,
    PageSize: pageSize,
    q: searchDebounce,
  });

  const columns = useMemo(
    () => [
      {
        Header: 'ROLE NAME',
        accessor: 'role_name',
        Cell: ({
          row: { original },
        }: {
          row: { original: RefRoleUserSummaryDto };
        }) => {
          return original.role_display_name;
        },
        style: {
          width: 150,
          whiteSpace: 'break-spaces',
        },
        styleColumn: {
          whiteSpace: 'nowrap',
        },
      },
      {
        Header: 'DESCRIPTION',
        accessor: 'role_desc',
        style: {
          width: '51%',
          whiteSpace: 'break-spaces',
        },
        styleColumn: {
          whiteSpace: 'nowrap',
        },
      },
      {
        Header: 'LAST MODIFIED',
        accessor: 'last_modified_datetime_utc',
        Cell: LastModified,
      },
      {
        Header: 'USERS',
        accessor: 'user_count',
        Cell: UserCount,
      },
    ],
    []
  );

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <ExploreRolesView
      data={refRoleUsersSummary}
      columns={columns}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPageIndex={setPageIndex}
      setPageSize={setPageSize}
      setSearch={setSearch}
      setSortBy={setSortBy}
      isLoadingUsersSummary={isLoadingUsersSummary}
      isFetchingUsersSummary={isFetchingUsersSummary}
      userHasRequiredRole={userHasRequiredRole}
    />
  );
};

export default ExploreRoles;
