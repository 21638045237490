import { createContext, useContext, useEffect, useState } from 'react';
import {
  loadState,
  LOCALSTORAGE_KEYS,
} from '../../app/helpers/localStorageHelper';
import { useGetUserOrganizationByRefUserIdQuery } from '../../app/services/provider/api/userOrganization';
import { GetUserOrganizationDto } from '../../app/services/provider/types';
import { useAppSelector } from '../../app/state/hooks';

export const ChooseOrgContext = createContext<any>('');

export type OrganizationContext = {
  userHasMultipleOrganization: boolean;
  userOrganizations: GetUserOrganizationDto[] | null;
  selectedOrg: GetUserOrganizationDto | null;
  setSelectedOrg: React.Dispatch<
    React.SetStateAction<GetUserOrganizationDto | null>
  >;
  refetch: () => void;
};

export const useChooseOrgContext = () => {
  const context = useContext(ChooseOrgContext);
  if (!context) {
    throw new Error('Context error');
  }

  return context;
};

export const ChooseOrgProvider = ({ children }: any) => {
  const { logonUser } = useAppSelector(s => s.user);
  const clinicProvider = loadState(LOCALSTORAGE_KEYS.clinicProvder);
  const [selectedOrg, setSelectedOrg] = useState<GetUserOrganizationDto | null>(
    null
  );
  const [userOrganizations, setUserOrganizations] = useState<
    GetUserOrganizationDto[] | null
  >(null);
  const [userHasMultipleOrganization, setUserHasMultipleOrganization] =
    useState(false);

  const { data, isLoading, isFetching, isError, refetch } =
    useGetUserOrganizationByRefUserIdQuery(logonUser?.ref_user_id || 0);

  useEffect(() => {
    if (!isLoading && data && data.length) {
      const tempData = data.filter(f => !f.disabled_flag);
      setUserOrganizations(() => tempData);
      setUserHasMultipleOrganization(tempData.length > 1);
      let selected = selectedOrg;

      if (!selected && clinicProvider && clinicProvider.selectedOrg) {
        selected = clinicProvider.selectedOrg;
      } else if (!selected && tempData.length === 1) {
        // only one org make it the default selected
        selected = tempData[0];
      } else if (tempData.length === 0) {
        console.error('User organization was not setup.');
      }

      setSelectedOrg(selected);

      return () => {
        setUserOrganizations(null);
      };
    }
  }, [isLoading, data, isError, selectedOrg]);

  return (
    <ChooseOrgContext.Provider
      value={{
        userHasMultipleOrganization,
        userOrganizations,
        selectedOrg,
        setSelectedOrg,
        refetch,
      }}
    >
      {isLoading || isFetching ? <>Loading...</> : children}
    </ChooseOrgContext.Provider>
  );
};
