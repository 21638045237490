import { Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { UserDto } from '../../app/services/provider/types';
import { PanelCard } from '../../components/PanelCard';
import UserList from './UserList';
import UserRole from './UserRole';

export const User = () => {
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);

  const onClickUser = (user: UserDto | null) => {
    setSelectedUser(user);
  };

  const clearSelected = () => {
    setSelectedUser(null);
  };

  const fullName = `${selectedUser?.first_name?.toLowerCase()} ${selectedUser?.last_name?.toLowerCase()}`;

  return (
    <Grid templateColumns="repeat(3, minmax(450px, 1fr))" gap={3}>
      <GridItem>
        <PanelCard header="User" fullHeight>
          <UserList
            onClickUser={onClickUser}
            selectedUser={selectedUser}
            onUserListChange={clearSelected}
          />
        </PanelCard>
      </GridItem>
      {selectedUser && (
        <GridItem>
          <PanelCard
            header={
              <Heading
                as="p"
                size="md"
                textTransform={
                  fullName.trim() === '' ? 'lowercase' : 'capitalize'
                }
                color="gray.500"
              >
                {fullName.trim() || selectedUser?.email}
              </Heading>
            }
            fullHeight
            onClose={() => {
              setSelectedUser(null);
            }}
          >
            <Stack>
              <UserRole user={selectedUser} />
            </Stack>
          </PanelCard>
        </GridItem>
      )}
    </Grid>
  );
};
