import { Box, VStack, List, ListItem, Heading, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { ConsentedView } from '../ConsentedView';
import {
  LOCALSTORAGE_KEYS,
  LOCALSTORAGE_VALUES,
  saveState,
} from '../../app/helpers/localStorageHelper';
import { useLazyGetUserOrganizationByIdQuery } from '../../app/services/provider/api/userOrganization';
import { useChooseOrgContext } from '../../app/context/ChooseOrgContext';

type IChooseOrganizationViewProps = {
  Component: React.ComponentType;
};

const ChooseOrganizationView: FC<IChooseOrganizationViewProps> = ({
  Component,
}): any => {
  const { clinicProvider } = LOCALSTORAGE_VALUES;
  const { userOrganizations, selectedOrg, setSelectedOrg } =
    useChooseOrgContext();
  const [triggerGetUserOrgById] = useLazyGetUserOrganizationByIdQuery();

  const onSelectItem = async (org: any) => {
    const userOrg = await triggerGetUserOrgById(org.user_organization_id);
    if (userOrg) {
      saveState(
        {
          ...clinicProvider,
          selectedOrg: userOrg.data,
          organizationCount: userOrganizations?.length,
        },
        LOCALSTORAGE_KEYS.clinicProvder
      );
      setSelectedOrg({ ...userOrg.data });
    }
  };

  return (
    <>
      {userOrganizations && userOrganizations.length > 0 && !selectedOrg && (
        <VStack align="stretch" spacing={5} alignItems="center">
          <Box p={5} w="auto" shadow="md" borderWidth="1px">
            <Heading as="h5" size="sm" pb={5}>
              Please choose an organization to continue:
            </Heading>
            <List>
              {userOrganizations &&
                userOrganizations.map((org: any) => {
                  return (
                    <ListItem
                      key={org.organization_id}
                      display="flex"
                      justifyContent="space-between"
                      p={3}
                      onClick={() => onSelectItem(org)}
                    >
                      <Button
                        colorScheme="brand.main"
                        variant="outline"
                        width="100%"
                        rightIcon={<ChevronRightIcon color="brand.main" />}
                        justifyContent="space-between"
                        textTransform="uppercase"
                      >
                        {org.organization_name.toLowerCase()}{' '}
                      </Button>
                    </ListItem>
                  );
                })}
            </List>
          </Box>
        </VStack>
      )}

      {selectedOrg && <ConsentedView>{<Component />}</ConsentedView>}
    </>
  );
};

export default ChooseOrganizationView;
