import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { OrganizationDocument } from '../../app/services/provider/types';

type Props = {
  file: OrganizationDocument | null;
  handleClose: () => void;
  handleClick: () => void;
};

const FileShareDeleteFileModal: React.FC<Props> = ({
  file,
  handleClose,
  handleClick,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (file) onOpen();
    else onClose();
  }, [file, onOpen, onClose]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="md"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete File</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            <Text>
              Are you sure you want to delete {file?.document_filename}?
            </Text>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={handleClose} ml="auto">
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleClick}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileShareDeleteFileModal;
