import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { Heading, VStack, HStack, Box, Button } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useState, useRef } from 'react';
import { useGetUserOrganizationByRefUserIdQuery } from '../../../app/services/provider/api/userOrganization';
import {
  OrganizationDto,
  OrganizationPagedDto,
} from '../../../app/services/provider/types';
import { useAppSelector } from '../../../app/state/hooks';

type Props = object;

const AcoPaymentReport = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrgTin, setSelectedOrgTin] = useState<
    string | number | null | undefined
  >(null);
  const { logonUser } = useAppSelector(s => s.user);

  const uri = `https://lookerstudio.google.com/embed/reporting/3cd8730e-67e5-44a3-a6bf-f65138a5daf7/page/6u8ED?params=%7B%22payment_report.param_org_tin%22%3A%22${selectedOrgTin}%22%7D`;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { data } = useGetUserOrganizationByRefUserIdQuery(
    logonUser?.ref_user_id || 0
  );

  const refresh = () => {
    if (iframeRef.current) {
      // eslint-disable-next-line
      iframeRef.current.src = iframeRef.current.src;
    }
  };

  return (
    <HStack alignItems="stretch" height="100%" flexDir="column" w="full">
      <VStack alignItems="start">
        <HStack>
          <Button backgroundColor="#13707F" color="#fff" onClick={refresh}>
            Refresh
          </Button>
        </HStack>
        <HStack mt=".8rem" w="full">
          <Heading as="h5" size="md">
            Organization
          </Heading>
          <Box w="40%">
            <Select
              onChange={e => {
                setSelectedOrgTin(e?.value);
              }}
              placeholder="Select Organization"
              options={
                data
                  ? data
                      .filter(x => !x.disabled_flag)
                      .map((m: OrganizationDto) => {
                        return {
                          value: m.organization_tin,
                          label: m.organization_name,
                        };
                      })
                  : undefined
              }
            />
          </Box>
        </HStack>
      </VStack>

      <HStack height="75%">
        {selectedOrgTin ? (
          <iframe
            ref={iframeRef}
            style={{
              overflow: 'hidden',
              overflowX: 'hidden',
              overflowY: 'hidden',
              height: '100%',
              width: '100%',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
              marginTop: '1rem',
            }}
            height="100%"
            width="100%"
            onLoad={() => setIsLoading(false)}
            src={uri}
            allowFullScreen
          />
        ) : (
          <></>
        )}
      </HStack>
    </HStack>
  );
};

export default AcoPaymentReport;
