import { providerApi } from './index';
import {
  PostClinicProviderDto,
  PutClinicProviderDto,
  GetClinicProviderListDto,
  ClinicProviderDto,
  ClinicProviderPagedDto,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getClinicProviderList: builder.query<any, GetClinicProviderListDto>({
      query: params =>
        `/clinicprovider/user/${params.ref_user_id}/?pagenumber=${params.filter.page_number}
        &pagesize=${params.filter.page_size}
        &incorrect_data_flag=${params.filter.incorrect_data_flag}
        &q=${params.filter.search}`,
      providesTags: ['ClinicProvider'],
    }),
    getClinicProviderListByOrg: builder.query<
      ClinicProviderPagedDto,
      GetClinicProviderListDto
    >({
      query: params =>
        `/clinicprovider/organization/${params.orgId}/?pagenumber=${params.filter.page_number}
        &pagesize=${params.filter.page_size}
        &incorrect_data_flag=${params.filter.incorrect_data_flag}
        &q=${params.filter.search}`,
      providesTags: ['ClinicProvider'],
    }),
    getClinicProviderListByOrgV2: builder.query<
      ClinicProviderPagedDto,
      GetClinicProviderListDto
    >({
      query: params =>
        `/clinicprovider/organization/v2/${params.orgId}/?` +
        new URLSearchParams({
          pagenumber: params.filter.page_number.toString(),
          pagesize: params.filter.page_size.toString(),
          incorrect_data_flag: params.filter.incorrect_data_flag.toString(),
          q: params.filter.search,
          sort_order: params.filter.sortOrder,
          sort_column: params.filter.sortColumn,
        }).toString(),
      providesTags: ['ClinicProvider'],
    }),
    getClinicProviderId: builder.query<ClinicProviderDto, number>({
      query: clinicProviderId => `/clinicprovider/${clinicProviderId}`,
      providesTags: ['ClinicProvider'],
    }),
    getClinicProviderNpiExternal: builder.query<ClinicProviderDto, string>({
      query: npi => `/clinicprovider/${npi}/external`,
    }),
    postClinicProvider: builder.mutation<void, PostClinicProviderDto>({
      query: params => ({
        url: `/clinicprovider/user/${params.ref_user_id}`,
        method: 'POST',
        body: params.data,
      }),
      invalidatesTags: ['ClinicProvider'],
    }),
    putClinicProvider: builder.mutation<void, PutClinicProviderDto>({
      query: params => ({
        url: `/clinicprovider/user/${params.ref_user_id}`,
        method: 'PUT',
        body: params.data,
      }),
      invalidatesTags: ['ClinicProvider'],
    }),
    putClinicProviderNpi: builder.mutation<void, number>({
      query: npi => ({
        url: `clinicprovider/${npi}/refresh`,
        method: 'PUT',
      }),
      invalidatesTags: ['ClinicProvider'],
    }),

    putClinicProviderIncorrectFlag: builder.mutation<void, number>({
      query: clinicProviderId => ({
        url: `/clinicprovider/incorrectdata/${clinicProviderId}/reverseflag`,
        method: 'PATCH',
      }),
      invalidatesTags: ['ClinicProvider'],
    }),

    deleteClinicProvider: builder.mutation<void, number>({
      query: id => ({
        url: `/clinicprovider/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ClinicProvider'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClinicProviderListQuery,
  useGetClinicProviderListByOrgQuery,
  useGetClinicProviderListByOrgV2Query,
  useLazyGetClinicProviderIdQuery,
  usePostClinicProviderMutation,
  usePutClinicProviderMutation,
  usePutClinicProviderNpiMutation,
  usePutClinicProviderIncorrectFlagMutation,
  useDeleteClinicProviderMutation,
  useLazyGetClinicProviderListByOrgV2Query,
} = extendedApi;

export const { getClinicProviderNpiExternal } = extendedApi.endpoints;
