import { Button, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { usePutUserDemographicMutation } from '../../app/services/provider/api/userDemographic';
import {
  GetUserDemographicDto,
  PutUserDemographicDto,
} from '../../app/services/provider/types';
import EditDemographicsFormModal from './EditDemographicsFormModal';

type UpdateDemographicsButtonProps = {
  title: string;
  data: GetUserDemographicDto[] | null;
  userId: number | null;
};

const EditDemographicsButton = ({
  title,
  data,
  userId,
}: UpdateDemographicsButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [postAsync, { isLoading }] = usePutUserDemographicMutation();

  const [putUserDemogStatus, setPutUserDemogStatus] = useState<any | null>({
    isSuccess: false,
    isError: false,
    error: null,
  });

  const onSubmit = (values: PutUserDemographicDto) => {
    postAsync(values).then((resp: any) => {
      if (resp.error) {
        const errors = resp.error.data.errors;
        if (errors) {
          setPutUserDemogStatus({
            ...putUserDemogStatus,
            isError: true,
            error: Object.values(errors),
          });
        }
      } else {
        setPutUserDemogStatus({
          ...putUserDemogStatus,
          isSuccess: true,
          error: null,
        });
      }
    });
  };

  return (
    <div>
      <Button
        leftIcon={<FiEdit />}
        boxShadow="sm"
        size="sm"
        onClick={onOpen}
        colorScheme="brand.main"
      >
        {title}
      </Button>
      <EditDemographicsFormModal
        mode="Change"
        modalTitle="Update"
        initialValues={data}
        isOpen={isOpen}
        onClose={onClose}
        submitButtonLabel="Submit"
        successMessage="Successfully updated."
        onSubmit={onSubmit}
        isLoading={isLoading}
        isSuccess={putUserDemogStatus.isSuccess}
        isError={putUserDemogStatus.isError}
        errorMessage={putUserDemogStatus.error}
      />
    </div>
  );
};

export default EditDemographicsButton;
