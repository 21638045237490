import { WarningIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { createElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ValueOf } from '../../../app/helpers/utilities';
import { usePostRolesAppAccessRoleMutation } from '../../../app/services/provider/api/rolesAppAccess';
import { RolesAppAccessSelectedAppAccessModel } from '../../../app/services/provider/types';
import { useAppSelector } from '../../../app/state/hooks';
import AddRoleAppAccess from './AppAccess';
import AddRoleDetails from './Details';

export type AddRoleModel = {
  details: {
    roleName: string;
    roleDisplayName: string;
    roleDesc: string;
  };
  appAccess: RolesAppAccessSelectedAppAccessModel[];
};

const AddRoleInitialValues: AddRoleModel = {
  details: {
    roleName: '',
    roleDisplayName: '',
    roleDesc: '',
  },
  appAccess: [],
};

const AddRole = () => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { logonUser } = useAppSelector(s => s.user);
  const [localError, setLocalError] = useState<{
    title: string;
    desc: string;
  }>();
  const [localSuccess, setLocalSuccess] = useState<string>();
  const [tabErrorIndex, setTabErrorIndex] = useState<number>(-1);
  const [postAsync, postDetail] = usePostRolesAppAccessRoleMutation();

  const tabs = [
    {
      label: 'Details',
      path: 'details',
      component: AddRoleDetails,
      model: 'details',
      isArray: false,
    },
    {
      label: 'App Access',
      path: 'app-access',
      component: AddRoleAppAccess,
      model: 'appAccess',
      isArray: true,
    },
  ];

  const FormSchema = Yup.object().shape({
    details: Yup.object().shape({
      roleName: Yup.string()
        .label('Role Name')
        .required()
        .test((str, { createError }) => {
          const regex = /^[a-z.]+$/;
          return str && !regex.test(str)
            ? createError({
                message:
                  'Role Name only accepts lower case characters and periods',
                path: 'details.roleName',
              })
            : true;
        }),
      roleDisplayName: Yup.string().label('Role Display Name').required(),
      roleDesc: Yup.string().label('Role Description').required(),
    }),
    appAccess: Yup.array<RolesAppAccessSelectedAppAccessModel[]>().of(
      Yup.object().shape({
        ref_app_access_id: Yup.number().moreThan(0).required(),
      })
    ),
  });

  const {
    handleSubmit,
    errors,
    touched,
    values,
    resetForm,
    setValues,
    validateForm,
  } = useFormik<AddRoleModel>({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      details: AddRoleInitialValues.details,
      appAccess: AddRoleInitialValues.appAccess,
    },

    onSubmit: values => {
      const isLastTab = tabIndex === tabs.length - 1;
      if (isLastTab) {
        postAsync({
          create_ref_role_model: {
            role_name: values.details.roleName,
            role_display_name: values.details.roleDisplayName,
            role_desc: values.details.roleDesc,
          },
          role_app_access_id_list: values.appAccess.map(
            ({ ref_app_access_id }) => ref_app_access_id
          ),
        });
      }
    },
  });

  const validate = async () => {
    setLocalError(undefined);
    handleSubmit(); // will trigger errors

    const validationError = await validateForm(values);

    const tab = tabs[tabIndex];
    const tempError = validationError as any;
    const tempValues = values as any;
    const isLastTab = tabIndex === tabs.length - 1;

    if (!tempError[tab.model]) {
      if (tab.isArray && tempValues[tab.model].length === 0 && !isLastTab) {
        setLocalError({ title: tab.label, desc: 'must have atleast 1 entry' });
      } else {
        !isLastTab && setTabIndex(tabIndex + 1);
      }
    } else {
      setLocalError({ title: '', desc: 'Some field(s) are invalid' });
    }
  };

  const onChange = (
    field: keyof AddRoleModel,
    data: ValueOf<AddRoleModel>,
    isReset?: boolean
  ) => {
    let tempIndex;
    switch (field) {
      case 'details':
        tempIndex = 0;
        break;
      case 'appAccess':
        tempIndex = 1;
        break;
    }
    if (isReset) {
      resetForm();
    } else {
      setValues({ ...values, [field]: data });
    }
    if (tabErrorIndex === tempIndex) {
      setTabErrorIndex(-1);
      setLocalError(undefined);
    }
  };

  const onDuplicateError = (index: number) => {
    setTabIndex(index);
    setTabErrorIndex(index);
  };

  useEffect(() => {
    if (tabErrorIndex === -1) {
      setLocalError(undefined);
      window.scrollTo(0, 0);
    }
  }, [tabIndex]);

  useEffect(() => {
    const { isSuccess, isError, isLoading } = postDetail;
    if (isSuccess) {
      setLocalSuccess('Role successfully added.');
      setTimeout(() => {
        resetForm();
        setTabIndex(0);
        setLocalSuccess('');
      }, 3000);
    } else if (isError) {
      setLocalError({
        title: '',
        desc: 'There was an error processing your request, please try again later.',
      });
    } else {
      setLocalError(undefined);
    }

    if (isLoading) {
      setLocalSuccess('');
    }
  }, [postDetail]);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setLocalError(undefined);
    }
  }, [errors]);

  return (
    <VStack w="100%" alignItems="start">
      <HStack>
        <HStack flex={1}>
          <Heading size="md" whiteSpace="nowrap">
            Add Role
          </Heading>
          {logonUser && (
            <ButtonGroup>
              <Button
                isLoading={postDetail.isLoading}
                variant="outline"
                colorScheme="brand.main"
                onClick={() => validate()}
                isDisabled={tabIndex !== tabs.length - 1}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        </HStack>
        {localError && (
          <Alert py={2} status="error">
            <AlertIcon />
            {localError.title && (
              <AlertTitle>{tabs[tabIndex].label}</AlertTitle>
            )}
            <AlertDescription>{localError.desc}</AlertDescription>
          </Alert>
        )}
        {localSuccess && (
          <Alert py={2} status="success">
            <AlertIcon />
            <AlertDescription>{localSuccess}</AlertDescription>
          </Alert>
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} w="100%">
          <TabList>
            {tabs.map((m, i) => {
              const error = errors as any;
              return (
                <Tab key={i}>
                  {m.label}
                  {(error[m.model] && localError) || tabErrorIndex === i ? (
                    <Icon color="brand.error" ml={2} as={WarningIcon} />
                  ) : (
                    <></>
                  )}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tabs.map((tabPanel, i) => (
              <TabPanel key={i}>
                {tabPanel &&
                  values &&
                  createElement(tabPanel.component, {
                    onChangeData: onChange,
                    data: values,
                    isSubmitting: postDetail.isLoading,
                    errors: errors,
                    touched: touched,
                    title: tabPanel.label,
                    tabErrorIndex: tabErrorIndex,
                  })}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
      <Divider />
      <HStack>
        <Text>
          Page {tabIndex + 1} of {tabs.length}
        </Text>
        <ButtonGroup>
          <Button
            size="sm"
            isDisabled={tabIndex === 0}
            onClick={() => setTabIndex(tabIndex - 1)}
          >
            Previous
          </Button>
          <Button
            size="sm"
            isDisabled={tabIndex === tabs.length - 1}
            onClick={() => {
              validate();
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
};

export default AddRole;
