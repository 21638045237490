import { providerApi } from '.';
import {
  GetDeviceOrderListDto,
  GetDeviceOrderListResult,
  PutDeviceOrderListDto,
} from '../types';

const extendedApi = providerApi.injectEndpoints({
  endpoints: builder => ({
    getDeviceOrderList: builder.query<
      GetDeviceOrderListResult,
      GetDeviceOrderListDto
    >({
      query: ({ organization_id_list, ...params }) => {
        let orgParam: string;
        if (organization_id_list.length < 1) {
          orgParam = 'organization_id_list=null';
        } else if (organization_id_list.length === 1) {
          orgParam = 'organization_id_list=' + organization_id_list[0];
        } else {
          let text = '';
          organization_id_list.forEach((f, i) => {
            if (i === 0) {
              text = 'organization_id_list=' + f;
            } else {
              text = `${text}&organization_id_list=${f}`;
            }
          });
          orgParam = text;
        }

        const otherParams = new URLSearchParams({
          ref_device_order_status_id:
            params.ref_device_order_status_id.toString(),
          sort_order: params.sort_order,
          sort_column: params.sort_column,
          PageNumber: params.PageNumber.toString(),
          PageSize: params.PageSize.toString(),
          q: params.q,
          insulin_use: params.insulin_use?.toString() ?? 'null',
        }).toString();

        return {
          url: `/deviceorder/list?${orgParam}&${otherParams}`,
        };
      },
      providesTags: ['DeviceOrderList'],
    }),
    putDeviceOrderList: builder.mutation<void, PutDeviceOrderListDto>({
      query: body => ({
        url: '/deviceorder/list',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['DeviceOrderList'],
    }),
  }),
});

export const {
  useGetDeviceOrderListQuery,
  useLazyGetDeviceOrderListQuery,
  usePutDeviceOrderListMutation,
} = extendedApi;
