export const LOCALSTORAGE_KEYS = {
  aToken: 'provider_access_token',
  expireTime: 'provider_token_expire_time',
  timestamp: 'provider_time_stamp',
  clinicProvder: 'clinic_provider_state',
  isSignup: 'provider_is_sign_up',
  b2cRedirect: 'b2c_redirect_state',
};

export const loadState = (stateName: string) => {
  try {
    const serializedState = localStorage.getItem(stateName);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (value: any, stateName: string) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(stateName, serializedState);
  } catch (err) {
    throw new Error("Can't save changes in local storage");
  }
};

export const removeState = (stateName: string) => {
  try {
    localStorage.removeItem(stateName);
  } catch (err) {
    throw new Error("Can't remove item in local storage");
  }
};

export const LOCALSTORAGE_VALUES = {
  aToken: loadState(LOCALSTORAGE_KEYS.aToken),
  expireTime: loadState(LOCALSTORAGE_KEYS.expireTime),
  timestamp: loadState(LOCALSTORAGE_KEYS.timestamp),
  clinicProvider: loadState(LOCALSTORAGE_KEYS.clinicProvder),
  isSignup: loadState(LOCALSTORAGE_KEYS.isSignup),
  b2cRedirect: loadState(LOCALSTORAGE_KEYS.b2cRedirect),
};
