import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogonUserDto, UserConsentsDto } from '../services/provider/types';
export type AppState = {
  isUserOnboard: boolean;
  userConsents: UserConsentsDto<boolean> | null;
  refUserId: number;
  isProfileEditSuccess: boolean | null;
  logonUser: LogonUserDto | null;
  isAuthorized: boolean;
};

const initialState: AppState = {
  isUserOnboard: false,
  userConsents: null,
  isProfileEditSuccess: null,
  refUserId: 0,
  logonUser: null,
  isAuthorized: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsUserOnboard: (state, action: PayloadAction<boolean>) => {
      state.isUserOnboard = action.payload;
    },
    setIsUserConsent: (
      state,
      action: PayloadAction<UserConsentsDto<boolean>>
    ) => {
      state.userConsents = action.payload;
    },
    setRefUserId: (state, action: PayloadAction<number>) => {
      state.refUserId = action.payload;
    },
    setLogonUser: (state, action: PayloadAction<LogonUserDto>) => {
      state.logonUser = action.payload;
    },
    setIsProfileEditSuccess: (state, action: PayloadAction<boolean>) => {
      state.isProfileEditSuccess = action.payload;
    },
    setIsAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
  },
});

export const {
  setIsUserOnboard,
  setIsUserConsent,
  setRefUserId,
  setIsProfileEditSuccess,
  setLogonUser,
  setIsAuthorized,
} = userSlice.actions;
export default userSlice.reducer;
