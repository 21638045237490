import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { usePostOrganizationResendInvitesMutation } from '../../app/services/provider/api/organizationInvite';
import { GetUserOrganizationDto } from '../../app/services/provider/types';
import { AppAccessAuth } from '../../app/types/appAccessAuth';

type IProps = {
  userList: GetUserOrganizationDto[] | null;
  onSuccess: () => void;
  orgId: number;
};

const ResentInviteButton: FC<IProps> = (props: IProps) => {
  const [users, setUsers] = useState<GetUserOrganizationDto[] | null>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertDisclosure = useDisclosure();
  const [loading, setLoading] = useState(false);
  const cancelRef = React.useRef(null);
  const [emailInvites, setEmailInvites] = useState<Array<string>>([]);

  const [postAsync, { isLoading, isSuccess, isError, error }] =
    usePostOrganizationResendInvitesMutation();

  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.userWrite
  );

  const sendInvites = async () => {
    await postAsync(emailInvites);
  };

  const updateEmailInvites = (userEmail: string, isChecked: boolean) => {
    if (isChecked) {
      setEmailInvites(s => [...s, userEmail]);
    } else {
      setEmailInvites(s => s.filter(f => f !== userEmail));
    }
  };

  const handleClick = () => {
    onOpen();
    setLoading(true);
    const filtered = props.userList?.filter((u: GetUserOrganizationDto) => {
      if (!u.consent_date_time_utc && u.invite_date_time_utc) return true;
      return false;
    });

    setUsers(filtered || null);
    setLoading(false);
  };

  const onModalClose = useCallback(() => {
    setUsers(null);
    setEmailInvites([]);
    onClose();
    alertDisclosure.onClose();
  }, [alertDisclosure, onClose]);

  useEffect(() => {
    if (isSuccess) {
      alertDisclosure.onOpen();
      setTimeout(() => {
        onModalClose();
      }, 2000);
    }

    if (isError) {
      alertDisclosure.onOpen();
      setEmailInvites([]);
    }
  }, [isSuccess, isError, alertDisclosure, onModalClose]);

  return (
    <>
      {userHasRequiredRole ? (
        <div>
          <Button
            leftIcon={<IoIosSend />}
            boxShadow="sm"
            size="sm"
            onClick={handleClick}
          >
            Resend Invitation
          </Button>

          <Modal isOpen={isOpen} onClose={onModalClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Check on the boxes to resend email invites to users
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {loading && <h5>Loading...</h5>}
                {users && (
                  <VStack
                    align="stretch"
                    spacing={5}
                    alignItems="center"
                    maxH={300}
                    overflowY="auto"
                  >
                    <Box p={2} w="100%" shadow="md" borderWidth="1px">
                      {users && users.length < 1 && <Text>No data found</Text>}
                      <List>
                        {users &&
                          users.map((u: any) => {
                            return (
                              <ListItem
                                key={u.ref_user_id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderBottomWidth={1}
                                pb={1}
                              >
                                {u.first_name && u.last_name ? (
                                  <Text
                                    fontSize="md"
                                    textTransform="capitalize"
                                  >
                                    {u.first_name.toLowerCase()}{' '}
                                    {u.last_name.toLowerCase()}
                                  </Text>
                                ) : (
                                  <Text fontSize="md">{u.email}</Text>
                                )}
                                <Checkbox
                                  name={`UserEmail_${u.email}`}
                                  disabled={isLoading}
                                  onChange={(e: any) => {
                                    updateEmailInvites(
                                      u.email,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </VStack>
                )}

                <Flex>
                  {alertDisclosure.isOpen && (
                    <Alert
                      status={
                        isSuccess ? 'success' : isError ? 'error' : 'info'
                      }
                    >
                      <AlertIcon />
                      <>
                        {isSuccess &&
                          'Invitation has been sent to the selected users.'}
                        {isError &&
                          !error &&
                          'Something went wrong, please try again later or contact admin'}
                        {isError &&
                          error &&
                          'data' in error &&
                          (error?.data as any).error_message}
                      </>
                    </Alert>
                  )}
                </Flex>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup>
                  <Button
                    ref={cancelRef}
                    variant="outline"
                    colorScheme="brand.main"
                    onClick={onModalClose}
                    ml={3}
                  >
                    Close
                  </Button>
                  <Button
                    colorScheme="brand.main"
                    variant="solid"
                    justifyContent="space-between"
                    isLoading={isLoading}
                    onClick={sendInvites}
                    disabled={!emailInvites.length}
                  >
                    Send
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ResentInviteButton;
