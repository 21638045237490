import {
  Alert,
  AlertIcon,
  Box,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Row, SortingRule } from 'react-table';
import { DeviceOrderStatus } from '../../../app/constants';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useDebounce } from '../../../app/hooks/useDebounce';
import {
  useGetDeviceOrderListQuery,
  useLazyGetDeviceOrderListQuery,
  usePutDeviceOrderListMutation,
} from '../../../app/services/provider/api/deviceOrder';
import { GetDeviceOrderListData } from '../../../app/services/provider/types';
import { appColors } from '../../../app/theme';
import CsvDownloadButton from '../../../components/CsvDownloadButton';
import CustomTable from '../../../components/CustomTable';

const cgmHeaders = [
  {
    Header: 'Organization',
    accessor: 'organization_name',
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  // {
  //   Header: "Middle Name",
  //   accessor: "middle_name",
  //   style: { textAlign: "center" },
  //   Cell: () => <>-</>,
  // },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'DOB',
    style: { textAlign: 'center' },
    Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
      return (
        <>{convertUtcToLocal(original.date_of_birth, 'YYYY-MM-DD') || '-'}</>
      );
    },
    transform: (e: GetDeviceOrderListData) =>
      convertUtcToLocal(e.date_of_birth, 'YYYY-MM-DD') || '-',
  },
  {
    Header: 'Lore ID',
    accessor: 'community_ref_user_id',
    style: { textAlign: 'center' },
  },
  {
    Header: 'Assigned Provider',
    accessor: 'attributed_provider',
  },
  // {
  //   Header: "Pregnant",
  //   accessor: "pregnancy_status",
  //   style: { textAlign: "center" },
  //   Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
  //     return <>{original.pregnancy_status ? "YES" : "NO"}</>;
  //   },
  //   transform: (e: GetDeviceOrderListData) => (e.pregnancy_status ? "YES" : "NO"),
  // },
  {
    Header: 'Consented to Study',
    accessor: 'consented_to_study',
    style: { textAlign: 'center' },
    Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
      return <>{original.consented_to_study ? 'YES' : 'NO'}</>;
    },
    transform: (e: GetDeviceOrderListData) =>
      e.consented_to_study ? 'YES' : 'NO',
  },
  {
    Header: 'Attested to Lifestyle Use',
    accessor: 'attested_to_lifestyle_use',
    style: {
      display: 'flex',
      justifyContent: 'center',
    },
    Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
      return <>{original.attested_to_lifestyle_use ? 'YES' : 'NO'}</>;
    },
    transform: (e: GetDeviceOrderListData) =>
      e.attested_to_lifestyle_use ? 'YES' : 'NO',
  },
  // {
  //   Header: "Insulin Regimen",
  //   accessor: "insulin_regimen",
  //   style: { textAlign: "center" },
  //   Cell: () => <>-</>,
  // },
  {
    Header: 'Insulin Use',
    accessor: 'insulin_use',
    style: { textAlign: 'center' },
    Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
      return <>{original.insulin_use ? 'YES' : 'NO'}</>;
    },
    transform: (e: GetDeviceOrderListData) => (e.insulin_use ? 'YES' : 'NO'),
  },
  {
    Header: 'Potential Action',
    accessor: 'potential_action',
  },
  {
    Header: 'Sent to Fulfillment',
    Cell: ({ row: { original } }: { row: Row<GetDeviceOrderListData> }) => {
      return <>{convertUtcToLocal(original.status_datetime_utc) || '-'}</>;
    },
    transform: (e: GetDeviceOrderListData) =>
      convertUtcToLocal(e.status_datetime_utc, 'YYYY-MM-DD') || '-',
  },
];

const downloadColumnCurrent: React.ComponentProps<
  typeof CsvDownloadButton<GetDeviceOrderListData>
>['transform'] = [
  {
    Header: 'Organization',
    accessor: 'organization_name',
  },
  {
    Header: 'Order #',
    accessor: 'device_order_id',
  },
  {
    Header: 'Lore ID',
    accessor: 'community_ref_user_id',
  },
  {
    Header: 'RecipientFirstName',
    accessor: 'first_name',
  },
  {
    Header: 'RecipientLastName',
    accessor: 'last_name',
  },
  {
    Header: 'Address1',
    accessor: 'street_address',
  },
  // {
  //   Header: 'Address2',
  //   transform: () => '', // TODO: no field
  // },
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: 'State',
    accessor: 'state',
  },
  {
    Header: 'ZIP',
    accessor: 'zip',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'SKU',
    accessor: 'sku',
  },
  {
    Header: 'SKU description',
    accessor: 'sku_description',
  },
  {
    Header: 'Quantity',
    transform: () => 1, // TODO: no field
  },
  {
    Header: 'Shipping option',
    transform: () => 'usps', // TODO: no field
  },
  {
    Header: 'GPID',
    accessor: 'gpid',
  },
  {
    Header: 'MBI',
    accessor: 'mbi',
  },
];

const downloadColumnHistory: React.ComponentProps<
  typeof CsvDownloadButton<GetDeviceOrderListData>
>['transform'] = [
  ...downloadColumnCurrent,
  {
    Header: 'Request Date',
    transform: e => convertUtcToLocal(e.request_datetime_utc),
  },
  {
    Header: 'Approved Date',
    transform: e => convertUtcToLocal(e.approved_datetime_utc),
  },
];

const InitialSortBy: SortingRule<GetDeviceOrderListData> = {
  id: 'device_order_id',
  desc: false,
};

const FulfillmentCgm = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<SortingRule<object>>(InitialSortBy);
  // const [orgIdList, setOrgIdList] = useState<number[]>([]);

  const [searchDebounce, setSearchDebounce] = useState('');
  const debouncedSearch = useDebounce(search, 400);
  const toast = useToast();

  // const { userOrganizations } = useChooseOrgContext() as OrganizationContext;
  // const { isUserLoreTeam } = useUserRoleContext();

  // const organization_id_list = useMemo(() => {
  //   return !isUserLoreTeam && orgIdList.length < 1
  //     ? userOrganizations?.map(({ organization_id }) => organization_id) ?? [-1] // none lore team should not fetch all patients
  //     : orgIdList;
  // }, [isUserLoreTeam, orgIdList, userOrganizations]);

  const { data, isLoading, isFetching } = useGetDeviceOrderListQuery({
    ref_device_order_status_id: DeviceOrderStatus.approved,
    PageNumber: pageIndex + 1,
    PageSize: pageSize,
    q: searchDebounce,
    sort_column: sortBy.id,
    sort_order: sortBy.desc ? 'desc' : 'asc',
    insulin_use: null,
    organization_id_list: [],
  });
  // const orgListDetail = useGetOrganizationListQuery(
  //   { filter: { page_number: 1, page_size: 99999, search: '' } },
  //   { skip: !isUserLoreTeam }
  // );
  const [listAsync] = useLazyGetDeviceOrderListQuery();
  const [putAsync, putDetail] = usePutDeviceOrderListMutation();

  // const handleSelectOrg = (orgId: number) => () => {
  //   const index = orgIdList.findIndex(f => f === orgId);
  //   if (index < 0) {
  //     setOrgIdList(s => [...s, orgId]);
  //   } else {
  //     setOrgIdList(s => {
  //       const list = s.slice();
  //       list.splice(index, 1);
  //       return list;
  //     });
  //   }
  // };

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <>
      {/* {(isUserLoreTeam || (userOrganizations ?? []).length > 1) && (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Choose Organization{' '}
            {orgIdList.length > 0 ? `(${orgIdList.length})` : ''}
          </MenuButton>
          <MenuList h="70vh" overflowY="auto" zIndex={3}>
            {(isUserLoreTeam && orgListDetail.data
              ? orgListDetail.data.data
              : userOrganizations
            )?.map(m => (
              <Box key={m.organization_id} px="4" py="2">
                <Checkbox
                  isChecked={orgIdList.some(s => s === m.organization_id)}
                  onChange={handleSelectOrg(m.organization_id)}
                >
                  <Text title={m.organization_name} noOfLines={1} maxW="96">
                    {m.organization_name}
                  </Text>
                </Checkbox>
              </Box>
            ))}
            <HStack px="4">
              <Button
                variant="ghost"
                ml="auto"
                onClick={() => setOrgIdList([])}
              >
                Clear
              </Button>
            </HStack>
          </MenuList>
        </Menu>
      )} */}

      {data && data.data.length < 1 && (
        <Box mt="4">
          <Alert size="sm" bg={appColors.hightLightColor}>
            <AlertIcon />
            <Text fontSize={16} fontWeight="normal">
              No data found.
            </Text>
          </Alert>
        </Box>
      )}

      <CustomTable
        isLoading={isLoading}
        isFetching={isFetching}
        data={(data && data.data) || []}
        pageCount={(data && data.total_pages) || 0}
        pageSize={(data && data.page_size) || pageSize}
        totalRecords={(data && data.total_records) || 0}
        pageIndex={pageIndex}
        headers={cgmHeaders}
        // @ts-expect-error: please fix if encountered
        initialState={{ sortBy: [InitialSortBy] }}
        hasFilter
        hasLoadingIndicator
        manual
        manualSortBy
        disableSortRemove
        isPageNumberEditable
        onPageChange={index => {
          if ((data?.total_pages || 1) > index) setPageIndex(index);
        }}
        onPageSizeChange={size => {
          setPageIndex(0);
          setPageSize(size);
        }}
        onPageSearch={search => {
          setPageIndex(0);
          setSearch(search);
        }}
        onSort={sort => {
          if (sort[0]) setSortBy(sort[0]);
        }}
      />
      <HStack pt="8" gap="4">
        <CsvDownloadButton<GetDeviceOrderListData>
          exportName={`fulfillment_current_${Date.now()}`}
          transform={downloadColumnCurrent}
          isDisabled={
            isFetching ||
            isLoading ||
            putDetail.isLoading ||
            !data?.data ||
            data.data.length < 1
          }
          handleDownload={async () => {
            const list: GetDeviceOrderListData[] = data?.data ?? [];
            if (list.length > 0) {
              await putAsync(
                list.map(({ device_order_id }) => ({
                  device_order_id,
                  ref_device_order_status_id:
                    DeviceOrderStatus.preparingForShipment,
                  tracking_notes: '', // TODO ckvm: no tracking notes in list API. should add to it to prevent overwriting
                }))
              );
            }
            return list;
          }}
          handleEmptyDownload={() =>
            toast({ description: 'Downloaded file is empty', status: 'info' })
          }
        >
          Download Current Fulfillment List
        </CsvDownloadButton>
        <CsvDownloadButton<GetDeviceOrderListData>
          exportName={`fulfillment_history_${Date.now()}`}
          transform={downloadColumnHistory}
          handleDownload={async () => {
            const list = await listAsync({
              ref_device_order_status_id:
                DeviceOrderStatus.preparingForShipment,
              PageNumber: 1,
              PageSize: 99999,
              q: '',
              sort_column: 'device_order_id',
              sort_order: 'asc',
              insulin_use: null,
              organization_id_list: [],
            }).unwrap();
            return list.data;
          }}
          handleEmptyDownload={() =>
            toast({ description: 'Downloaded file is empty', status: 'info' })
          }
        >
          Download Fulfillment History
        </CsvDownloadButton>
      </HStack>
    </>
  );
};

export default FulfillmentCgm;
