import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Alert,
  AlertIcon,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { DemographicDto } from '../../app/services/provider/types';
import dayjs from 'dayjs';
import { useAppSelector } from '../../app/state/hooks';

type EditDemographicsFormModalProps = {
  mode: 'Add' | 'Change';
  modalTitle: string;
  initialValues: any;
  isOpen: boolean;
  onClose: () => void;
  successMessage: string;
  onSubmit: (values: any) => void;
  submitButtonLabel: string;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMessage: any;
};

const EditDemographicsFormModal = ({
  mode,
  modalTitle,
  isOpen,
  onClose,
  successMessage,
  onSubmit,
  submitButtonLabel,
  isSuccess,
  isError,
  isLoading,
  errorMessage,
  ...props
}: EditDemographicsFormModalProps) => {
  const alertDisclosure = useDisclosure();

  const [initialValues, setInitialValues] = useState(props.initialValues);
  const { logonUser } = useAppSelector((s: any) => s.user);

  useEffect(() => {
    setInitialValues(props.initialValues);
  }, [props]);

  const { handleSubmit, values, resetForm, setValues } = useFormik({
    enableReinitialize: true,
    //validationSchema: FormSchema,
    initialValues: initialValues,
    onSubmit: values => {
      // console.log("formik", values);
      const demographic: DemographicDto[] = [];
      const userId = logonUser?.ref_user_id || 0;

      if (values && values.length > 0) {
        values.forEach((d: DemographicDto) => {
          demographic.push({
            ref_demographic_id: d.ref_demographic_id,
            demographic_value: d.demographic_value,
            disabled_flag: d.disabled_flag,
            ref_user_id: d.ref_user_id,
            row_created_date_time_utc: d.row_created_date_time_utc,
            row_created_ref_user_id: d.row_created_ref_user_id,
            row_modified_date_time_utc: dayjs().format(),
            row_modified_ref_user_id: userId,
            user_demographic_id: d.user_demographic_id,
            disabled_datetime_utc: d.disabled_datetime_utc,
            row_created_by_user_name: d.row_created_by_user_name,
            row_modified_by_user_name: d.row_modified_by_user_name,
          });
        });
      }
      onSubmit({ ref_user_id: userId, demographic: values });
    },
  });

  const onModalClose = () => {
    onClose();
    resetForm();
    alertDisclosure.onClose();
  };

  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onModalClose}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack spacing={5} align="stretch">
                <VStack spacing={5} alignItems="flex-start">
                  {initialValues && initialValues.length > 0 ? (
                    initialValues.map((d: any, i: number) => {
                      return (
                        <FormControl
                          isRequired={values[i].required_field_flag}
                          key={i}
                          // isInvalid={!!demographicsTouched && demographicsTouched}
                        >
                          <FormLabel htmlFor={values[i].demographic_name}>
                            {values[i].demographic_label}
                          </FormLabel>
                          <Input
                            id={values[i].demographic_name}
                            placeholder={values[i].demographic_label}
                            name={values[i].demographic_name}
                            value={values[i].demographic_value}
                            onChange={e => {
                              const newDemographics = values.map((d: any) =>
                                d.demographic_name === e.target.name
                                  ? { ...d, demographic_value: e.target.value }
                                  : d
                              );
                              setValues(newDemographics);
                            }}
                          />

                          {/* <FormErrorMessage>{demographicsErrors.demographic_name}</FormErrorMessage> */}
                        </FormControl>
                      );
                    })
                  ) : (
                    <div>Loading...</div>
                  )}
                  {alertDisclosure.isOpen && (
                    <Alert
                      status={
                        isSuccess ? 'success' : isError ? 'error' : 'info'
                      }
                    >
                      <AlertIcon />
                      {isSuccess && `${successMessage}`}
                      {isError && errorMessage}
                    </Alert>
                  )}
                </VStack>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="outline"
                colorScheme="brand.main"
                mr={3}
                onClick={onModalClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="brand.main"
                isLoading={isLoading}
                type="submit"
              >
                {submitButtonLabel}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditDemographicsFormModal;
