import { AddIcon } from '@chakra-ui/icons';
import { Button, useDisclosure } from '@chakra-ui/react';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { usePostOrganizationMutation } from '../../app/services/provider/api/organization';
import { PostOrganizationDto } from '../../app/services/provider/types';
import { AppAccessAuth } from '../../app/types/appAccessAuth';
import OrganizationFormModal from './OrganizationFormModal';

type Props = object;

export const AddOrgButton = (props: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const userHasRequiredRole = useIsUserHasRequiredAppAccess(
    AppAccessAuth.organizationWrite
  );

  const initialValues = {
    organization_id: 0,
    organization_tin: '',
    organization_name: '',
    organization_address_1: '',
    organization_address_2: '',
    organization_city: '',
    organization_state: '',
    organization_zip_code: '',
    doing_business_as: '',
    ref_payment_track_id: 0,
  } as PostOrganizationDto;

  const [postAsync, { isLoading, isSuccess, isError, error }] =
    usePostOrganizationMutation();

  const onSubmit = (values: PostOrganizationDto) => {
    const params = {
      ...values,
    };
    postAsync(params);
  };

  return (
    <>
      {userHasRequiredRole ? (
        <>
          <Button
            leftIcon={<AddIcon />}
            boxShadow="sm"
            size="sm"
            onClick={onOpen}
          >
            Add Organization
          </Button>
          <OrganizationFormModal
            mode="Add"
            modalTitle="Add Organization"
            isOpen={isOpen}
            onClose={onClose}
            submitButtonLabel="Submit"
            successMessage="Successfully added organization."
            initialValues={initialValues}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};
