import { Button, Heading, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { HiSwitchHorizontal } from 'react-icons/hi';
import {
  LOCALSTORAGE_KEYS,
  removeState,
} from '../../app/helpers/localStorageHelper';

type IChangeOrganizationButtonProps = {
  onChange: (params: any) => void;
  organizationName: string;
};
const ChangeOrganizationButton: FC<IChangeOrganizationButtonProps> = ({
  onChange,
  organizationName,
}) => {
  const handleClick = () => {
    if (onChange) {
      removeState(LOCALSTORAGE_KEYS.clinicProvder);
      onChange(null);
    }
  };

  return (
    <HStack pt={3} pb={3}>
      <Heading as="h5" size="md">
        Organization:
        <Text as="span" p={3}>
          {organizationName}
        </Text>
      </Heading>
      <Button
        onClick={handleClick}
        colorScheme="brand.main"
        leftIcon={<HiSwitchHorizontal fontSize="25px" />}
        boxShadow="sm"
        size="sm"
      >
        Switch Organization
      </Button>
    </HStack>
  );
};

export default ChangeOrganizationButton;
