import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserRoleAppAccessByEmailModel,
  UserRoleDto,
} from '../services/provider/types';
type UserRoleState = {
  currentUserRole?: UserRoleDto[];
  currentUserUserAppAccess?: UserRoleAppAccessByEmailModel[];
};

const initialState: UserRoleState = {};

export const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setCurrentUserRole: (state, action: PayloadAction<UserRoleDto[]>) => {
      state.currentUserRole = action.payload;
    },
    setCurrentUserAppAccess: (
      state,
      action: PayloadAction<UserRoleAppAccessByEmailModel[]>
    ) => {
      state.currentUserUserAppAccess = action.payload;
    },
  },
});

export const { setCurrentUserRole, setCurrentUserAppAccess } =
  userRoleSlice.actions;
export default userRoleSlice.reducer;
