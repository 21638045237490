import { useUserRoleContext } from '../context/UserRoleContext';
import { UserRoleAppAccessByEmailModel } from '../services/provider/types';
import { AppAuthRequiredAppAccess } from '../types/appAccessAuth';

function useIsUserHasRequiredAppAccess(
  requiredAppAccess: AppAuthRequiredAppAccess
): boolean {
  const { userAppAccess } = useUserRoleContext();

  return checkUserHasRequiredAppAccess(userAppAccess, requiredAppAccess);
}

type ReturnType = (requiredAppAccess: AppAuthRequiredAppAccess) => boolean;
export function useIsUserHasRequiredAppAccessFunc(): ReturnType {
  const { userAppAccess } = useUserRoleContext();

  return (requiredAppAccess: AppAuthRequiredAppAccess) =>
    checkUserHasRequiredAppAccess(userAppAccess, requiredAppAccess);
}

export const checkUserHasRequiredAppAccess = (
  userAppAccess: UserRoleAppAccessByEmailModel[],
  requiredAppAccess: AppAuthRequiredAppAccess
) => {
  const inRole =
    (userAppAccess.length && requiredAppAccess.toLocaleString() === '*') ||
    (typeof requiredAppAccess === 'string' &&
      userAppAccess.some(
        s =>
          s.app_access_name ===
            (requiredAppAccess as UserRoleAppAccessByEmailModel['app_access_name']) &&
          s.access_flag
      )) ||
    (Array.isArray(requiredAppAccess) &&
      userAppAccess.some(
        s => requiredAppAccess.includes(s.app_access_name) && s.access_flag
      ));

  return inRole;
};

export default useIsUserHasRequiredAppAccess;
