import {
  Alert,
  AlertIcon,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import UploadAvatar from '../../components/UploadAvatar/UploadAvatar';

type UploadAvatarFormModalProps = {
  mode: 'Add' | 'Change';
  modalTitle: string;
  isOpen: boolean;
  onClose: () => void;
  successMessage: string;
  onSubmit: (values: any) => void;
  submitButtonLabel: string;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMessage: any;
};

const UploadAvatarFormModal: FunctionComponent<
  UploadAvatarFormModalProps
> = props => {
  const [src, setSrc] = useState<string>('');
  const [error, setError] = useState<string>('');

  const alertDisclosure = useDisclosure();
  const {
    modalTitle,
    isOpen,
    onClose,
    onSubmit,
    submitButtonLabel,
    isSuccess,
    isError,
    isLoading,
    errorMessage,
  } = props;

  const onModalClose = () => {
    onClose();
    alertDisclosure.onClose();
  };

  const handleOnChange = (src: string) => {
    setError('');
    setSrc(src);
  };

  const handleOnError = (error: string) => {
    setError(error);
    alertDisclosure.onOpen();
  };

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        onClose();
        alertDisclosure.onClose();
      } else {
        setSrc('');
        setError(errorMessage?.data.msg);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onModalClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5} align="stretch">
              <HStack spacing={7} alignItems="flex-start"></HStack>
              <UploadAvatar onChange={handleOnChange} onError={handleOnError} />
              {alertDisclosure.isOpen && error && (
                <Alert status="error">
                  <AlertIcon />
                  {isError &&
                    !errorMessage &&
                    'Something went wrong, please try again later or contact admin'}
                  {error}
                </Alert>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="brand.main"
              mr={3}
              onClick={onModalClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="brand.main"
              isLoading={isLoading}
              onClick={() => onSubmit(src)}
            >
              {submitButtonLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadAvatarFormModal;
