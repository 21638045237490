import React from 'react';
import { useAccount } from '@azure/msal-react';
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useAppSelector } from '../../app/state/hooks';

type Props = object;

const ClaimsTable = (params: any) => {
  console.log('params', params.demographics);
  if (params.obj === Object(params.obj)) {
    return (
      <TableContainer>
        {params.demographics === Object(params.demographics) && (
          <Box pt={5} pb={5}>
            <Heading as="h5" size="md" pb={5}>
              User Demographics
            </Heading>
            <Table variant="striped" size="sm">
              <Tbody>
                <>
                  {Object.entries(params.demographics).map(([key, value]) => {
                    return (
                      <Tr key={key}>
                        <Td>{key}</Td>
                        <Td>
                          {value === Object(value) ? (
                            <ClaimsTable obj={value} />
                          ) : (
                            (value as any)
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              </Tbody>
            </Table>
          </Box>
        )}
        <Table variant="striped" size="sm">
          <Tbody>
            <>
              {Object.entries(params.obj).map(([key, value]) => {
                return (
                  <Tr key={key}>
                    <Td>{key}</Td>
                    <Td>
                      {value === Object(value) ? (
                        <ClaimsTable obj={value} />
                      ) : value === Boolean(value) ? (
                        value.toString()
                      ) : (
                        (value as any)
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </>
          </Tbody>
        </Table>
      </TableContainer>
    );
  } else {
    return <>{params.obj}</>;
  }
};

const Claims = (props: Props) => {
  const { logonUser } = useAppSelector(s => s.user);
  const account = useAccount();

  return (
    <VStack>
      <ClaimsTable obj={account} demographics={logonUser} />
    </VStack>
  );
};

export default Claims;
