import { ComponentStyleConfig, cssVar, Tooltip } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');
Tooltip.defaultProps = {
  ...Tooltip.defaultProps,
  placement: 'top',
  hasArrow: true,
};
const CustomTooltip: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    bg: 'gray.100',
    color: 'gray.600',
    [$arrowBg.variable]: 'colors.gray.100',
    maxW: 'none',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    // size: "",
    // variant: "",
    // colorScheme: "",
  },
};

export default CustomTooltip;
