import {
  Button,
  Checkbox,
  Divider,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import useIsUserHasRequiredAppAccess from '../../app/hooks/useIsUserHasRequiredRoles';
import { useGetRefRoleListQuery } from '../../app/services/provider/api/refrole';
import {
  useGetUserRoleListByRefUserIdQuery,
  usePutUserRoleMutation,
} from '../../app/services/provider/api/userRole';
import {
  RefRoleDto,
  UpdateUserRoleDto,
  UserDto,
} from '../../app/services/provider/types';
import { AppAccessAuth } from '../../app/types/appAccessAuth';

type IProps = {
  user: UserDto;
};

const UserRole: FC<IProps> = ({ user }) => {
  const [userRoleList, setUserRoleList] = useState<UpdateUserRoleDto[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const hasUserWrite = useIsUserHasRequiredAppAccess(AppAccessAuth.userWrite);
  const refRoleQry = useGetRefRoleListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const userRoleQry = useGetUserRoleListByRefUserIdQuery(user.ref_user_id, {
    refetchOnMountOrArgChange: true,
  });

  const [putAsync, putStatus] = usePutUserRoleMutation();

  const getUniqueUserRoles = () => {
    const list: UpdateUserRoleDto[] = [];
    userRoleList.forEach(i => {
      if (!list.some(j => i.ref_role_id === j.ref_role_id)) list.push(i);
    });
    return list;
  };

  const updateCheckedRoles = (ref_role_id: number, isChecked: boolean) => {
    if (isChecked) {
      setUserRoleList(s => [...s, { ref_role_id }]);
    } else {
      setUserRoleList(s => s.filter(f => f.ref_role_id !== ref_role_id));
    }
  };

  const updateUserRoles = async () => {
    await putAsync({
      ref_user_id: user.ref_user_id,
      model: getUniqueUserRoles(),
    });
  };

  const checkUserRoleChanges = () => {
    const a = JSON.stringify(userRoleList.sort(refRoleSorter));
    const b = JSON.stringify(
      userRoleQry.data
        ?.map(({ ref_role_id }) => ({ ref_role_id }))
        .sort(refRoleSorter) || '{}'
    );

    setDisableSaveButton(a === b);
  };

  const refRoleSorter = (a: UpdateUserRoleDto, b: UpdateUserRoleDto) => {
    return a.ref_role_id - b.ref_role_id;
  };

  useEffect(() => {
    if (userRoleQry.data) {
      setUserRoleList(
        userRoleQry.data.map(m => ({ ref_role_id: m.ref_role_id }))
      );
    } else {
      setUserRoleList([]);
    }
  }, [userRoleQry.data]);

  useEffect(() => {
    checkUserRoleChanges();
  }, [userRoleList]);

  return (
    <VStack align="stretch" spacing={4}>
      <VStack spacing={2} alignItems="flex-start">
        <Heading as="h6" size="md" lineHeight="1.9">
          Roles
        </Heading>
        <>
          {refRoleQry.isFetching || userRoleQry.isFetching ? (
            <>Loading...</>
          ) : userRoleQry.isSuccess && refRoleQry.isSuccess ? (
            <>
              {refRoleQry.data
                //.sort((a, b) => (a.ref_role_id > b.ref_role_id ? 1 : -1))
                .map((m: RefRoleDto, index: number) => (
                  <Checkbox
                    key={index}
                    disabled={
                      !hasUserWrite ||
                      putStatus.isLoading ||
                      Boolean(user.disabled_datetime_utc)
                    }
                    name={`UserRoleUserId_${user.ref_user_id}`}
                    onChange={(e: any) => {
                      updateCheckedRoles(m.ref_role_id, e.target.checked);
                    }}
                    isChecked={userRoleList.some(
                      s => s.ref_role_id === m.ref_role_id
                    )}
                  >
                    {m.role_display_name}
                  </Checkbox>
                ))}

              {!user.disabled_datetime_utc ? (
                <>
                  <Divider />
                  <HStack justifyContent="flex-end" w="full">
                    <Button
                      size="sm"
                      colorScheme="brand.main"
                      onClick={updateUserRoles}
                      isLoading={putStatus.isLoading}
                      disabled={disableSaveButton || userRoleQry.isFetching}
                    >
                      Save
                    </Button>
                  </HStack>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>Please try again later or contact admin</>
          )}
        </>
      </VStack>
    </VStack>
  );
};

export default UserRole;
